import React, { useRef } from "react";
import RaffleWhyChoose1 from "../../assets/images/svg/raffle_why_choose_01.svg";
import RaffleWhyChoose2 from "../../assets/images/svg/raffle_why_choose_02.svg";
import RaffleWhyChoose3 from "../../assets/images/svg/raffle_why_choose_03.svg";
import RaffleWhyChoose4 from "../../assets/images/svg/raffle_why_choose_04.svg";
import CommunityHowItWorks1 from "../../assets/images/svg/community_how_it_works_1.svg";
import CommunityHowItWorks2 from "../../assets/images/svg/community_how_it_works_2.svg";
import CommunityHowItWorks3 from "../../assets/images/svg/community_how_it_works_3.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

const WhyChoose = () => {
  const scrollContainerRef = useRef(null);
  const WhyChooseContent = [
    {
      id: 1,
      heading: "Fairness Powered by Advanced AI & Machine Learning",

      description: (
        <div>
          We believe in fairness above all else.{" "}
          <b className="ml-1">SafeDrop™ </b>utilizes advanced AI and Machine
          Learning to ensure every sneakerhead has an equal shot at coveted
          releases. Say goodbye to bots and fraud, and hello to genuine sneaker
          enthusiasts getting a shot to add another pair to the collection.
        </div>
      ),
      image: <img src={RaffleWhyChoose1} alt="Community Image" />,
    },
    {
      id: 2,
      heading: "EntryBoost™ Technology",
      description: (
        <div>
          Maximize your customers chances of winning!{" "}
          <b className="ml-1">EntryBoost™</b> technology strategically enters
          participants into upcoming raffles, increasing their odds of securing
          the sneakers they love. Elevate their experience, and they'll keep
          coming back for more!
        </div>
      ),
      image: <img src={RaffleWhyChoose2} alt="Community Image" />,
    },
    {
      id: 3,
      heading: (
        <div>
          DropTarget™ Supercharges Customer
          <br />
          Re-Engagement
        </div>
      ),
      description: (
        <div>
          Taking a “L” on a raffle too many times can be painful! Don't let them
          slip away! With <b className="ml-1">DropTarget™</b>, you can re-engage
          customers who missed out on previous releases. Send targeted emails
          and texts, enticing them with special promotions to keep them shopping
          at your store.
        </div>
      ),
      image: <img src={RaffleWhyChoose3} alt="Community Image" />,
    },
    {
      id: 4,
      heading: "Retailer Insights Dashboard",
      description: (
        <div>
          Make data-driven decisions effortlessly.{" "}
          <b className="ml-1">SafeDrop™</b> provides comprehensive insights into
          participant behavior, preferences, and feedback. Customize your
          release experience to meet exceed customer expectations.
        </div>
      ),
      image: <img src={RaffleWhyChoose4} alt="Community Image" />,
    },
  ];

  const handleScroll = (evt) => {
    evt.preventDefault();
    scrollContainerRef.current.scrollLeft += evt.deltaX;
  };

  return (
    <React.Fragment>
      <div className="mt-[15rem] 2xl:pl-12 xl:pr-4">
        <div className="mx-auto text-center">
          <h1 className="sm:w-3/5 xl:w-auto font-druk font-medium text-[30px] leading-[32px] sm:text-[48px] sm:leading-[48px] mx-auto">
            WHY CHOOSE SAFEDROP™?
          </h1>
        </div>

        <div className="mt-12 xl:mt-16 pl-10 2xl:pl-12 2xl:mr-24">
          <div
            className="w-auto flex gap-6 mr-10 xl:mr-4 2xl:mr-0 xl:gap-8 2xl:gap-8 overflow-x-auto category-scroller"
            ref={scrollContainerRef}
            onWheel={handleScroll}
          >
            {WhyChooseContent.map((ele, id) => (
              <div key={id} className="xl:mx-auto ">
                <div className="w-[19rem] xl:w-[19rem] 2xl:w-[24rem] h-[17rem] xl:h-[19rem] 2xl:h-[24rem] p-4 xl:p-8 2xl:p-8 flex border border-white rounded-xl">
                  {ele.image}
                </div>
                <div className="w-[18rem]">
                  <h1 className="font-druk font-medium text-[22px] leading-[24px] my-4 ">
                    {ele.heading}
                  </h1>
                  <h2 className="font-inter font-normal text-[16px] leading-[23px] ">
                    {ele.description}
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhyChoose;
