import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoggedIn: false,
  user: { username: "", email: "" },
  showLoginModal: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogin: (state) => {
      state.isLoggedIn = true;
      state.isAuthenticated = true;
      state.showLoginModal = true;
    },
    setLogoff: (state) => {
      state.isLoggedIn = false;
      state.isAuthenticated = false;
      state.user = initialState.user;
    },
    setAuthenicated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    hideLoginModal: (state) => {
      state.showLoginModal = false;
    },
    login() {},
  },
});

export const { setLogin, setLogoff, setAuthenicated, setUser, hideLoginModal } =
  userSlice.actions;

export default userSlice.reducer;
