import React, { useState } from "react";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import insuranceLockSafe from "../../assets/images/png/insurance-lock-safe.png";

import "./insurance-faqs.scss";

function InsuranceFaq() {
  return (
    <React.Fragment>
      <div className="font-druk font-medium text-[30px] leading-[40px] m-4 mx-6 2xl:mx-[10rem]">
        INSURANCE FAQ
      </div>
      <div
        className="accordion overflow-y-auto overflow-x-hidden text-black h-[30rem] mx-6 mb-[40rem] rounded-xl space-y-4 2xl:mx-[10rem] insurance-faqs"
        id="accordionExample"
      >
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingOne">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is SoleSafe™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              SoleSafe™ is a sneaker insurance™ product for sneaker collectors.
              Once a crazy idea in 2020 is now available for the community.
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwo">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              How do I access the sneaker insurance™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              Download the mobile app to join community, inventory your
              collection, and get sneaker insurance™.
            </div>
          </div>
        </div>

        {/* 3 rd  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThree">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              What is sneaker insurance™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px] ">
              SoleSafe's sneaker insurance™ is specialized covered design for
              sneakerheads, by sneakerheads. Our insurance product provides
              legit coverage, whether worn or not, for the community.{" "}
              <div className="mt-4">
                You can call us the "insurance rider for the sneaker culture"
                providing the right coverage on top of the limited coverage you
                have on your existing homeowners or renters policy.
              </div>
            </div>
          </div>
        </div>
        {/* 4th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFour">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              Why do you need (or want) sneaker insurance™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              From our POV, sneakers have elevated in value and should be
              protected in the same manner as other assets like jewelry, sports
              memorabilia and trading cards.
              <div className="mt-4 col-span-11">
                The coverages and product didn't exist, so we built it from
                scratch.
              </div>
            </div>
          </div>
        </div>
        {/* 5th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFive">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              Which states is the sneaker insurance™ available?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Available - 45 States
              <div className="mt-4 ">
                Not Available - U.S Territories, Montana, Idaho
              </div>
            </div>
          </div>
        </div>
        {/* 6th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSix">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              How much is SoleSafe's sneaker insurance™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              A typical premium (payment) will be roughly 3-5% of the market
              value* your sneaker.
              <div className="mt-4">
                *Note: This is an estimate and will depend on the specific age,
                condition, wear frequency, and other details provided during
                underwriting.
              </div>
            </div>
          </div>
        </div>
        {/* 7th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSeven">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
              What is dynamic coverage pricing?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              SoleSafe™ offers dynamic coverage pricing based on the changing
              market value of each sneaker insured on the platform. Like other
              alternative assets, sneaker valuation/pricing changes in the
              market and users should have the ability to adjust their coverage.
              <div className="mt-4">
                We will share our learnings to ensure dynamic pricing is
                providing the right value throughout a coverage term.
              </div>
            </div>
          </div>
        </div>
        {/* 8th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingEight">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="true"
              aria-controls="collapseEight"
            >
              How is SoleSafe's sneaker insurance™ different from your
              homeowners/renters/condo/mobile home/etc. policy?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Most traditional carriers and agents do not understand the sneaker
              community. Your standard policy will have a low sub-limits (a cap
              put on valuable items) with no mention of our valued sneakers. The
              other difference is what you pay and experience at the time of a
              claim. Your standard deductible is typically $1,000 (could be more
              or less) but leaves you paying a high amount just to start the
              claims process. Finally, every sneaker doesn't need to be
              "totaled" or paid out during a claim. While we don't want you to
              experience a loss and file a claim, it will happen. When it does,
              our investment in understanding our community will shine through
              in the claims experience.
              <div className="mt-4">
                We look forward to protecting your kicks.
              </div>
            </div>
          </div>
        </div>
        {/* 9th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingNine">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="true"
              aria-controls="collapseNine"
            >
              How is SoleSafe's sneaker insurance™ different from collectibles
              or other "sneaker insurance" competitors?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <ul className="accordion-body list-disc p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue   text-[16px] leading-[24px]">
              <li className="">Sneaker-specific coverages</li>
              <li>Dynamic Pricing</li>
              <li>30-Day Photo Submission Period </li>
              <li>Coverage Tiering</li>
              <li>
                We handle everything end-to-end = SoleSafe is the Agent,
                Underwriter, and Claims Adjuster for our customers.
              </li>
            </ul>
          </div>
        </div>
        {/* 10th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="true"
              aria-controls="collapseTen"
            >
              What does SoleSafe's sneaker insurance™ COVER?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue   text-[16px] leading-[24px]">
              We created two plans - Lock and Safe - to meet your coverage
              needs. Crumble Coverage™ is available at the time of initial
              purchase to add proactive coverage for sneakers that prematurely
              crumble over time.
              <div className="my-4">
                See this graphic below that shows what is included in Lock and
                Safe:
              </div>
              <img src={insuranceLockSafe} />
            </div>
          </div>
        </div>
        {/* 11th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingEleven">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="true"
              aria-controls="collapseEleven"
            >
              What does SoleSafe's sneaker insurance™ NOT COVER?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseEleven"
            className="accordion-collapse collapse"
            aria-labelledby="headingEleven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              To keep it simple, we do not cover the following:
              <ul className="list-disc mt-4 ml-6">
                <li>Normal Wear and Tear </li>
                <li>Creasing </li>
                <li>Miscellaneous Disappearance </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 12th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwelve">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="true"
              aria-controls="collapseTwelve"
            >
              What kinds of sneakers are covered (DS,VNDS, etc)?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwelve"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwelve"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              All brands and sneakers available in our growing database of 160K+
              sneakers are eligible for sneaker insurance.
              <div className=" mt-4 ">
                Condition types (DS, VNDS, etc.) are eligible but there will
                come a point where we will not insure sneakers that are
                considered "beaters" or dead. I'm sure you get that we can't
                cover everything.
              </div>
            </div>
          </div>
        </div>
        {/* 13th  */}
        {/* this que is removed temporarily
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThirteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirteen"
              aria-expanded="true"
              aria-controls="collapseThirteen"
            >
              Do you authenticate sneakers for insurance coverage?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThirteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingThirteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              <div className=" cursor-pointer scroll-smooth rounded-full float-right">
                <img
                  src={scrollArrow}
                  className="scroll-smooth rounded-full bg-white p-1"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="true"
                  aria-controls="collapseThirteen"
                />
              </div>
              Yes - We know how important this is for the sneaker community. We
              have a third-partnership with _________________ to authenticate
              sneakers.
            </div>
          </div>
        </div> */}
        {/* 14th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFourteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFourteen"
              aria-expanded="true"
              aria-controls="collapseFourteen"
            >
              What is SoleID™? (in Private Beta)
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFourteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingFourteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              SoleSafe™ believes insurance built with an element of blockchain
              provides another layer of security and confidence for everyone.
              <div className="mt-4">
                We create a tagging solution using Near Field Communication
                (NFC) technology that enables small tags and allows a collector
                to uniquely identify any particular pair of sneakers.
              </div>
              <div>
                This will apply to specific sneakers and made clear during the
                underwriting process in the future.
              </div>
              <div>Think of the vin number for sneakers. Kinda dope right?</div>
              <div>
                Interested in early-access and joining private beta? Contact us
                at hello@solesafe.co
              </div>
            </div>
          </div>
        </div>
        {/* 15th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFifteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFifteen"
              aria-expanded="true"
              aria-controls="collapseFifteen"
            >
              What is SoleGrade™? How does this grade work and impact insurance
              premium pricing?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFifteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingFifteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              SoleSafe™ believes sneakers are on the same level as trading cards
              and jewelry. Both trading cards and jewelry have a defined
              standard that determines value based on a variety of factors. As
              an example, there is the PSA for trading cards or the GIA for
              diamond/jewelry. But what is the value system standardized for
              sneakers?
              <div className="mt-4">
                SoleSafe™ created our SoleGrade™. SoleGrade™ is determined by
                user inputs and creates a composite score to determine the value
                of a specific sneaker. This value is used as the "Adjusted
                Market Value" defined by SoleSafe™ to calculate an insurance
                premium based on specific tier of coverage.
              </div>
            </div>
          </div>
        </div>
        {/* 16th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSixteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSixteen"
              aria-expanded="true"
              aria-controls="collapseSixteen"
            >
              What are my coverage limits with SoleSafe's sneaker insurance™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSixteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingSixteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              <ul className="list-disc">
                <li>
                  <strong> Individual Pair(s): </strong> $25,000 ($25K) is the
                  max we will pay for a Covered Loss to any individual item or
                  pair of sneakers unless approved by our team and scheduled on
                  your policy.{" "}
                </li>
                <li>
                  <strong> Collection Amount: </strong>$1,000,000 ($1M) per
                  collection unless specifically approved by our team.
                  <li className="ml-8">
                    {" "}
                    Collections Over $1M will require additional underwriting
                    review, in-person inspection and inventorying.{" "}
                  </li>
                </li>
              </ul>
              <div className="mt-4">
                If you have questions, please contact help@solesafe.co.
              </div>
            </div>
          </div>
        </div>
        {/* 17th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSeventeen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeventeen"
              aria-expanded="true"
              aria-controls="collapseSeventeen"
            >
              Do I have a deductible?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSeventeen"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeventeen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              <ul className="list-disc">
                <li>
                  Yes - A 5% deductible is applied to each insured sneaker.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 18th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingEighteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEighteen"
              aria-expanded="true"
              aria-controls="collapseEighteen"
            >
              Why 5% versus $0 like our competitors?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseEighteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingEighteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              <ul className="list-disc">
                <li>
                  <strong> Reality of Claims</strong>- We believe our coverages
                  offer you value and truly puts skin in in the claim for both
                  sides. This means you have protection when it matters the
                  most. While the future could lead us to $0 Deductibles, our
                  team would like to ensure you truly want to file this claim.
                </li>
                <li>
                  <strong> Restoration</strong> - Our team has been extremely
                  thoughtful about more than coverage, but the claims
                  experience. To ensure this experience is valuable and
                  frictionless, we offer a restoration service to all of our
                  insured customers.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 19th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingNineteen">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNineteen"
              aria-expanded="true"
              aria-controls="collapseNineteen"
            >
              What are the available terms or frequencies for payment?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseNineteen"
            className="accordion-collapse collapse"
            aria-labelledby="headingNineteen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              We offer monthly and annual (upfront) payment over a 12-month
              term.
            </div>
          </div>
        </div>
        {/* 20th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwenty">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwenty"
              aria-expanded="true"
              aria-controls="collapseTwenty"
            >
              Can I transfer my insurance policy?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwenty"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwenty"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Short answer - No. Insurance is not transferable.
              <div className="mt-4">
                Longer answer - Insurance is not transferable by law. Each
                person has their own level of "risk" and "tolerance for risk"
                for us to evaluate on an individual basis. Therefore, we cannot
                simply transfer one policy from person to person. We will work
                on notifying a specific user or enabling solutions to make it
                known that you once insured this sneaker when
                buying/selling/trading/inventorying on our platform or partners.
              </div>
            </div>
          </div>
        </div>
        {/* 21th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwentyOne">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyOne"
              aria-expanded="true"
              aria-controls="collapseTwentyOne"
            >
              How do I file a claim?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwentyOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwentyOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              This is a seamless process and we have adjusters waiting to help
              you through a claim. You can complete right in the SoleSafe app
              and we will assign a resource to you within 24-48 hours.
              <div className="mt-4">
                If you have questions, please contact help@solesafe.co.
              </div>
            </div>
          </div>
        </div>
        {/* 22th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwentyTwo">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyTwo"
              aria-expanded="true"
              aria-controls="collapseTwentyTwo"
            >
              What is your cancellation policy?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwentyTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwentyTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              You can cancel at any time. Any unpaid premium within your term
              will be pro-rated and returned as a refund.
              <div className="mt-4">
                If you have questions, please contact help@solesafe.co.
              </div>
            </div>
          </div>
        </div>
        {/* 23th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwentyThree">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyThree"
              aria-expanded="true"
              aria-controls="collapseTwentyThree"
            >
              What is your refund policy?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwentyThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwentyThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 space-y-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              You can cancel at any time. Any unpaid premium within your term
              will be pro-rated and returned as a refund.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InsuranceFaq;
