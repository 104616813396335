import React from "react";
import scrollArrow from "../../../assets/images/svg/scroll-arrow.svg";

const Customers = () => {
  return (
    <React.Fragment>
      <div
        className="accordion overflow-y-auto overflow-x-hidden h-[30rem] mt-8 mb-14 text-white rounded-xl space-y-4 mx-6 sm:mx-[3rem] xl:mx-[2.5rem] 2xl:mx-[12rem] insurance-faqs"
        id="accordionExample"
      >
        {/* 1st */}
        <div className="accordion-item mb-4">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button
              className="
    justify-between
    collapsed
    relative
    flex
    items-center
    w-full
    p-[30px]
    font-druk text-[20px] leading-[24px] font-bold text-left
    rounded-xl
    transition
    focus:outline-none
    border
         
  "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              HOW DO I ENTER DROPS BY SAFEDROP?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              The best way to enter drops is by visiting your favorite retailer
              websites to learn more about upcoming releases, launches, or
              drops. We will be there to make the experience as smooth as a
              knife through hot butter.
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwo">
            <button
              className="
    justify-between
    collapsed
    relative
    flex
    items-center
    w-full
    p-[30px]
    font-druk text-[20px] leading-[24px] font-bold text-left
    rounded-xl
    transition
    focus:outline-none
    border
  "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              AM I CHARGED FOR ENTERING A DROP?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              No. Entering a product drop is free. Your payment information
              including credit card details are collected to place a $0 USD
              authorization or “hold” in your bank account to verify your
              identify and proof of funds if selected as a winner.
            </div>
          </div>
        </div>

        {/* 3 rd  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThree">
            <button
              className="
    justify-between
    collapsed
    relative
    flex
    items-center
    w-full
    p-[30px]
    font-druk text-[20px] leading-[24px] font-bold text-left
    rounded-xl
    transition
    focus:outline-none
    border
   
  "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              AM I CHARGED FOR WINNING A DROP?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px] ">
              Yes. If selected as a winner after a drop ends you will be charged
              the following:
              <div className="my-4">
                <b>Delivered To You:</b> Retail Price + Shipping & Handling Fees
                + SafeDrop™ fee. There might be an optional Package Protection
                cost, if selected during entry.
              </div>
              <div className="my-4">
                <b>Pick Up In-Store:</b> Retail Price + SafeDrop™ fee. There
                might be an optional SoleSafe Sneaker Insurance™ cost, if
                selected during entry.
              </div>
              <div>
                Standard local taxes may also be charged by your
                city/state/country depending on your order.
              </div>
            </div>
          </div>
        </div>
        {/* 4th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFour">
            <button
              className="
    justify-between
    collapsed
    relative
    flex
    items-center
    w-full
    p-[30px]
    font-druk text-[20px] leading-[24px] font-bold text-left
    rounded-xl
    transition
    focus:outline-none
    border
  "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              HOW DO I INSTALL SAFEDROP™ ? WHAT IS THE OPTIONAL SOLESAFE SNEAKER
              INSURANCE™ SHOWN AT CHECKOUT?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              Sneaker Insurance™ backed by SoleSafe provides you extra coverage
              for your sneakers from being lost/stolen/damaged during shipping,
              at your doorstep, in your home/apartment, to on your feet.
              SoleSafe Sneaker Insurance™ includes coverage for: accidental
              damage when wearing kicks, theft, flooding, pet damage, mold
              damage, checked luggage when traveling, and more.
              <div className="my-4">
                This is a 12-month (annual) insurance policy from the date of
                purchase.
              </div>
            </div>
          </div>
        </div>
        {/* 5th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFive">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              WHAT IS THE OPTIONAL SOLESAFE PACKAGE PROTECTION SHOWN AT
              CHECKOUT?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Package Protection backed by SoleSafe provides you extra coverage
              for all products from being lost/stolen/damaged when shipped from
              the retailer/store to you.
              <div className="my-4">
                Coverage is active only while products are in-transit/shipped.
                Once items non-sneaker items are delivered, your coverage ends.
                For sneakers, it will extend for 12-months (1-year) from the
                date of purchase.
              </div>
            </div>
          </div>
        </div>
        {/* 6th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSix">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              HOW DO I FILE AN INSURANCE CLAIM?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              The best and fastest way to file a claim, is by downloading the
              SoleSafe mobile app. Otherwise, you can email claims@solesafe.co
              with the details and one of our adjusters will be excited to help
              you resolve your claim.
            </div>
          </div>
        </div>
        {/* 7th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSeven">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
              WHAT IS THE SAFEDROP FEE?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              For all product drops, you will see a SafeDrop fee as part of your
              transaction. Our mission is to make entering and winning raffles a
              fair experience. To do this requires intelligent, sophisticated
              technology that fights bots/scammers, secures your information,
              scales with high traffic, and continues to create fun experiences.
              This means SafeDrop must be able to invest in our resources and
              technologies to sustain a superior shopping experience enabling us
              to sustain our business while you get your hands on coveted
              products.
              <div className="my-4 font-bold">
                IMPORTANT NOTE: You only pay the SafeDrop™ fee if you get a “W”
                and if your purchase is successfully completed.
              </div>
            </div>
          </div>
        </div>
        {/* 8th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingEight">
            <button
              className="
              justify-between
              collapsed
              relative
              flex
              items-center
              w-full
              p-[30px]
              font-druk text-[20px] leading-[24px] font-bold text-left
              rounded-xl
              transition
              focus:outline-none
              border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="true"
              aria-controls="collapseEight"
            >
              CAN I ENTER THE SAME DROP MULTIPLE TIMES?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            {/* <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]"> */}
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              Unfortunately, that is not how it works. We want everyone in the
              community to have fair chance to win and limit each raffle entry
              to one per person.
              <div className="my-4">
                We will say that our EntryBoost™ technology accounts for many
                factors including store loyalty to improve your chances to win a
                drop.
              </div>
            </div>
          </div>
        </div>
        {/* 9th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingNine">
            <button
              className="
              justify-between
              collapsed
              relative
              flex
              items-center
              w-full
              p-[30px]
              font-druk text-[20px] leading-[24px] font-bold text-left
              rounded-xl
              transition
              focus:outline-none
              border
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="true"
              aria-controls="collapseNine"
            >
              WHY DO YOU NEED MY CREDIT CARD DETAILS WHEN ENTERING DROPS?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Our mission is to keep it fair and safe for everyone entering
              drops. Your credit card information is a key indicator confirming
              your identity and helpful security measure when deliver fair
              drops. Plus, we get to keep bots out, identify any fraudulent
              activity, and will only charge if you win.
            </div>
          </div>
        </div>
        {/* 10th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTen">
            <button
              className="
              justify-between
              collapsed
              relative
              flex
              items-center
              w-full
              p-[30px]
              font-druk text-[20px] leading-[24px] font-bold text-left
              rounded-xl
              transition
              focus:outline-none
              border
        
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="true"
              aria-controls="collapseTen"
            >
              WHO IS SHIPPING THE PRODUCT?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue   text-[16px] leading-[24px]">
              SafeDrop is not shipping or handling your order. This will be done
              directly from your favorite retailers. They will manage everything
              from order confirmation, shipping, and delivery of your order.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customers;
