import React from "react";
import blackDotBox from "../../assets/images/svg/black-dot-box.svg";
import jake from "../../assets/images/jpg/jake.jpg";
import tyrre from "../../assets/images/jpg/tyrre.jpg";
import meka from "../../assets/images/jpg/meka.jpg";
import blackBox from "../../assets/images/svg/blackBox.svg";

function Advisors() {
  return (
    <React.Fragment>
      <h3 className="text-center text-black font-druk font-bold text-[22px] leading-[28px] pb-20 md:py-32 overflow-hidden">
        ADVISORS
      </h3>
      <div className=" grid grid-cols-10  xl:flex gap-12 space-y-12 sm:space-y-0 sm:gap-32 xl:gap-18  place-content-center">
        {/* jake  */}
        <div className="relative z-1 max-w-sm xl:max-w-xs xl:order-2 -rotate-[5deg] rounded overflow-visible bg-white border-2 border-black col-start-3 col-span-6 sm:col-start-3 sm:col-span-3 lg:col-start-2 lg:col-span-4 xl:col-start-2 xl:col-span-3">
          <div className="p-4  bg-white">
            <img className="w-full border-2 border-black" src={jake} />
          </div>

          <div className="text-center px-2  bg-white">
            <div className="font-druk text-black font-black text-[18px] leading-[18px]">
              JAKE RUDIN
            </div>
            <div className="font-inter font-normal text-black text-[18px] sm:text-[20px] py-2 leading-[24.2px]">
              Sr. Manager, Digital Tech <br /> Adidas
            </div>
          </div>
          <div className="absolute z-[-100] top-[-29%]  left-[-42%] xl:top-[-32%] xl:left-[-42%] rotate-[6.5deg]">
            <img src={blackBox} className="h-[23rem] xl:h-[29.5rem] " />
          </div>
        </div>
        {/* Tyrre  */}
        <div className="relative z-1 max-w-sm xl:max-w-xs  xl:order-last rotate-[6deg] rounded overflow-visible bg-white border-2 border-black col-start-3 col-span-6 sm:col-start-6  sm:col-span-3 lg:col-start-6 lg:col-span-4 xl:col-start-5 xl:col-span-3 ">
          <div className="p-4  bg-white">
            <img className="w-full border-2 border-black" src={tyrre} />
          </div>

          <div className="text-center px-2  bg-white">
            <div className="font-druk text-black font-black text-[18px] leading-[18px]">
              TYRRE BURKS
            </div>
            <div className="font-inter font-normal text-black text-[20px] py-2 leading-[24.2px]">
              Chief Executive Officer
              <br /> Players Health
            </div>
          </div>
          <div className="absolute z-[-100] top-[-31%] left-[-44%] sm:top-[-29%] sm:left-[-40%] xl:top-[-32%] xl:left-[-42%] rotate-[6.5deg]">
            <img src={blackBox} className="h-[23rem] xl:h-[29.5rem]" />
          </div>
        </div>
        {/* Meka */}
        <div className="max-w-sm xl:max-w-xs  xl:order-1 -rotate-[2deg] rounded overflow-visible bg-white border-2 border-black col-start-3 col-span-6 sm:col-start-4 sm:col-span-4 sm:m-14 lg:m-0 lg:col-start-4 lg:col-span-4 xl:col-start-8 xl:col-span-3">
          <div className="p-4  bg-white">
            <img className="w-full border-2 border-black" src={meka} />
          </div>
          <div className="text-center px-2  bg-white">
            <div className="font-druk text-black font-black text-[18px] leading-[18px]">
              MEKA MORRIS
            </div>
            <div className="font-inter font-normal text-black text-[20px] py-2 leading-[24.2px]">
              Chief Business Officer Minnesota Twins
            </div>
          </div>
          <div className="absolute z-[-100] top-[-31%] left-[-44%] sm:top-[-29%] sm:left-[-40%] xl:top-[-32%] xl:left-[-42%] rotate-[6.5deg]">
            <img src={blackBox} className="h-[23rem] xl:h-[29.5rem]" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Advisors;
