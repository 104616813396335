import React, { useState, useEffect, useRef } from "react";
import aboutBox1 from "../../assets/images/png/about-box-1.png";
import aboutBox2 from "../../assets/images/png/about-box-2.png";
import aboutBox3 from "../../assets/images/png/about-box-3.png";
import aboutBox4 from "../../assets/images/png/about-box-4.png";
import "./featureCarousel.scss";

const items = [
  {
    id: 1,
    label: (
      <>
        <p>COMPETE WITH</p>
        <p>CHARACTER</p>
      </>
    ),
    feature: <img src={aboutBox1} />,
  },
  {
    id: 2,
    label: (
      <>
        <p>RELENTLESS COMMITMENT </p>
        <p>TO PROTECT THE CUSTOMER</p>
      </>
    ),
    feature: <img src={aboutBox2} />,
  },
  {
    id: 3,
    label: (
      <>
        <p>TRANSPARENCY BREEDS</p>
        <p>TRUST</p>
      </>
    ),
    feature: <img src={aboutBox3} />,
  },
  {
    id: 4,
    label: (
      <>
        <p>INNOVATE WITHOUT</p>
        <p>BOUNDARIES</p>
      </>
    ),
    feature: <img src={aboutBox4} />,
  },
];

const totalItems = items.length;

const margin = 50;

const FeatureCarousel = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [transformDim, setTransformDim] = useState(0);
  const [initialTransformDone, setInitialTransformDone] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const animateRef = useRef(null);
  const itemRef = useRef(null);

  const increaseCounter = () => {
    if (currentStep < items.length - 1) {
      setCurrentStep((prev) => prev + 1);
      if (currentStep <= 0) {
        setInitialTransformDone(true);
      }
    }
  };

  const decreaseCounter = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (animateRef && itemRef && windowWidth < 640) {
      const width = itemRef.current.getBoundingClientRect().width;
      setItemWidth(width);
      const initialTransform = windowWidth / 2 - width / 2;
      setTransformDim(initialTransform);
      animateRef.current.style.webkitTransform = `-webkit-translateX(-webkit-${initialTransform}px)`;
      setInitialTransformDone(true);
    }
    if (windowWidth >= 640) {
      animateRef.current.style.transform = `translateX(${0}px)`;
    }
  }, [windowWidth]);

  useEffect(() => {
    if (transformDim && initialTransformDone) {
      const transform =
        transformDim -
        currentStep * itemWidth -
        currentStep * 2 -
        currentStep * 20;
      animateRef.current.style.transform = `translateX(${transform}px)`;
    }
  }, [currentStep, transformDim, itemWidth]);

  const calculateWindowWidth = () => {
    setInitialTransformDone(false);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // need debouncing
    window.addEventListener("resize", calculateWindowWidth, true);

    return () =>
      window.removeEventListener("resize", calculateWindowWidth, true);
  });

  return (
    <React.Fragment>
      <div className="feature-carousel absolute -top-52 sm:-top-40 xl:-top-48 2xl:-top-56">
        <div className="float-left ml-8 mb-4 flex sm:hidden  ">
          {/* Slider Left Button  */}
          <button className=" w-14 " onClick={decreaseCounter}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="32.7868"
                cy="32.7868"
                r="32.7869"
                webkitTransform="rotate(-180 32.7868 32.7868)"
                fill="white"
              />
              <path
                d="M45.6837 30.7598L45.6837 34.0753L25.7905 34.0753L34.9082 43.193L32.5542 45.5471L19.4247 32.4176L32.5542 19.2881L34.9083 21.6421L25.7905 30.7598L45.6837 30.7598Z"
                fill="black"
              />
            </svg>
          </button>
          {/* Slider Right Button  */}
          <button className=" w-32" onClick={increaseCounter}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                webkitTransform="rotate(-180 32.7868 32.7868)"
                cx="33.2132"
                cy="32.7869"
                r="32.7869"
                fill="white"
              />
              <path
                d="M20.3204 34.8139L20.3204 31.4984L40.2136 31.4984L31.0959 22.3807L33.4499 20.0267L46.5794 33.1562L33.4499 46.2856L31.0959 43.9316L40.2136 34.8139L20.3204 34.8139Z"
                fill="black"
              />
            </svg>
          </button>
        </div>

        <div className="feature-carousel-alt__items  ">
          <div
            className="feature-carousel-alt__items__animate sm:gap-4 "
            data-translate={currentStep}
            ref={animateRef}
          >
            {items.map((item, index) => {
              if (item) {
                return (
                  <div
                    className={`feature-carousel-alt__items__item ${
                      index === currentStep
                        ? "feature-carousel-alt__items__item--active"
                        : ""
                    } about-us__features`}
                    key={index}
                    ref={itemRef}
                  >
                    <div className=" p-10 font-inter text-[16px] leading-[24px] px-8 sm:col-span-12  feature-carousel-alt__items__item__image">
                      {" "}
                      {item.feature}
                    </div>
                    <div className="text-black px-1 pb-5 font-inter font-bold text-[16px] leading-[24px] text-center sm:col-span-12  feature-carousel-alt__items__item__label">
                      {" "}
                      {item.label}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="sm:flex" key={index}>
                    <h3>{}</h3>
                    <p>{}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeatureCarousel;
