//external imports
import React from "react";
//internal imports
import homePhone1 from "../../assets/images/png/homePhone-1.png";
import homePhone2 from "../../assets/images/png/homePhone2.png";
import homePhone3 from "../../assets/images/png/homePhone3.png";
import "../Footer/footer.scss";

function CollectModules() {
  const connects = [
    {
      id: 1,
      content: [
        "Community is at the heart of sneaker culture. Whether close friends, neighborhood legends, or sneaker historians (yes, they do exist), we are all linked by our passion for the kicks.",
      ],
    },
    {
      id: 2,
      content: [
        "SoleSafe™ believes community shouldn’t cost, come with a membership fee, or feel completely disconnected from our space. That is why we thoughtfully built our own community experience right inside the app.",
      ],
    },
    {
      id: 3,
      content: [
        " Community gets supercharged when you digitally add your sneaker collection to the app. Using what you own or want connects you to everyone.",
      ],
    },
  ];

  const collects = [
    {
      id: 1,
      content: [
        "Managing your sneaker collection is no easy task. That is why we created multiple ways to onboard sneakers to your digital collection.",
      ],
    },
    {
      id: 2,
      content: [
        "SoleSafe™  extends your physical collection into the digital space by enabling you to set your preferences, simplifies searching, tracks the market value, and even shows your sneakers in containers.",
      ],
    },
    {
      id: 3,
      content: [
        " We may not have thought of everything, but together we’ll make it just right.",
      ],
    },
  ];
  const tracks = [
    {
      id: 1,
      content: [
        "SoleSafe™  understands the market's complexities and knows how sneaker values change daily. For that reason, dynamic coverage gives our community clear visibility into how the market impacts their collection value. At the same time, we empower you to take control and adjust your coverage through our intelligent notification system.",
      ],
    },
    {
      id: 2,
      content: [
        "Whether you have a few pairs or large collection, SoleSafe™  is flexible and calculates all the details tailored to your stash.",
      ],
    },
  ];
  return (
    <div className="mt-6 sm:mt-72 lg:mt-24 xl:mb-96 mb-40 lg:mb-0">
      <div className="gap-[10rem]">
        {/* collect module  */}
        <div className="relative col-start-1 col-span-8 bg-yellow rounded-[30px] px-2 py-20 mx-6 lg:mx-6 gap-2 2xl:w-[1424px] 2xl:mx-auto">
          <div className="sm:w-[35rem] lg:w-100 xl:w-1/3 absolute top-20 xl:top-8 sm:mx-28 lg:mx-0 lg:mt-0">
            <img
              src={homePhone2}
              className=" w-100 h-100 mt-[-35%] lg:mt-[0%]  xl:mt-[-20%] 2xl:mt-[-24%] lg:animate-waving-mob2"
            />
          </div>
          <div className="w-full  text-center lg:text-start mt-[18rem] md:mt-[30rem] lg:mt-0 sm:px-20 lg:pl-[34rem]">
            <div className="font-inter text-[20px] font-bold leading-[26px] text-blue py-5">
              COLLECT
            </div>
            <div className="font-druk text-[30px] sm:text-[48px] leading-[40px] sm:leading-[55px] font-medium text-black mx-6 sm:mx-28 lg:mx-0  pr-0 xl:pr-52 2xl:pr-80">
              Inventory management tools
            </div>
            <div className=" text-black font-inter font-normal text-[16px] pt-5 sm:mx-28 lg:mx-0">
              {collects.map((collect) => {
                return (
                  <ul key={collect.id}>
                    <li className="py-2">{collect.content}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>

        {/* //connect module  */}
        <div className="relative col-start-1 col-span-8 bg-blue rounded-[30px] px-2 lg:px-20 mx-6 py-20 xl:py-10 my-32 sm:my-48 gap-2 2xl:w-[1424px] 2xl:mx-auto">
          <div className="absolute  sm:right-[12rem]  lg:right-0 w-[22rem] sm:w-[35rem] lg:w-1/2 xl:w-1/3 flex justify-center ">
            <img
              src={homePhone1}
              className=" w-100 h-100 sm:mx-28 lg:mx-0 mt-[-40%] lg:mt-[-22%] lg:animate-waving-mob1 "
            />
          </div>
          <div className="w-full lg:w-3/5 text-center lg:text-start mt-[18rem] md:mt-[30rem] lg:mt-0 sm:px-20 lg:px-0 ">
            <div className="font-inter text-[20px] font-bold leading-[26px] text-yellow py-5 ">
              CONNECT
            </div>
            <div className="font-druk text-[30px] sm:text-[48px] leading-[40px] sm:leading-[55px] font-medium text-black mx-6 sm:mx-28 lg:mx-0 pr-0 xl:pr-48 2xl:pr-72">
              <span>Sneakers</span>
              <br></br>
              <span>connect us</span>
              <br></br>
              <span>together</span>
            </div>
            <div className=" text-black font-inter font-normal text-[16px] pt-5 mx-2  lg:pr-20 sm:mx-28 lg:mx-0 ">
              {connects.map((connect) => {
                return (
                  <ul key={connect.id}>
                    <li className="py-2">{connect.content}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>

        {/* Track module  */}

        <div className="relative col-start-1 col-span-8 bg-white  rounded-[30px] px-2 py-12 mx-6 lg:mx-6 gap-2 2xl:w-[1424px] 2xl:mx-auto">
          <div className="sm:w-[35rem] lg:w-100 xl:w-1/3 absolute top-20 xl:top-8 sm:mx-28 lg:mx-0 lg:mt-32">
            <img
              src={homePhone3}
              className=" w-100 h-100 mt-[-35%] lg:mt-[0%]  xl:mt-[-50%] lg:animate-waving-mob2 "
            />
          </div>
          <div className="w-full  text-center lg:text-start mt-[18rem] md:mt-[30rem] lg:mt-0 sm:px-20 lg:pl-[34rem]">
            <div className="font-inter text-[20px] font-bold leading-[26px] text-track-red py-4 mt-4">
              TRACK
            </div>
            <div className="font-druk text-[30px] sm:text-[48px] leading-[40px] sm:leading-[55px] font-medium text-black sm:mx-32 lg:mx-0 pr-0 xl:pr-52 2xl:pr-56">
              Know your collection value in real-time
            </div>
            <div className=" text-black font-inter font-normal text-[16px] pt-5 lg:pr-20 sm:mx-28 lg:mx-0">
              {tracks.map((track) => {
                return (
                  <ul key={track.id}>
                    <li className="py-2">{track.content}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectModules;
