import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PartnerShoe from "../../assets/images/svg/partner-shoe.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import coin1 from "../../assets/images/png/coin-1.png";

const BlueBox = (props) => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
      <div className="mx-4 sm:mx-32 lg:mx-12 2xl:mx-52 mb-56 mt-56 sm:mt-[20rem] xl:mt-56 pt-[16rem] lg:pt-0 bg-blue rounded-[30px] grid lg:flex ">
        <div className="absolute right-[0rem] sm:right-[12rem] -mt-[18rem] sm:-mt-[24rem] lg:mt-0  lg:right-0 w-[22rem] sm:w-[35rem] lg:w-1/2 xl:w-3/5 flex justify-center ">
          <img alt=""
            src={coin1}
            className="absolute sm:flex sm:right-[8rem] lg:right-[13rem] xl:right-[20rem] 2xl:right-[29rem] -top-[45rem] sm:-top-[36rem] xl:-top-[22rem] w-12 h-12 sm:w-36 sm:h-36 z-20"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          />
          <img alt=""
            src={coin1}
            className="absolute sm:flex left-[-0rem] sm:left-[-3rem] xl:left-[5rem] 2xl:left-[14rem]  top-[120rem] sm:top-[125rem] lg:top-[125rem]  w-16 h-16 sm:w-32 sm:h-32 z-20"
            style={{ transform: `translateY(-${offsetY * 0.4}px)` }}
          />
          <img alt=""
            src={PartnerShoe}
            className="p-4 mt-[37rem] z-10 "
            style={{ transform: `translateY(-${offsetY * 0.14}px)` }}
          />{" "}
        </div>
        <div className="w-full lg:w-1/2 xl:w-2/5 text-center lg:text-start pb-12  sm:py-20 px-4 sm:px-0 lg:pl-12">
          <div className="font-inter text-[14px] sm:text-[16px] font-bold leading-[26px] text-black">
            Retailers, Brands, Secondary Marketplaces, Auction Houses, and
            Insurance Brokers
          </div>
          <div className="sm:w-4/5 lg:w-full mx-auto lg:mx-0 font-druk text-[22px] sm:text-[36px] leading-[40px] font-bold sm:font-medium text-white py-8">
            Don’t Have Shopify? We can still protect your customers
          </div>
          <div className="sm:w-4/5 lg:w-auto mx-auto lg:mx-0 font-inter text-black font-normal text-[16px]">
            Embedding <b>Sneaker Insurance™</b> in the payment experience
            enhances trust and elevates the buying process for the sneaker
            community.
            <b> Drop us a note to discuss online and/or in-store options.</b>
          </div>
          <button
            className="flex justify-center items-center cursor-pointer  w-[90%] mx-auto lg:mx-0 sm:w-56 sm:my-0 mt-9 sm:mt-10 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] py-3 z-30"
            type="submit"
            onClick={() => {
              navigate("/contact", { state: "partner" });
            }}
          >
            <div className="flex justify-center items-center z-30">
              <h1>LET'S PARTNER </h1>
              <img alt=""
                src={scrollArrow}
                className="scroll-smooth rotate-90 ml-4 "
              />
            </div>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlueBox;
