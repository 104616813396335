import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";

const urlPrefix = process.env.REACT_APP_STRAPI_API;
const navsUrl = `${urlPrefix}/api/navigation?populate=*`;

const NavigationLink = ({ label, href, theme, pathname }) => (
  <li
    className={`navigation__ul__li ${label === "home" ? "hide-desktop" : ""}`}
  >
    <Link
      className={`navigation__ul__li__link ${
        theme === "dark" ? "navigation__ul__li__link--dark" : ""
      } 
  ${theme === "light" ? "navigation__ul__li__link--light" : ""} 
  ${pathname === href ? "fill-link" : ""}`}
      to={href}
    >
      <span
        className="NavbarDot"
        style={{
          borderColor: pathname === href ? "#FCFFEB" : "transparent",
        }}
      ></span>
      <span className="ml-1">{label}</span>
    </Link>
  </li>
);

const renderNavLinks = (links, theme, pathname) => (
  <ul className={`navigation__ul`}>
    {links.map((d) => (
      <NavigationLink
        key={d.id}
        label={d.label}
        href={d.href}
        theme={theme}
        pathname={pathname}
      />
    ))}
  </ul>
);

const Navbar = (props) => {
  const [navLinks, setNavLinks] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { pathname } = useLocation();

  const fetchNavLinks = async () => {
    try {
      const response = await fetch(navsUrl);
      const { data } = await response.json();
      setNavLinks(data);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  React.useEffect(() => {
    fetchNavLinks();
  }, []);

  return (
    <nav className="navigation">
      {errorMessage && (
        <p className="error color-red bg-white"> {errorMessage} </p>
      )}
      <div className="lg:flex justify-between">
        {navLinks &&
          renderNavLinks(navLinks.attributes.links, props.theme, pathname)}
        <div className="">
          {navLinks &&
            renderNavLinks(
              navLinks.attributes.rightNavLinks,
              props.theme,
              pathname
            )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
