import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import blackArrow from "../../../assets/images/png/black-arrow.png";
import scrollArrow from "../../../assets/images/svg/scroll-arrow.svg";
import communityProductFeatures1 from "../../../assets/images/svg/community_product_features_1.svg";
import communityProductFeatures2 from "../../../assets/images/svg/community_product_features_2.svg";
import communityProductFeatures3 from "../../../assets/images/svg/community_product_features_3.svg";
import communityProductFeatures4 from "../../../assets/images/svg/community_product_features_4.svg";
import communityProductFeatures5 from "../../../assets/images/svg/community_product_features_5.svg";
import communityProductFeatures6 from "../../../assets/images/svg/community_product_features_6.svg";
import "./productFeatures.scss";

const ProductFeatures = () => {
  const navigate = useNavigate();
  const [showRealSneaker, setShowRealSneaker] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [expandedItem, setExpandedItem] = useState(1);

  const animateRef = useRef(null);
  const itemRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [transformDim, setTransformDim] = useState(0);
  const [initialTransformDone, setInitialTransformDone] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const margin = 25;

  const ProductFeaturesContent = [
    {
      id: 1,
      label: "SEE MEMBER COLLECTIONS",
      description:
        "Our platform allows members to catalog their sneaker collection to keep everything in one place. Easily view member collections in a few clicks.",
      image: <img src={communityProductFeatures1} />,
    },
    {
      id: 2,
      label: "GET SNEAKER INSURANCE™",
      description: (
        <div>
          Members can add SoleSafe's <b>Sneaker Insurance™</b> coverage to a
          grail, few pairs, or entire collection. SoleSafe's{" "}
          <b>Sneaker Insurance™</b> is fully backed by two A-Rated Insurance
          partners.&nbsp;
          <Link
            to="/sneakerheads#real-sneaker"
            onClick={() => setShowRealSneaker(true)}
          >
            <b className="underline underline-offset-4 ">Learn more</b>
          </Link>
        </div>
      ),
      image: <img src={communityProductFeatures2} />,
    },

    {
      id: 3,
      label: "SHOWCASE YOUR COLLECTION",
      description:
        "A social way to display your collection to rest of the community.",
      image: <img src={communityProductFeatures3} />,
    },
    {
      id: 4,
      label: "LIVE STREAMING",
      description:
        "Host live audio or video events to bring everyone together.",
      image: <img src={communityProductFeatures4} />,
    },

    {
      id: 5,
      label: "COMMUNITY INSIGHTS DASHBOARD",
      description:
        "Go beyond the profile pics to see what people have, what they want, and how to use these insights to continue improving their experience inside the platform or in real life.",
      image: <img src={communityProductFeatures5} />,
    },
    {
      id: 6,
      label: "COLLECT CONTRIBUTIONS",
      description:
        "Let the community show some love for providing dope experiences by making contributions to your digital wallet.",
      image: <img src={communityProductFeatures6} />,
    },
  ];

  const toggleItem = (itemId) => {
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
      // Update currentStep to the clicked item's index
      const clickedItemIndex = ProductFeaturesContent.findIndex(
        (item) => item.id === itemId
      );
      setCurrentStep(clickedItemIndex);
    }
  };

  const increaseCounter = () => {
    if (currentStep < ProductFeaturesContent.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (animateRef && itemRef && windowWidth > 768) {
      const width = itemRef.current.getBoundingClientRect().width;
      setItemWidth(width);
      const initialTransform = windowWidth / 2 - width / 2 - margin;
      setTransformDim(initialTransform);
      animateRef.current.style.transform = `translateX(${initialTransform}px)`;
      setInitialTransformDone(true);
    }
    if (windowWidth < 768) {
      animateRef.current.style.transform = `translateX(${0}px)`;
    }
  }, [windowWidth]);

  useEffect(() => {
    if (transformDim && initialTransformDone) {
      const transform =
        transformDim - currentStep * itemWidth - currentStep * margin * 2;
      animateRef.current.style.transform = `translateX(${transform}px)`;
    }
  }, [currentStep, transformDim, itemWidth]);

  const calculateWindowWidth = () => {
    setInitialTransformDone(false);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // need debouncing
    window.addEventListener("resize", calculateWindowWidth, true);

    return () =>
      window.removeEventListener("resize", calculateWindowWidth, true);
  });
  return (
    <React.Fragment>
      <div className="mb-8 xl:mb-20 mt-20 sm:mt-0 xl:mt-24 2xl:mt-4">
        {/* Product Features  */}
        {/* <div className="col-span-12 sm:col-span-6 sm:ml-11 mx-4">
          <div className=" mb-14 text-center sm:text-left">
            <h1 className="w-1/2 mx-auto sm:mx-0 font-druk font-medium text-[30px] leading-[48px] sm:text-[48px]">
              PRODUCT FEATURES
            </h1>
            <h2 className="font-inter font-normal text-[16px] mt-4 w-1/2 sm:w-auto mx-auto sm:mx-0">
              Designed with Sneaker Communities in mind.
            </h2>
          </div>
          <div>
            {ProductFeaturesContent.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`${
                    expandedItem === item.id
                      ? "bg-yellow text-black"
                      : "bg-black text-white"
                  } col-span-5 border border-white rounded-lg mb-3  p-4`}
                  onClick={() => {
                    toggleItem(item.id);
                    console.log("item.id type:", typeof item.id);
                    console.log("expandedItem type:", typeof expandedItem);
                  }}
                  ref={itemRef}
                >
                  <div className="flex justify-between">
                    <div className="font-druk font-bold text-[15px] cursor-pointer">
                      {item.label}
                    </div>
                    {expandedItem === item.id && (
                      <div>
                        <img src={blackArrow} className="cursor-pointer" />
                      </div>
                    )}
                  </div>
                  {expandedItem === item.id && (
                    <div>
                      <div className="font-inter font-normal text-[16px] mt-4">
                        {item.description}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          Request a demo
          <div className="bg-white text-black rounded-full flex justify-center items-center sm:justify-start mt-16 mx-auto sm:mx-4 w-3/5 xl:w-2/5 2xl:w-1/3 ">
            <button
              className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto"
              type="submit"
              onClick={() => {
                navigate("/contact", { state: "partner" });
              }}
            >
              <div className="flex justify-center items-center lg:w-full py-4 ">
                <h1>REQUEST A DEMO </h1>
                <img
                  src={scrollArrow}
                  className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                />
              </div>
            </button>
          </div>
        </div> */}

        {/* Image - Product Features  */}
        {/* <div
          ref={scrollContainerRef}
          onWheel={handleScroll}
          className="col-span-6 flex items-center relative bg-purple-900"
        >
          {ProductFeaturesContent.map((item, index) => {
            const isCurrentItem = expandedItem === item.id;
            const nextIndex = (index + 1) % ProductFeaturesContent.length;

            return (
              <div
                key={index}
                ref={index === 0 ? itemRef : null}
                onClick={() => toggleItem(item.id)}
                className="flex justify-evenly items-end absolute inset-y-0 right-0 transition-transform w-5/6"
              >
                <div
                  ref={isCurrentItem ? animateRef : null}
                  className={`${
                    isCurrentItem
                      ? "animate-productFeaturesHorizontalFloat"
                      : ""
                  }`}
                >
                  {isCurrentItem && item.image}
                </div>
                <div
                  className={`${
                    index === isCurrentItem ? "" : animationClass
                  } ${isCurrentItem ? "opacity-50" : "opacity-100"}`}
                >
                  {isCurrentItem && ProductFeaturesContent[nextIndex].image}
                </div>
              </div>
            );
          })}
        </div> */}
        {/* <div className="product-features">
          <div className="product-features__buttons float-right -mt-[10rem] hidden sm:block"> */}
        <div className="grid grid-cols-1 overflow-hidden relative">
          <div className="product-features-alt__items grid grid-cols-12">
            <div className="col-span-12 sm:col-span-6 sm:pl-12 2xl:pl-24 bg-black z-50">
              <div className="mb-14 text-center sm:text-left">
                <h1 className="w-1/2 mx-auto sm:mx-0 font-druk font-medium text-[30px] leading-[32px] sm:leading-[48px] sm:text-[48px]">
                  PRODUCT FEATURES
                </h1>
                <h2 className="font-inter font-normal text-[16px] leading-[23px] mt-4 w-1/2 sm:w-auto mx-auto sm:mx-0">
                  Designed with Sneaker Communities in mind.
                </h2>
              </div>
              <div className="mx-3 sm:mx-0">
                {ProductFeaturesContent.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="bg-lightGray rounded-b-xl"
                      onClick={() => {
                        toggleItem(item.id);
                      }}
                      ref={itemRef}
                    >
                      <div
                        className={`${
                          expandedItem === item.id
                            ? "bg-yellow text-black"
                            : "bg-black text-white"
                        } col-span-5 border border-white rounded-lg mb-3  p-4`}
                      >
                        <div className="flex justify-between">
                          <div
                            className={`${
                              expandedItem === item.id ? "w-1/2 xl:w-auto" : ""
                            } font-druk font-bold text-[15px] cursor-pointer`}
                          >
                            {item.label}
                          </div>
                          {expandedItem === item.id && (
                            <div>
                              <img
                                src={blackArrow}
                                className="cursor-pointer hidden sm:block"
                              />
                              <div className="block sm:hidden w-3 h-3 border-t-[2px] border-r-[2px] border-black -rotate-45" />
                            </div>
                          )}
                        </div>
                        {expandedItem === item.id && (
                          <div>
                            <div className="font-inter font-normal text-[16px] mt-4">
                              {item.description}
                            </div>
                          </div>
                        )}
                      </div>
                      {expandedItem === item.id && (
                        <div className="grid place-items-center mb-4 sm:hidden">
                          <div className="h-[18.5rem] overflow-y-hidden">
                            {" "}
                            {item.image}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="bg-white text-black rounded-full flex justify-center items-center sm:justify-start mt-16 mx-auto sm:mx-0 w-3/5 xl:w-2/5 2xl:w-1/3 ">
                <button
                  className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto"
                  type="submit"
                  onClick={() => {
                    navigate("/contact", { state: "partner" });
                  }}
                >
                  <div className="flex justify-center items-center lg:w-full py-4 ">
                    <h1>REQUEST A DEMO </h1>
                    <img
                      src={scrollArrow}
                      className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                    />
                  </div>
                </button>
              </div>
            </div>
            {/* Product Feature Images  */}
            <div className="hidden sm:flex items-end">
              <div
                className="product-features-alt__items__animate col-span-6 absolute left-[16rem] z-10"
                data-translate={currentStep}
                ref={animateRef}
              >
                {ProductFeaturesContent.map((item, index) => {
                  if (item) {
                    return (
                      <div
                        className={`product-features-alt__items__item ${
                          expandedItem === item.id
                            ? "product-features-alt__items__item--active"
                            : ""
                        }`}
                        key={index}
                        ref={itemRef}
                      >
                        <div className="">
                          <h1 className="">{item.image}</h1>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="product-features-alt__items__item product-features-alt__items__item--hide"
                        key={index}
                      >
                        <h3>{}</h3>
                        <p>{}</p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductFeatures;
