import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import CommunityHowItWorks from "../../assets/images/svg/community_how_it_works.svg";
import CommunityHowItWorks1 from "../../assets/images/svg/community_how_it_works_1.svg";
import CommunityHowItWorks2 from "../../assets/images/svg/community_how_it_works_2.svg";
import CommunityHowItWorks3 from "../../assets/images/svg/community_how_it_works_3.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

const HowItWorks = () => {
  const navigate = useNavigate();

  const scrollContainerRef = useRef(null);

  const HowItWorksContent = [
    {
      id: 1,
      heading: "Explore",
      description:
        "We've got everything from collector inventories to dedicated community spaces, all tailored for anyone with a passion for sneakers.",
      image: <img src={CommunityHowItWorks} alt="Community Image" />,
    },
    {
      id: 2,
      heading: "Sign-Up",
      description: (
        <div>
          Bring people and passion together. Start your community with
          SoleSafe’s simple community management onboarding experience. Follow
          our simple sign-up process to begin engaging your community.
          <b className="ml-1">
            If you already have a community on another platform, we will help
            you plan a move to SoleSafe!
          </b>{" "}
        </div>
      ),
      image: <img src={CommunityHowItWorks1} alt="Community Image" />,
    },
    {
      id: 3,
      heading: "Engage",
      description: (
        <div>
          Host live audio/video events, start discussions, share trends,
          showcase sneaker collections, and offer protection with
          <b className="ml-1">Sneaker Insurance™.</b>{" "}
        </div>
      ),
      image: <img src={CommunityHowItWorks2} alt="Community Image" />,
    },
    {
      id: 4,
      heading: "Earn",
      description:
        "Our platform doesn’t just facilitate conversations and community; you can receive contributions. Let members show their love for the experience you provide.",

      image: <img src={CommunityHowItWorks3} alt="Community Image" />,
    },
  ];

  const handleScroll = (evt) => {
    evt.preventDefault();
    scrollContainerRef.current.scrollLeft += evt.deltaX;
  };

  return (
    <React.Fragment>
      <div className="py-24 2xl:pl-12">
        <div className="mx-auto text-center">
          <h1 className="w-2/3 sm:w-2/5 xl:w-1/3 2xl:w-1/4 font-druk font-medium text-[30px] leading-[32px] sm:text-[48px] sm:leading-[48px] mx-auto">
            HOW IT WORKS
          </h1>
          <h2 className="font-inter font-normal text-[16px] leading-[23px] my-4 w-3/4 sm:w-[55%] xl:w-3/5 mx-auto">
            We partner with you to build a community that works as a digital
            extension of what you’re doing IRL (in real life). Our platform
            gives your members al of the tools to collect, connect, track, and
            insure their collections. Plus, we work with you to generate revenue
            where we do.
          </h2>
        </div>

        <div className="mt-20 xl:mt-14 2xl:pl-12 2xl:mr-24">
          <div
            className="w-auto flex gap-6 mr-12 xl:mr-0 xl:gap-0 overflow-x-auto category-scroller"
            ref={scrollContainerRef}
            onWheel={handleScroll}
          >
            {HowItWorksContent.map((ele, id) => (
              <div key={id} className="w-[20rem] xl:w-[25%] h-auto px-8 py-5">
                <div className="w-[20rem] xl:w-[100%] h-[17rem] 2xl:h-[20rem] 2xl:p-8 flex border border-white rounded-xl">
                  {ele.image}
                </div>
                <div>
                  <h1 className="font-druk font-bold text-[22px] leading-[28px] my-4">
                    {ele.heading}
                  </h1>
                  <h2 className="font-inter font-normal text-[16px] leading-[23px]">
                    {ele.description}
                  </h2>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-10 sm:flex justify-center items-center">
            <button
              className="flex justify-center items-center w-3/5 sm:w-56 2xl:w-64  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
              type="submit"
              onClick={() => {
                navigate("/contact", { state: "partner" });
              }}
            >
              REQUEST A DEMO
              <div className="cursor-pointer rotate-90 ml-6">
                <img src={scrollArrow} className="scroll-smooth" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HowItWorks;
