import { useState, useEffect } from "react";

import "./social-media.scss";

const urlPrefix = process.env.REACT_APP_STRAPI_API;

const socialUrl = `${urlPrefix}/api/social-medias?populate=*`;

const SocialMedia = (props) => {
  const [socialLinks, setSocialLinks] = useState(null);

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(socialUrl);
      const { data } = await response.json();
      setSocialLinks(data);
    } catch (e) {
      console.log(e);
    }
  };

  const renderSocialLinks = () => {
    if (socialLinks) {
      return socialLinks.map((d) => {
        const {
          attributes: {
            title,
            link,
            logoblack: {
              data: {
                attributes: { url: urlLight },
              },
            },
            logo: {
              data: {
                attributes: { url: urlDark },
              },
            },
          },
        } = d;
        const url = props.theme === "dark" ? urlDark : urlLight;
        return (
          <a
            href={link}
            key={d.id}
            target="_black"
            className="social-links__link"
          >
            <img
              className="social-links__link__img"
              src={`${urlPrefix}${url}`}
              alt={title}
            />
          </a>
        );
      });
    }
  };

  useEffect(() => {
    fetchSocialLinks();
  }, []);

  return (
    <>
      <div className="social-links">{renderSocialLinks()}</div>
    </>
  );
};

export default SocialMedia;
