import React from "react";
import { useNavigate } from "react-router-dom";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import "../ForSneakerheads/insurance-faqs.scss";

const PartnerFaq = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="w-3/5 sm:w-3/4 xl:w-2/3 2xl:w-2/5 mx-14 sm:mx-auto text-center font-druk font-medium text-[30px] leading-[32px] sm:text-[48px] sm:leading-[48px]">
        FREQUENTLY ASKED QUESTIONS
      </div>
      <div
        className="accordion overflow-y-auto overflow-x-hidden h-[30rem] mt-10 mx-6 mb-14 text-white rounded-xl space-y-4 2xl:mx-[10rem] insurance-faqs"
        id="accordionExample"
      >
        {/* 1st */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
             
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              WHAT IS SOLESAFE™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              SoleSafe™ is an insurance and community management platform for
              sneakers.
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwo">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              WHAT WILL HAPPEN IF A CUSTOMER FILES A CHARGEBACK EVEN IF THEY'RE
              INSURED WITH SOLESAFE™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              SoleSafe™ will refund the insurance premium plus taxes/fees to the
              store during an effort to issue payment to the customer.
            </div>
          </div>
        </div>

        {/* 3 rd  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThree">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
       
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              IF WE NEED TO ISSUE A REFUND, DOES SOLESAFE™ REFUND US?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px] ">
              Yes! We will refund any insurance premium plus taxes/fees back to
              you.
            </div>
          </div>
        </div>
        {/* 4th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFour">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              CAN THE TOGGLE BE SWITCHED TO AUTOMATICALLY OFF INSTEAD OF
              AUTOMATICALLY BEING TOGGLED ON?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              Yes! We do not recommend this approach as it will impact
              conversion and create unnecessary confusion for customers thinking
              they had insurance at the point of sale. Our team is flexible
              about what makes sense for you and your customer community.
            </div>
          </div>
        </div>
        {/* 5th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFive">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              WHAT CUSTOMER INFORMATION WILL YOU HAVE ACCESS TO ONCE A CUSTOMER
              IS INSURED WITH SOLESAFE™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              SoleSafe™ will only receive the following customer information
              post-purchase: First Name, Last Name, Email, Phone Number (if
              provided), Mailing Address, and Order Information.
            </div>
          </div>
        </div>
        {/* 6th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSix">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              WHAT PERCENTAGE DO I GET OF THE INSURANCE PREMIUM?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              A default percentage is applied when installing the SoleSafe™ app
              on Shopify. We recommend completing our contact form, so a team
              member can speak directly with you. Our team really enjoys
              speaking with our partners and discussing shared objectives to
              reach best outcomes for community.
            </div>
          </div>
        </div>
        {/* 7th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingSeven">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
              HOW IS MY PERCENTAGE PAID OUT?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue  text-[16px] leading-[24px]">
              SoleSafe™ pays you based on the negotiated percentage at the end
              of every month. This is actually happening automatically via
              integration with Shopify.
            </div>
          </div>
        </div>
        {/* 8th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingEight">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="true"
              aria-controls="collapseEight"
            >
              IF WE HAVE AN UPSET CUSTOMER WITH A SOLESAFE™ RELATED CONCERN,
              SHOULD WE RELAY THEM TO SOLESAFE™ CUSTOMER SERVICE?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              Yes! SoleSafe™ is an extension of your customer service experience
              and is committed to resolving any claims/issues expeditiously. Our
              team has personally experienced mishandled and/or poor claims
              processes. We know the importance of getting claims resolved
              efficiently, so your customers can get back to doing what they
              love - supporting your business!
            </div>
          </div>
        </div>
      </div>
      {/* Contact us  */}
      <div className="mb-60 sm:flex justify-center items-center">
        <button
          className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
          onClick={() => {
            navigate("/contact", { state: "partner" });
          }}
        >
          CONTACT US
          <div className="cursor-pointer rotate-90 ml-6">
            <img src={scrollArrow} className="scroll-smooth" />
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default PartnerFaq;
