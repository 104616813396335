//external imports
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

//internal imports
import error404 from "../../assets/images/svg/page-not-found.svg";
import Header from "../../components/Header";

const PageNotFound = () => {
  return (
    <div>
      {" "}
      <Helmet>
        <style type="text/css">
          {`body {
            background-color: black;
        }
    .header__menu-icon__bar {
        background-color: white
        }`}
        </style>
      </Helmet>
      <Header logoColor="white" barColor="white" theme="dark" />
      <div className="flex flex-col justify-center mt-24 sm:mt-36 2xl:mt-60">
        <h1 className="font-druk text-[52px] sm:text-[115px]  text-center leading-[55px] sm:leading-[125px] font-medium sm:font-bold ">
          NOTHING
          <br />
          TO SEE HERE...
        </h1>
        <img src={error404} className="lg:px-64 2xl:px-96 2xl:mx-12 sm:-mt-8" />
        <h3 className="mx-4 mb-20 sm:mb-0 sm:mx-0 text-center font-inter font-normal text-[16px] leading-[24px] sm:-mt-20">
          The page you're looking for was not found.{" "}
          <Link to="/">
            <span className="underline underline-offset-8 ">
              Go back home -
            </span>
            <span className="-ml-2 border-white inline-block border-b-2 border-r-2 p-1 -rotate-45" />
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default PageNotFound;
