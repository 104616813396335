//external imports
import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useLocation } from "react-router-dom";
//internal imports
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import GotQuestion from "../../components/Support/GotQuestion";
import SupportFaq from "../../components/Support/SupportFaq";

const Support = (props) => {
  const [formType, setFormType] = useState(
    props.formType ? props.formType : "partner"
  );

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setFormType(state);
    }
  }, []);

  return (
    <React.Fragment>
      <HelmetProvider>
        <div>
          <Helmet>
            <style type="text/css">
              {`body {
                background-color: black;
            }
        .header__menu-icon__bar {
            background-color: white
            }`}
            </style>
          </Helmet>
        </div>
      </HelmetProvider>
      <Header logoColor="white" barColor="white" theme="dark" />
      <div className="xl:ml-[2rem] 2xl:ml-[5rem] sm:my-10  xl:pt-[3rem]">
        <GotQuestion formType={formType} setFormType={setFormType} />
      </div>

      <div className="my-40 sm:mx-8 xl:mx-6 2xl:mx-44 ">
        <SupportFaq formType={formType} setFormType={setFormType} />
      </div>

      <div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Support;
