import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import solesafeBlackLogo from "../../assets/images/svg/solesafe-black-logo.svg";
import blackChecklist from "../../assets/images/svg/black-checklist.svg";
import blurCrosslist from "../../assets/images/svg/blur-crosslist.svg";
import communityDiscord from "../../assets/images/svg/community_discord.svg";
import communityFgroups from "../../assets/images/svg/community_fgroups.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import yellowCoin from "../../assets/images/png/coin-1.png";
import blueCoin from "../../assets/images/png/coin-2.png";
import blueBlurCoin from "../../assets/images/svg/blue_blur_coin.svg";

const BenefitsAndFeatures = () => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const ComparisonData = [
    {
      id: 1,
      label: "Free Pricing",
      solesafe: true,
      discord: true,
      groups: true,
    },
    {
      id: 2,
      label: "Revenue Opportunities",
      solesafe: true,
      discord: true,
      groups: true,
    },
    {
      id: 3,
      label: "Create / Host Events",
      solesafe: true,
      discord: true,
      groups: true,
    },
    {
      id: 4,
      label: "Multi-Media Sharing",
      solesafe: true,
      discord: true,
      groups: true,
    },

    {
      id: 5,
      label: "Voice & Video Chat",
      solesafe: true,
      discord: true,
      groups: true,
    },
    {
      id: 6,
      label: "Community Insights",
      solesafe: true,
      discord: false,
      groups: true,
    },
    {
      id: 7,
      label: "Sneaker Data",
      solesafe: true,
      discord: false,
      groups: false,
    },
    {
      id: 8,
      label: "Collection Insights",
      solesafe: true,
      discord: false,
      groups: false,
    },
    {
      id: 9,
      label: "Community Contributions & Digital Wallet",
      solesafe: true,
      discord: false,
      groups: false,
    },
  ];

  return (
    <React.Fragment>
      {/* COMPARISON */}
      <div className="mt-16">
        <div className="z-50 mx-12">
          <p className="xl:w-2/3 2xl:w-3/5 mx-auto font-medium pb-6 font-druk text-white text-center text-[30px] leading-[32px] sm:text-[48px] sm:leading-[48px] col-start-2 col-span-4">
            A FRESH SPOT FOR YOUR SNEAKER COMMUNITY
            <p className="font-druk font-bold text-[20px] sm:text-[22px] leading-[24px] text-center text-white mx-auto my-4 pt-12 xl:pt-10 xl:mt-12">
              BENEFITS <br className="inline sm:hidden" />
              AND FEATURES
            </p>
          </p>
        </div>

        <div className="text-black grid grid-cols-12">
          <div className="col-span-12 mx-[1rem] sm:mx-[5rem] lg:mx-[10rem] xl:mx-[14rem] 2xl:mx-[20rem] bg-white rounded-[1rem]">
            {/* Coins parallax */}
            <div className="-rotate-45">
              <img
                src={yellowCoin}
                className="hidden sm:block absolute w-32 left-24 2xl:left-28 -mt-[5rem] xl:-mt-[10rem] 2xl:-mt-[15rem]"
                style={{ transform: `translateY(-${offsetY * 0.1}px)` }}
              />
            </div>
            <img
              src={blueBlurCoin}
              className="absolute w-32 -left-12 sm:left-2 xl:left-32 2xl:left-56 pt-[40rem] sm:pt-[32rem] xl:pt-[35rem] 2xl:pt-[40rem] -z-10"
              style={{ transform: `translateY(-${offsetY * 0.1}px)` }}
            />
            <img
              src={blueCoin}
              className="absolute w-32 -mt-[52rem] sm:-mt-[42rem] right-[-0rem] sm:-right-[-2rem] xl:-right-[-10rem] 2xl:-right-[-14rem] sm:pt-[14rem] 2xl:pt-[25rem] -z-10 sm:z-0"
              style={{ transform: `translateY(${offsetY * 0.2}px)` }}
            />

            {/* Table data  */}
            <div className="grid grid-cols-10 bg-yellow rounded-t-[1rem]">
              {/* FEATURES   */}
              <div className="col-span-3 sm:col-span-3">
                <h1 className="flex justify-start items-center rounded-tl-[1rem] font-druk font-medium text-[12px] leading-[8.3px] sm:text-[20px] 2xl:text-[22px] h-24 pl-4 sm:pl-8 bg-yellow">
                  {" "}
                  FEATURES
                </h1>
              </div>
              {/* Solesafe  */}
              <div className="col-span-2">
                <h1 className="flex justify-center items-center h-24 bg-yellow">
                  {" "}
                  <img src={solesafeBlackLogo} className="" />
                </h1>
              </div>
              {/* M DISCORD*/}
              <div className="col-span-3 pr-2 sm:pr-0">
                <h1 className="flex justify-center items-center w-auto font-druk font-medium sm:font-bold text-[8px] sm:text-[15px] pl-4 h-24 bg-yellow">
                  {" "}
                  <img src={communityDiscord} className="" />
                </h1>
              </div>
              {/* F GROUPS  */}
              <div className="col-span-2 sm:col-span-2 pr-2">
                <h1 className="w-auto sm:pr-12 flex justify-center items-center rounded-tr-[1rem]  font-druk font-medium sm:font-bold text-[8px] sm:text-[15px]  h-24 bg-yellow">
                  {" "}
                  <img src={communityFgroups} className="" />
                </h1>
              </div>
            </div>
            <div className="col-span-12 border-t-2 border-black z-50">
              {ComparisonData.map((item, index) => {
                return (
                  <div
                    className="grid grid-cols-12 border-b-2 border-black py-4"
                    key={index}
                  >
                    <div className="col-span-4 pl-4 sm:pl-8">
                      <ul>
                        <li className="py-2 font-inter text-[11px] lg:text-[16px] leading-[16px] font-normal">
                          {" "}
                          {item.label}
                        </li>
                      </ul>
                    </div>

                    <div className="col-span-2 place-self-center 2xl:mr-4">
                      {
                        <img
                          className="w-5"
                          src={item.solesafe ? blackChecklist : blurCrosslist}
                          alt="check/cross mark"
                        />
                      }
                    </div>

                    <div className="col-span-4  place-self-center">
                      {
                        <img
                          className="w-5"
                          src={item.discord ? blackChecklist : blurCrosslist}
                          alt="check/cross mark"
                        />
                      }
                    </div>

                    <div className="col-span-1 2xl:ml-5 place-self-center">
                      {
                        <img
                          className="w-5"
                          src={item.groups ? blackChecklist : blurCrosslist}
                          alt="check/cross mark"
                        />
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-11 sm:mt-14 sm:flex justify-center items-center">
        <button
          className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] px-4 py-3"
          type="submit"
          onClick={() => {
            navigate("/contact", { state: "partner" });
          }}
        >
          REQUEST A DEMO
          <div className="cursor-pointer rotate-90 ml-6">
            <img src={scrollArrow} className="scroll-smooth" />
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default BenefitsAndFeatures;
