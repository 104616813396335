import React from "react";
import { Link, useNavigate } from "react-router-dom";
import scrollArrow from "../../../assets/images/svg/scroll-arrow.svg";
import StandardPremiumBox from "./StandardPremiumBox";

const ReadyForSafedrop = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="py-40 mt-14 sm:mt-32 2xl:mt-40">
        <div className="text-center ">
          <h1 className="font-druk font-medium text-[30px] sm:text-[48px] leading-[32px] sm:leading-[48px] w-[21rem] sm:w-[56rem] mx-auto">
            READY FOR SAFEDROP™ TO TRANSFORM YOUR SNEAKER RAFFLES?
          </h1>
          <h2 className="font-inter font-normal text-[16px] leading-[23px] w-[19rem] sm:w-[28rem] mx-auto my-6">
            Explore our subscription plans and choose the <b>SafeDrop™</b> plan
            that best fits your needs as a retailer:
          </h2>
        </div>
        {/* box */}
        <div className="flex justify-center my-10">
          <StandardPremiumBox />
        </div>
        {/* button */}
        <div className="grid sm:flex justify-center items-center sm:gap-7 my-10">
          <div className="bg-white sm:my-4 text-black rounded-full flex justify-center items-center sm:justify-start mx-auto sm:mx-0 w-[23rem] sm:w-[25%] xl:w-1/6 2xl:w-60">
            <div className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto">
              <a
                 href="https://apps.shopify.com/safedrop" 
                className="flex justify-center items-center lg:w-full py-3"
              >
                <h1>INSTALL APP</h1>
                <img
                  src={scrollArrow}
                  alt=''
                  className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                />
              </a>
            </div>
          </div>
          <div className="bg-white sm:my-4 text-black rounded-full flex justify-center items-center sm:justify-start mx-auto sm:mx-0 mt-2 w-[23rem] sm:w-[28%] xl:w-1/6 2xl:w-60">
            <button
              className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto"
              type="submit"
              onClick={() => {
                navigate("/contact", { state: "partner" });
              }}
            >
              <div className="flex justify-center items-center lg:w-full py-3">
                <h1>SCHEDULE DEMO</h1>
                <img
                  src={scrollArrow}
                  alt=''
                  className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReadyForSafedrop;
