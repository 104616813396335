//external imports
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//internal imports
import insureShoe from "../../assets/images/png/insure-shoe.png";
import insureWhatShoe from "../../assets/images/png/insure_what_shoe.png";
import AppStores from "../AppStores";

const Insure = (props) => {
  const { pathname } = useLocation();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="relative grid">
      <div className="place-self-center z-[-100000000]">
        <img
          src={insureWhatShoe}
          className="absolute -bottom-[460rem] sm:-bottom-[368rem] lg:-bottom-[314rem] 2xl:-bottom-[324rem] right-[1rem] sm:right-[22rem] lg:right-[30rem] 2xl:right-[44rem] h-96 w-96"
          style={{ transform: `translateY(-${offsetY * 0.43}px)` }}
        />
      </div>
      <div className=" text-center -top-32 md:-top-[15rem] xl:-top-[10rem] xl:mx-48 2xl:mx-[30rem]">
        <span className="grid md:inline-block font-druk font-medium sm:font-bold text-[48px] sm:text-[85px] leading-[55px] sm:leading-[90px] mytext--stroke">
          INSURE
        </span>
        <span className=" font-druk font-medium sm:font-bold text-[48px] sm:text-[85px] leading-[55px] sm:leading-[90px] sm:pl-4">
          WHAT YOU LOVE
        </span>
        <div className="sm:col-start-3 sm:col-span-12 mt-16 mx-4 text-[16px] leading-[24px]">
          Sneakers might be an "alternative asset" but they shouldn't go without
          protection.
        </div>
        <div className="font-bold mt-10">​ Welcome to SoleSafe™!</div>
        {pathname === "/sneakerheads" && (
          <div className="flex justify-center pt-4">
            <AppStores />
          </div>
        )}
      </div>
    </div>
  );
};

export default Insure;
