import React from "react";

const Privacy = () => {
  return (
    <div>
      <div className="font-druk font-medium sm:font-bold text-[48px] sm:text-[55px] lg:text-[75px] leading-[55px] sm:leading-[90px] sm:flex sm:gap-2 pb-12 sm:-mt-8  ">
        <p className="sm:pb-20">
          PRIVACY <span className="text--stroke">POLICY</span>
        </p>
      </div>
      <p className="font-inter  font-bold text-terms text-[16px] leading-[26px] pb-6">
        LAST MODIFIED: JANAUARY 1, 2023
      </p>
      <div className="border border-b-2 border-black  2xl:mr-32 "></div>
      {/* Numbering */}
      <div className="font-inter font-normal text-[16px] leading-[26px] pt-10 space-y-10  2xl:mr-32">
        {/* Heading */}
        <div>
          <p className="font-bold pb-10"> Privacy Notice</p>
          <p>
            This privacy notice explains what information we at SoleSafe, Inc
            ("SoleSafe” or "we") collect about you when you use this website or
            the SoleSafe portal accessible from it, how we use this information,
            with whom we share it, and the choices you have in connection with
            this. If you have questions about anything in this privacy notice,
            please email us at hello@solesafe.com.
          </p>
        </div>
        {/* Title No. 1 */}
        <div>
          <p className="font-bold pb-10">1. Information we collect</p>
          {/* content  */}
          <div className=" space-y-8">
            <p>
              Contact us: You do not need to provide us with any information to
              browse our website. If you contact us through the website (for
              example, to request information or make suggestions) or when you
              request technical support for assistance with the website or with
              the SoleSafe service or portal, we collect personal information,
              which is information that identifies you as an individual or
              relates to you as an identifiable individual. This includes your
              email address, phone number, the state you reside in, and any
              information provided by you. Please note that information you
              provide to us through an inquiry or any feedback, suggestions
              etc., without the parts that identify you, will be treated as
              non-confidential and non-proprietary and we may use it and share
              it with third parties.
            </p>
            <p>
              Use our Portal: If you use the portal to access and manage your
              SoleSafe account, we will collect your username, account number,
              and/or password. When you use our website, we use cookies and
              other technologies to collect certain information automatically
              about:
            </p>
            <ul className="pl-8 list-disc">
              <li>
                Usage Information: which of the pages on our website you access,
                the frequency of access, how much time you spend on each page,
                what you click on while on our website, when you accessed the
                website.
              </li>
              <li>
                Location Information: information about your estimated location
                as may be determined from the IP Address.
              </li>

              <li>
                Device Information: information about the device you are using,
                such as hardware model, operating system, application version
                number, browser, and IP addresses.
              </li>
            </ul>
            <p className="font-bold">What are cookies?</p>
            <p>
              ​Cookies are small pieces of text sent to your web browser by a
              website you visit. A cookie file is stored in your web browser.
              Cookies can be "persistent" or "session" cookies. Persistent
              cookies remain on your personal computer or mobile device when you
              go offline, while session cookies are deleted as soon as you close
              your web browser. We use first-party cookies, which are cookies
              that we place on your device ourselves, and third-party cookies,
              which are cookies that we allow third parties to place on your
              device.
            </p>

            <p>How we use cookies?</p>
            <p>
              When you use and access our website, we place or allow our
              third-party analytics provider, Google Analytics, to place a
              number of cookie files in your web browser. We use both session
              and persistent cookies on the service and we use the following
              types of cookies. To learn how you can control information
              collected about you using cookies and other technologies, go here.
            </p>
            <ul className="pl-8 list-disc">
              <li>
                Essential cookies. We use essential cookies to authenticate
                users and prevent fraudulent use of user accounts.
              </li>
              <li>
                Preferences cookies. If you chose this option while using our
                website, we will use preferences cookies to allow you to save
                your username or password to log into the portal.
              </li>
              <li>
                Analytics cookies. We allow Google Analytics to place cookies to
                track how the website is used so that we can make improvements.
                Google Analytics may also set cookies on your browser or mobile
                device, or read cookies that are already there. Google Analytics
                may also receive information about you from apps you have
                downloaded, that partner with Google. We do not combine the
                information collected through the use of Google Analytics with
                personally identifiable information. Go here to learn about how
                to control information used by Google Analytics.
              </li>
            </ul>
          </div>
        </div>
        {/* Title No. 2 */}
        <div>
          <p className="font-bold">2. How we use your information</p>
          {/* content  */}
          <div className="mt-10 space-y-8">
            <p>​ We use the information we collect about you to:</p>
            <ul className="pl-8 list-disc">
              <li>
                Provide the Service: administer your account and SoleSafe
                services;
              </li>
              <li>
                Answer your queries: contact you or respond to an inquiry from
                you in connection with your use of the website, service or the
                portal.
              </li>
              <li>
                Communicate with you: communicate with you about the website, or
                your account, to send you account updates or other
                communications regarding your account (for example: or example
                sending you a link to refresh your password); inform you of any
                changes to our website or portal, and to provide you support or
                other services you request.
              </li>
              <li>
                Tell you about our new products: send you notifications about
                new features or information available on our services or that we
                feel might be of interest to you. See Section 5 below to learn
                how you can opt out of receiving these communications at any
                time.
              </li>
              <li>
                Maintain the website or service: help diagnose problems on the
                website or portal, enhance its security, administer it, prevent
                or detect fraud.
              </li>
              <li>
                Improve and conduct benchmarking: optimize our website and
                portal to our users' preferences. We also aggregate personal
                information for the purpose of research and benchmarking. For
                example, gauge the number of support tickets opened by clients.
              </li>
              <li>
                Defend our rights and those of others, to efficiently maintain
                our business and to comply with the law: as described in Section
                3 below. ​
              </li>
            </ul>
            <p>
              Our legal basis for processing Personal Data and other information
              is your consent, and by voluntarily providing us with Personal
              Data and other information, you are consenting to our use of it in
              accordance with this Privacy Policy. If you provide Personal Data
              and other information to us, you acknowledge and agree that such
              Personal Data and other information may be transferred and stored
              from your current location to the offices and servers of SoleSafe.
            </p>{" "}
          </div>
        </div>
        {/* Title No. 3 */}
        <div>
          <p className="font-bold"> 3. How we share your information ​</p>
          {/* content  */}
          <div className="mt-10 space-y-8">
            <p>
              We share your information with certain third parties, including
              our service providers that facilitate the provision of our
              service, and as necessary to efficiently run our business and to
              comply with our legal and other obligations.
            </p>{" "}
            <ul className="pl-8 list-disc">
              <li>
                Service Providers. We share information with service providers
                that help us operate the website and administer the portal.
              </li>
              <li>We use an internal technical support team.</li>
              <li>
                We use Wix.com, Inc as a provider of hosting services for our
                website. A link to the Wix.com, Inc privacy notice is
                https://www.wix.com/about/privacy.
              </li>
              <li>
                We use Google Analytics to conduct data analytics on usage of
                our services. A link to the Google privacy notice is here.
              </li>
              <li>
                We use communication tools to share links with social networks.
                If you click on these features, the company providing them may
                collect your IP address, which page you are visiting on our site
                and may place cookies when you are connected into their
                services. Your interactions with these features are governed by
                the privacy statement of the company providing it. For more
                information on the cookies used by these websites, you can refer
                to their specific privacy statements listed below:
              </li>
              <li>Facebook: https://www.facebook.com/about/privacy/</li>
              <li>LinkedIn: https://www.linkedin.com/legal/privacy-policy</li>
              <li>Instagram: https://help.instagram.com/155833707900388/</li>
              <li>
                Corporate Family. We share information within our corporate
                family, such as with subsidiaries, joint ventures, or
                affiliates, in order to efficiently carry out our business and
                to the extent permitted by law.
              </li>
              <li>
                Corporate Structure. In the event that we enter into, or intend
                to enter into, a transaction that alters the structure of our
                business, such as a reorganization, merger, acquisition, sale,
                joint venture, assignment, consolidation, transfer, change of
                control, or other disposition of all or any portion of our
                business, assets or stock, we would share personal information
                with third parties, including the buyer or target (and their
                agents and advisors) for the purpose of facilitating and
                completing the transaction. If we undergo bankruptcy or
                liquidation, we would share personal information with third
                parties in the course of such proceedings.
              </li>
              <li>
                To Prevent Harm. We will share information if we believe it is
                necessary to detect, investigate, prevent, or take action
                against illegal activities, fraud, or situations involving
                potential threats to the rights, property, or personal safety of
                any person.
              </li>
              <li>
                Legal Purposes. We will share information where we are legally
                required to do so, such as in response to court orders, law
                enforcement or legal process, including for national security
                purposes; to establish, protect, or exercise our legal rights,
                as required to enforce our terms of service or other contracts;
                to defend against legal claims or demands; or to comply with the
                requirements of any applicable law.
              </li>
            </ul>
            <p>
              With Your Consent. Apart from the reasons identified above, we may
              request your permission to share your personal information for a
              specific purpose. We will notify you and request consent before
              you provide the personal information or before the personal
              information you have already provided is shared for such purpose.
            </p>
          </div>
        </div>
        {/* Title No. 4 */}
        <p className="font-bold">
          4. How we store and protect your information ​
        </p>
        {/* content  */}
        <p>
          We implement and maintain reasonable security procedures and practices
          appropriate to the nature of the information we store, in order to
          protect it from unauthorized access, destruction, use, modification,
          or disclosure. For example, all communications on the website or
          portal are done using HTTPS communications protocol. Also, we have
          implemented various policies including, encryption, access, and
          authentication to guard against unauthorized access to personal data
          in our systems.
        </p>
        <p>
          Please be aware that no method of transmission over the internet, or
          method of electronic storage is 100% secure and we are unable to
          guarantee the absolute security of the personal information we have
          collected from you.
        </p>
        <p>
          We retain your information for as long as necessary to serve the
          purpose for which it was provided, and as necessary for specific
          purposes to the extent that we are obliged to do so in accordance with
          applicable laws and regulations and/or as necessary to protect our
          legal rights, fulfill our contractual obligations or for certain
          business requirements. Our retention of information in your user
          account is governed by our agreement with our clients.
        </p>{" "}
        {/* Title No. 5 */}
        <p className="font-bold">
          5. Your choices with respect to your information ​
        </p>
        {/* content  */}
        <p>
          Access or update information: If you need to access, update, or delete
          personal information that we may have, you can do so by logging into
          your account or sending an email to hello@solesafe.com.
        </p>
        <p>
          Delete your account: Customers and other visitors to our Website have
          a right to access Personal Data about themselves and to amend,
          correct, or delete their personal Data. Such information will only be
          provided upon receipt of a verifiable request and upon receipt of a
          request we will provide the requested information promptly. We are not
          required to provide information more than twice in a 12-month period.
        </p>
        <p>
          Please note that while any amendments, corrections, or deletions will
          be reflected in active user databases (as updated within a reasonable
          period of time), we may retain all Personal Data for backups,
          archiving, prevention of fraud and abuse, analytics, and satisfaction
          of other legal obligations we reasonably believe applicable.
        </p>
        <p>
          You authorize us to use and disclose any new or modified Personal Data
          that you provide in accordance with this Privacy Policy, and you agree
          that we are under no obligation to delete or modify Personal Data that
          you have previously chosen to provide us as to which you have not
          instructed us to take such action.
        </p>
        <p>
          We may retain your Personal Data to comply with laws, prevent fraud,
          resolve disputes, troubleshoot problems, assist with any
          investigations, enforce our contracts, and take other actions
          otherwise permitted by law.
        </p>
        <p>
          If you send us an email with questions or comments, we may use your
          Personal Data to respond to your questions or comments, and we may
          save your questions or comments for future reference. For security
          reasons, we do not recommend that you send non-public personal
          information, such as passwords, social security numbers, or bank
          account information, to us by email.
        </p>
        <p>
          You may request access, delete or update your personal information by
          sending a verifiable request by emailing us at hello@solesafe.com..
        </p>
        <p>
          Please note that we may need to retain certain information to complete
          transactions, or when required by law.
        </p>
        <p>
          Promotional emails: You can opt out of receiving promotional e-mails
          from us by clicking the “unsubscribe” link provided in each e-mail or
          by contacting us at hello@solesafe.com. We will continue to send you
          notifications in connection with your SoleSafe account.
        </p>
        <p>
          Analytics: You can control the information provided to Google and opt
          out of certain ads provided by Google by using any of the methods set
          forth here or using the Google Analytics opt out browser add-on here.
        </p>
        <p>
          Cookies: Persistent cookies can be removed by following your web
          browser’s directions. A session cookie is temporary and disappears
          after you close your browser. You can reset your web browser to refuse
          all cookies or to indicate when a cookie is being sent. However, some
          features of the website may not function properly if the ability to
          accept cookies is disabled. To find out how to see what cookies have
          been set on your computer or device, and how to reject and delete the
          cookies, please visit: https://www.aboutcookies.org/. If you choose to
          opt out, we will place an "opt-out cookie" on your computer. The
          "opt-out cookie" is browser specific and device specific and only
          lasts until cookies are cleared from your browser or device. The
          opt-out cookie will not work for some cookies that are important to
          how our websites and mobile apps work ("essential cookies"). If the
          cookie is removed or deleted, if you upgrade your browser or if you
          visit us from a different computer, you will need to return and update
          your preferences. ​
        </p>
        {/* Title No. 6 */}
        <p className="font-bold">6. Do Not Track Disclosure</p>
        {/* content  */}
        <p>
          We do not support Do Not Track ("DNT"). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked.
        </p>{" "}
        <p>
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser. For further details, visit
          https://www.eff.org/issues/do-not-track.
        </p>
        {/* Title No. 7 */}
        <p className="font-bold">7. Links to other sites</p>
        {/* content  */}
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third-party's site. We strongly advise you to review the privacy
          notice of every site you visit.
        </p>
        <p>
          We have no control over, and assume no responsibility for the content,
          privacy notices or practices of any third-party sites or services.
        </p>
        {/* Title No. 8 */}
        <p className="font-bold">8. Children's privacy</p>
        {/* content  */}
        <p>
          This website and our services are not intended for individuals under
          the age of eighteen (18) years. Accordingly, we do not knowingly
          collect or share information from children.
        </p>
        {/* Title No. 9 */}
        <p className="font-bold">9. Contact us</p>
        {/* content  */}
        <p>
          If you have any questions about this privacy notice, the website or
          the portal, please contact us at: EMAIL:hello@solesafe.co ​
        </p>
        {/* Title No. 10 */}
        <p className="font-bold">10. Changes to our Privacy Notice</p>
        {/* content  */}
        <p>
          We may change this Privacy Notice from time to time. We will post the
          changes to this page, and will indicate the date they go into effect.
          We encourage you to review our Privacy Notice to stay informed. If we
          make changes that materially affect your privacy rights, we will
          notify you of the changes by posting a prominent notice on our website
          or using other methods that we select, such as sending you an email.
        </p>
        <p className="pb-10 sm:pb-0">
          This Privacy Policy was last updated on November 07, 2021.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
