import React, { useState, useEffect } from "react";
import axios from "axios";
import supportMobile from "../../assets/images/png/support-mob.png";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

const GotQuestion = (props) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "Query raised from web",
    body: "",
    contact_via_phone: false,
    contact_via_email: true,
  });

  const [partnerTypeCheckbox, setPartnerTypeCheckbox] = useState([]);

  const [partnerData, setPartnerData] = useState({
    company_name: "",
    name: "",
    email: "",
    phone: "",
    role: "",
    partner_type: partnerTypeCheckbox,
    website: "https://",
    retail_platform: "",
    managed_product_launches: "",
    pos_system: "",
    social_group: "",
    people_in_social_group: "",
    is_offer_insurance_product: false,
    subject: "Your SoleSafe Partner Message Submission",
    body: "",
  });

  const [body, setBody] = useState(data);
  const [partnerBody, setPartnerBody] = useState(partnerData);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [partnerNameError, setPartnerNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [partnerEmailError, setPartnerEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [partnerTypeError, setPartnerTypeError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [partnerBodyError, setPartnerBodyError] = useState("");
  const [partnerPlatformStoreError, setPartnerPlatformStoreError] =
    useState("");
  const [partnerManageRaffleError, setPartnerManageRaffleError] = useState("");
  const [partnerPOSSystemError, setPartnerPOSSystemError] = useState("");
  const [partnerIGHandleError, setPartnerIGHandleError] = useState("");
  const [partnerAmountOfPeopleError, setPartnerAmountOfPeopleError] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [showOnlineRetailerFields, setShowOnlineRetailerFields] =
    useState(false);
  const [showBrickAndMortarFields, setShowBrickAndMortarFields] =
    useState(false);
  const [showCommunityFields, setShowCommunityFields] = useState(false);
  const [showInfluencerFields, setShowInfluencerFields] = useState(false);

  const [emailResponse, setEmailResponse] = useState({
    message: "",
    success: true,
  });
  const [partnerEmailResponse, setPartnerEmailResponse] = useState({
    message: "",
    success: true,
  });

  // useEffect(() => {
  //   setPartnerData((prevData) => ({
  //     ...prevData,
  //     partner_type: partnerTypeCheckbox,
  //   }));
  // }, [partnerTypeCheckbox]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetPartnerTypeCheckboxFields = () => {
    setShowOnlineRetailerFields(false);
    setShowBrickAndMortarFields(false);
    setShowCommunityFields(false);
    setShowInfluencerFields(false);
  };

  const changeUserHandler = (e) => {
    setEmailResponse({
      message: "",
      success: true,
    });
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const changePartnerHandler = (e) => {
    const { name, value, checked } = e.target;

    setPartnerEmailResponse({
      message: "",
      success: true,
    });

    let updatedTypeCheckbox = [...partnerTypeCheckbox];

    if (
      updatedTypeCheckbox.includes("Insurance Broker") ||
      updatedTypeCheckbox.includes("Secondary Marketplace") ||
      updatedTypeCheckbox.includes("Auction House")
    ) {
      resetPartnerDataErrors();
    }
    if (e.target.type === "checkbox") {
      if (checked) {
        updatedTypeCheckbox.push(value);
      } else {
        updatedTypeCheckbox = updatedTypeCheckbox.filter(
          (val) => val !== value
        );
      }
    }

    const newData = { ...partnerData };

    if (name === "partner_type") {
      newData[name] = updatedTypeCheckbox;
    } else {
      newData[name] = value;
    }

    if (name === "is_offer_insurance_product") {
      newData[name] = checked;
    }

    setPartnerTypeCheckbox(updatedTypeCheckbox);
    setPartnerData(newData);

    // console.log("partnerTypeCheckbox", updatedTypeCheckbox);
    // console.log("partnerDataValue", newData);
  };

  const resetPartnerRadioButton = ( value, optiontype1, optiontype2, name ) => {
    const newData = {...partnerData};
    // this will reset the corresponding input fields (IG Handle & Amount of people) for partner type Community and Ambassador -
    if(optiontype1==='people_in_social_group' && optiontype2 === 'social_group'){
      if((!value && !isPartnerChecked('Influencer') && name === 'Community') || (!value &&  name ==='Influencer' && !isPartnerChecked('Community'))){
        newData[optiontype1] = '';
        newData[optiontype2] = '';
        setPartnerData(newData);
      }
    }
    // this will reset the corresponding radio button for partner type - Online retailer and Brick & Mortar
    else if(!value){
      newData[optiontype1] = '';
      if(optiontype2) {
        newData[optiontype2] = ''
      }
      setPartnerData(newData);
    }
  }

  const validate = (data) => {
    let hasErrors = false;

    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex =
      /^(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;

    if (!data.name) {
      setNameError("Full Name Field should not be empty");
      hasErrors = true;
    }

    if (!data.email) {
      setEmailError("Email should not be empty");
      hasErrors = true;
    } else if (!regEx.test(data.email)) {
      setEmailError("Email is invalid");
      hasErrors = true;
    }

    // As discussed phone number is currently not mandatory. Hence error check in commented for now.
    // if (!data.phone) {
    //   setPhoneError("Your Phone No. should not be empty");
    // } else if (!phoneRegex.test(data.phone)) {
    //   setPhoneError("Phone no is invalid");
    // }

    if (data.body === "") {
      setBodyError("Your Message should not be empty");
      hasErrors = true;
    }

    return hasErrors;
  };

  const isPartnerChecked = (partner) => {
    return partnerTypeCheckbox.includes(partner)
  }

  const partnerValidate = (partnerData) => {
    let hasPartnerErrors = false;
    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!partnerData.company_name) {
      setCompanyNameError("Company Name should not be empty");
      hasPartnerErrors = true;
    }
    if (!partnerData.name) {
      setPartnerNameError("Name should not be empty");
      hasPartnerErrors = true;
    } else if (!/^[a-zA-Z ]+$/.test(partnerData.name)) {
      setPartnerNameError("Full Name should not have numeric value");
      hasPartnerErrors = true;
    } else {
      setPartnerNameError("");
    }
    if (!partnerData.email) {
      setPartnerEmailError("Email should not be empty");
      hasPartnerErrors = true;
    } else if (!regEx.test(partnerData.email)) {
      setPartnerEmailError("Email is invalid");
      hasPartnerErrors = true;
    }
    if (!partnerData.role) {
      setRoleError("Role/Position should not be empty");
      hasPartnerErrors = true;
    } else if (!/^[a-zA-Z ]+$/.test(partnerData.role)) {
      setRoleError("Role/Position should not have numeric value");
      hasPartnerErrors = true;
    } else {
      setRoleError("");
    }

    if (!partnerData.website) {
      setWebsiteError("Website should not be empty");
      hasPartnerErrors = true;
    } else if (
      !/^https?:\/\/[^\s/$.?#].[^\s]*$/.test(partnerData.website.toLowerCase())
    ) {
      setWebsiteError(
        '"Website" must be a valid URL. eg: https://www.google.com'
      );
      hasPartnerErrors = true;
    }
    // if (!partnerData.partner_type.partnerTypeCheckbox) {
    //   setPartnerTypeError("Partner Type should not be empty");
    //   hasPartnerErrors = true;
    // }
    // if (!partnerData.retail_platform) {
    //   setPartnerPlatformStoreError("Please select platform");
    //   hasPartnerErrors = true;
    // }
    // if (!partnerData.partnerManageRaffleError) {
    //   setPartnerManageRaffleError("Please select product raffles/launches");
    //   hasPartnerErrors = true;
    // }
    // if (!partnerData.partnerPOSSystemError) {
    //   setPartnerPOSSystemError("Please select POS system");
    //   hasPartnerErrors = true;
    // }
    // if (!partnerData.partnerIGHandleError) {
    //   setPartnerIGHandleError(
    //     "Please enter IG Handle, Website or Facebook Group"
    //   );
    //   hasPartnerErrors = true;
    // }
    // if (!partnerData.partnerAmountOfPeopleError) {
    //   setPartnerAmountOfPeopleError(
    //     "Please enter amount of people in community"
    //   );
    //   hasPartnerErrors = true;
    // }
    if (partnerData.partner_type.includes("Online Retailer")) {
      if (!partnerData.retail_platform) {
        setPartnerPlatformStoreError("Please select platform");
        hasPartnerErrors = true;
      }

      if (
        !partnerData.managed_product_launches &&
        !partnerData.retail_platform.includes("Brick & Mortar Store") &&
        !partnerData.retail_platform.includes("Online Retailer")
      ) {
        setPartnerManageRaffleError("Please select product raffles/launches");
        hasPartnerErrors = true;
      }
    }

    if (partnerData.partner_type.includes("Brick & Mortar Store")) {
      if (!partnerData.pos_system) {
        setPartnerPOSSystemError("Please select POS system");
        hasPartnerErrors = true;
      }
    }

    if (
      partnerData.partner_type.includes("Community") ||
      partnerData.partner_type.includes("Influencer")
    ) {
      if (!partnerData.social_group) {
        setPartnerIGHandleError(
          "Please enter IG Handle, Website or Facebook Group"
        );
        hasPartnerErrors = true;
      }
      if (!partnerData.people_in_social_group) {
        setPartnerAmountOfPeopleError(
          "Please enter amount of people in community"
        );
        hasPartnerErrors = true;
      }
    }

    if (partnerData.body === "") {
      setPartnerBodyError("Your Message should not be empty");
      hasPartnerErrors = true;
    }
    return hasPartnerErrors;
  };

  const resetUserDataInputs = () => {
    setData({
      name: "",
      email: "",
      phone: "",
      subject: "Query raised from web",
      body: "",
      contact_via_phone: false,
      contact_via_email: true,
    });
  };

  const resetPartnerDataInputs = () => {
    setPartnerTypeCheckbox([]);
    setPartnerData({
      company_name: "",
      name: "",
      email: "",
      phone: "",
      role: "",
      partner_type: [],
      // partner_type: partnerTypeCheckbox,
      website: "https://",
      retail_platform: "",
      managed_product_launches: "",
      pos_system: "",
      social_group: "",
      people_in_social_group: "",
      is_offer_insurance_product: false,
      subject: "Your SoleSafe Partner Message Submission",
      body: "",
    });
  };
  const resetUserErrors = () => {
    setNameError("");
    setEmailError("");
    setBodyError("");
  };
  const resetPartnerDataErrors = () => {
    setCompanyNameError("");
    setPartnerNameError("");
    setPartnerEmailError("");
    setRoleError("");
    setWebsiteError("");
    setPartnerTypeError("");
    setPartnerBodyError("");
    setPartnerPlatformStoreError("");
    setPartnerManageRaffleError("");
    setPartnerPOSSystemError("");
    setPartnerIGHandleError("");
    setPartnerAmountOfPeopleError("");
  };

  const submitUserHandler = async (e) => {
    e.preventDefault();

    const hasErrors = validate(data);
    if (!hasErrors) {
      setLoading(true);
      setBody(`Subject: ${data?.subject}
    
    <img src="Body: ${data?.body}" />
      
      
      Hey ${data?.name},
     
          
      Thank you for your submission. We hope to resolve any questions, comments, or concerns that you may have. Someone from our team will review your submission and get back to you at the earliest convenience.
      
      In the meantime, don't forget to check out our website or download our app if you haven't done so already.
      
          
      Thanks for reaching out and you'll hear from us soon!
     
      SoleSafe&trade;
     
      <img src='https://solesafe-public-assets.s3.amazonaws.com/SoleSafe_icon.png' style="display:block; width:120px;"/>
      `);
      await axios
        .post(
          "https://ethm10mds9.execute-api.us-east-1.amazonaws.com/support",
          {
            name: data.name,
            email: data.email,
            phone: data.phone,
            subject: data.subject,
            body: data.body,
            contact_via_phone: data.contact_via_phone,
            contact_via_email: data.contact_via_email,
          }
        )

        .then((response) => {
          if (response.status === 200) {
            setEmailResponse({
              message: "Message Sent Successfully",
              success: true,
            });
            resetUserDataInputs();
            setTimeout(() => {
              setEmailResponse({
                message: "",
                success: true,
              });
            }, 3000);
            setLoading(false);
          }
        })
        .catch((error) => {
          setEmailResponse({
            message: "Something went wrong. Please try later",
            success: false,
          });
          setTimeout(() => {
            setEmailResponse({
              message: "",
              success: true,
            });
          }, 3000);
          setLoading(false);
        });
    }
  };

  const submitPartnerHandler = async (e) => {
    e.preventDefault();
    // const hasErrors = validate(data);
    const hasPartnerErrors = partnerValidate(partnerData);
    if (!hasPartnerErrors) {
      setLoading(true);

      setPartnerBody(`
    
      Subject: ${partnerData?.subject}
    
      Body: ${partnerData?.body}
      
      
      Hey ${partnerData?.name},
     
          
      Thank you for your submission. We look forward to reviewing your partnership inquiry. One of our team members will review your submission and get back to you in 3-5 business days. 

 

      In the meantime, don’t forget to check out our website or download our app on iOS or Android if you haven’t done so already. 
      
       
      
      Thanks for reaching out and you’ll hear from us soon! 
     
      <img src='https://solesafe-public-assets.s3.amazonaws.com/SoleSafe_icon.png' style="display:block; width:120px;"/>
      `);
      await axios
        .post(
          "https://ethm10mds9.execute-api.us-east-1.amazonaws.com/partner_support",
          {
            company_name: partnerData.company_name,
            name: partnerData.name,
            email: partnerData.email,
            phone: partnerData.phone,
            role: partnerData.role,
            partner_type: partnerData.partner_type,
            website: partnerData.website,
            retail_platform: partnerData.retail_platform,
            managed_product_launches: partnerData.managed_product_launches,
            pos_system: partnerData.pos_system,
            social_group: partnerData.social_group,
            people_in_social_group: partnerData.people_in_social_group,
            subject: partnerData.subject,
            body: partnerData.body,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setPartnerEmailResponse({
              message: "Message Sent Successfully",
              success: true,
            });
            resetPartnerDataInputs();
            setTimeout(() => {
              setPartnerEmailResponse({
                message: "",
                success: true,
              });
            }, 3000);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("err", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message ===
              "Invalid name. Please enter valid name."
          ) {
            setPartnerEmailResponse({
              message: "Full Name should not have numeric value",
              success: false,
            });
          } else if (
            error.response.data.message ===
            '"partner_type" must contain at least 1 items'
          ) {
            setPartnerTypeError("Partner Type should not be empty");
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message ===
              "Invalid Role. Please enter valid role."
          ) {
            setPartnerEmailResponse({
              message: "Role/Position should not have numeric value",
              success: false,
            });
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message === '"website" must be a valid uri'
          ) {
            setPartnerEmailResponse({
              message:
                '"website" must be a valid url. eg: https://www.google.com/',
              success: false,
            });
            setWebsiteError(
              '"website" must be a valid url. eg: https://www.google.com/'
            );
          } else {
            setPartnerEmailResponse({
              message: "Something went wrong. Please try later",
              success: false,
            });
          }
          setTimeout(() => {
            setPartnerEmailResponse({
              message: "",
              success: false,
            });
          }, 3000);
          setLoading(false);
        });
    }
  };
  return (
    <React.Fragment>
      <div className=" flex flex-col-reverse xl:grid xl:grid-cols-9 gap-8 xl:gap-0">
        <div className="xl:col-span-6 2xl:col-span-5  xl:ml-12">
          <div className="mb-12 flex justify-center xl:justify-start">
            <button
              className={`${
                // showUserSupport === true
                props.formType === "partner"
                  ? "bg-yellow text-black"
                  : "bg-none text-white"
              }
            sm:w-44 sm:my-0 col-span-8 sm:col-span-3  border-[1px] border-white rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4`}
              type="submit"
              onClick={() => {
                props.setFormType("partner");
                resetPartnerDataErrors();
                resetPartnerTypeCheckboxFields();
                resetPartnerDataInputs();
              }}
            >
              I want to partner
            </button>
            <button
              className={`${
                // showUserSupport === false
                props.formType === "user"
                  ? "bg-yellow text-black"
                  : "bg-none text-white"
              }
            ml-2 sm:w-44 sm:my-0 col-span-8 sm:col-span-3 border-[1px] border-white rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4`}
              type="submit"
              onClick={() => {
                props.setFormType("user");
                resetUserErrors();
                resetUserDataInputs();
              }}
            >
              I'm a collector
            </button>
          </div>
          {/* {showUserSupport ? ( */}
          <>
            <h2 className="text-center  xl:text-left font-druk font-medium sm:font-bold text-[47px] sm:text-[75px] xl:text-[70px] leading-[55px] sm:leading-[90px] sm:px-20 xl:px-0">
              {props.formType === "user"
                ? "GOT A QUESTION? SEND US A "
                : " GET STARTED WITH "}
              <span className="text-center xl:text-left border-b-[10px]  border-blue">
                {props.formType === "user" ? "MESSAGE." : "SOLESAFE."}
              </span>
            </h2>
            {/* {props.formType === "partner" && (
              <p className="font-inter text-[20px] leading-[26px] font-bold mt-12 mx-12 sm:mx-0 text-center xl:text-start">
                {" "}
                Sneaker insurance™ built for the culture, powered by partners.
              </p>
            )} */}
            <form
              onSubmit={
                props.formType === "user"
                  ? submitUserHandler
                  : props.formType === "partner"
                  ? submitPartnerHandler
                  : null
              }
              className="mx-6 sm:mx-32 xl:mx-0 r"
            >
              <div className="my-16 md:mr-0 w-32   sm:w-auto xl:w-[50rem] 2xl:w-[55rem] grid col-start-1 col-span-3 gap-2 2xl:gap-4 font-inter font-normal text-[16px] leading-[24px]">
                {props.formType === "partner" && (
                  <>
                    <label>Company Name *</label>
                    <input
                      type="text"
                      name="company_name"
                      className="text-[30px]  w-[23rem] sm:w-auto  2xl:w-auto leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2  outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                      value={partnerData.company_name}
                      onChange={(e) => {
                        changePartnerHandler(e);
                        setCompanyNameError("");
                      }}
                      placeholder="add here"
                    />
                    <p className="text-track-red">{companyNameError}</p>
                  </>
                )}

                <label>Full Name *</label>
                <input
                  type="text"
                  name="name"
                  className="text-[30px] w-[23rem] sm:w-auto 2xl:w-auto leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2  outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                  value={
                    props.formType === "user"
                      ? data.name
                      : props.formType === "partner"
                      ? partnerData.name
                      : null
                  }
                  onChange={
                    props.formType === "user"
                      ? (e) => {
                          changeUserHandler(e);
                          setNameError("");
                        }
                      : props.formType === "partner"
                      ? (e) => {
                          changePartnerHandler(e);
                          setPartnerNameError("");
                        }
                      : null
                  }
                  placeholder="add here"
                />
                {props.formType === "user" && (
                  <p className="text-track-red">{nameError}</p>
                )}
                {props.formType === "partner" && (
                  <p className="text-track-red">{partnerNameError}</p>
                )}

                <label>Email Address *</label>
                <input
                  type="email"
                  name="email"
                  className=" w-[23rem] sm:w-auto 2xl:w-auto text-[30px] leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2 outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                  value={
                    props.formType === "user"
                      ? data.email
                      : props.formType === "partner"
                      ? partnerData.email
                      : null
                  }
                  onChange={
                    props.formType === "user"
                      ? (e) => {
                          changeUserHandler(e);
                          setEmailError("");
                        }
                      : props.formType === "partner"
                      ? (e) => {
                          changePartnerHandler(e);
                          setPartnerEmailError("");
                        }
                      : null
                  }
                  placeholder="add here"
                />
                {props.formType === "user" && (
                  <p className="text-track-red">{emailError}</p>
                )}
                {props.formType === "partner" && (
                  <p className="text-track-red">{partnerEmailError}</p>
                )}
                {props.formType === "partner" && (
                  <>
                    <label>Role/Position *</label>
                    <input
                      type="text"
                      name="role"
                      className="text-[30px] w-[23rem] sm:w-auto 2xl:w-auto leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2  outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                      value={partnerData.role}
                      onChange={(e) => {
                        changePartnerHandler(e);
                        setRoleError("");
                      }}
                      placeholder="add here"
                    />
                    {roleError && (
                      <div className="text-track-red">{roleError}</div>
                    )}
                  </>
                )}

                <label>Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  className="no-arrows w-[23rem] sm:w-auto 2xl:w-auto text-[30px] leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2 outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white "
                  value={
                    props.formType === "user"
                      ? data.phone
                      : props.formType === "partner"
                      ? partnerData.phone
                      : null
                  }
                  onChange={
                    props.formType === "user"
                      ? (e) => {
                          changeUserHandler(e);
                        }
                      : props.formType === "partner"
                      ? (e) => {
                          changePartnerHandler(e);
                        }
                      : null
                  }
                  placeholder="add here"
                />
                <p className="text-track-red">{phoneError}</p>
                {props.formType === "partner" && (
                  <>
                    <label>Website *</label>
                    <input
                      type="text"
                      name="website"
                      className="text-[30px] w-[23rem] sm:w-auto 2xl:w-auto leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2  outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                      value={`${partnerData.website}`}
                      onChange={(e) => {
                        changePartnerHandler(e);
                        setWebsiteError("");
                      }}
                      placeholder="add here"
                    />{" "}
                    {websiteError && (
                      <div className="text-track-red">{websiteError}</div>
                    )}
                  </>
                )}

                {props.formType === "partner" && (
                  <>
                    <label>Partner Type (Select All That Apply) *</label>
                    <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-5 gird-rows-3 sm:grid-rows-2 mt-2">
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Insurance Broker{" "}
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Insurance Broker"
                          checked={isPartnerChecked('Insurance Broker')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Online Retailer
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Online Retailer"
                          checked={isPartnerChecked('Online Retailer')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                            // if(e.target.)
                            // setShowOnlineRetailerFields(
                            //   !showOnlineRetailerFields
                            // );
                            if(!e.target.checked)
                            resetPartnerRadioButton(e.target.checked, 'retail_platform', 'managed_product_launches');
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>

                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Secondary Marketplace
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Secondary Marketplace"
                          checked={isPartnerChecked('Secondary Marketplace')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                          }}
                        />
                        <span className="checkmark"></span>
                        {/* {!partnerTypeCheckbox && <p>{partnerTypeError}</p>} */}
                      </label>
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Brick & Mortar Store
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Brick & Mortar Store"
                          checked={isPartnerChecked('Brick & Mortar Store')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                            setShowBrickAndMortarFields(
                              !showBrickAndMortarFields
                            );
                            if(!e.target.checked)
                            resetPartnerRadioButton(e.target.checked, 'pos_system');
                          }}
                        />
                        <span className="checkmark"></span>
                        {!partnerTypeCheckbox && <p>{partnerTypeError}</p>}
                      </label>
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Auction House
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Auction House"
                          checked={isPartnerChecked('Auction House')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Community
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Community"
                          checked={isPartnerChecked('Community')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                            // setShowCommunityFields(!showCommunityFields);
                            if(!e.target.checked)
                            resetPartnerRadioButton(e.target.checked, 'people_in_social_group', 'social_group', e.target.value);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="partner mr-4 sm:mr-0">
                        {" "}
                        Ambassador
                        <input
                          type="checkbox"
                          name="partner_type"
                          value="Influencer"
                          checked={isPartnerChecked('Influencer')}
                          onChange={(e) => {
                            changePartnerHandler(e);
                            setPartnerTypeError("");
                            // setShowInfluencerFields(!showInfluencerFields);
                            if(!e.target.checked)
                            resetPartnerRadioButton(e.target.checked, 'people_in_social_group', 'social_group', e.target.value);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {partnerTypeError && (
                      <p className="text-track-red">{partnerTypeError}</p>
                    )}
                  </>
                )}

                {/* When the Online Retailer box is checked  */}
                {/* {showOnlineRetailerFields && ( */}
                {isPartnerChecked('Online Retailer') && (
                  <>
                    {/* Which platform is your store on? */}
                    {props.formType === "partner" && (
                      <div className="">
                        <label>Which platform is your store on? *</label>
                        <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 grid-rows-3 sm:grid-rows-2 gap-4 sm:gap-6 xl:gap-4 2xl:gap-6 mb-4 mt-5">
                          {/* Shopify  */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="Shopify"
                              checked={partnerData.retail_platform === "Shopify"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Shopify
                          </label>
                          {/* Wix  */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="Wix"
                              checked={partnerData.retail_platform === "Wix"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Wix
                          </label>
                          {/* WooCommerce  */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="WooCommerce"
                              checked={
                                partnerData.retail_platform === "WooCommerce"
                              }
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            WooCommerce
                          </label>

                          {/* Magento  */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="Magento"
                              checked={partnerData.retail_platform === "Magento"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Magento
                          </label>

                          {/* Square */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="Square"
                              checked={partnerData.retail_platform === "Square"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Square
                          </label>

                          {/* Other */}
                          <label>
                            <input
                              type="radio"
                              name="retail_platform"
                              value="Other"
                              checked={partnerData.retail_platform == "Other"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPlatformStoreError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Other
                          </label>
                        </div>
                        {partnerPlatformStoreError && (
                          <p className="text-track-red">
                            {partnerPlatformStoreError}
                          </p>
                        )}
                      </div>
                    )}

                    {/* Do you manage and run product raffles/launches? */}
                    {props.formType === "partner" && (
                      <div className="mb-4">
                        <label>
                          Do you manage and run product raffles/launches? *
                        </label>
                        <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-4 grid-rows-2 sm:grid-rows-1 gap-4 sm:gap-6 mt-5">
                          {/* Yes  */}
                          <label>
                            <input
                              type="radio"
                              name="managed_product_launches"
                              value="Yes"
                              checked={
                                partnerData.managed_product_launches == "Yes"
                              }
                              onChange={(e) => {
                                changePartnerHandler(e);
                                // setNameError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Yes
                          </label>
                          {/* No  */}
                          <label>
                            <input
                              type="radio"
                              name="managed_product_launches"
                              value="No"
                              checked={
                                partnerData.managed_product_launches == "No"
                              }
                              onChange={(e) => {
                                changePartnerHandler(e);
                                // setNameError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            No
                          </label>
                          {/* Not Yet  */}
                          <label>
                            <input
                              type="radio"
                              name="managed_product_launches"
                              value="Not Yet"
                              checked={
                                partnerData.managed_product_launches ==
                                "Not Yet"
                              }
                              onChange={(e) => {
                                changePartnerHandler(e);
                                // setNameError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Not Yet
                          </label>

                          {/* Want to Learn More  */}
                          <label>
                            <input
                              type="radio"
                              name="managed_product_launches"
                              value="Want to Learn More"
                              checked={
                                partnerData.managed_product_launches ==
                                "Want to Learn More"
                              }
                              onChange={(e) => {
                                changePartnerHandler(e);
                                // setNameError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Want to Learn More
                          </label>
                        </div>
                        {partnerManageRaffleError && (
                          <p className="text-track-red">
                            {partnerManageRaffleError}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* When the Online Retailer and Brick & Mortar Store box is checked  */}
                {/* {showOnlineRetailerFields ||
                  (showBrickAndMortarFields && ( */}
                {isPartnerChecked('Online Retailer') ||
                  (isPartnerChecked('Brick & Mortar Store') && (
                    <>
                      {/* Do you manage and run product raffles/launches? */}
                      {props.formType === "partner" && (
                        <div className="mb-4">
                          <label>
                            Do you manage and run product raffles/launches? *
                          </label>
                          <div className="grid grid-flow-row-dense grid-cols-4 grid-rows-1 gap-6 mb-4 mt-5">
                            {/* Yes  */}
                            <label>
                              <input
                                type="radio"
                                name="managed_product_launches"
                                value="Yes"
                                checked={
                                  partnerData.managed_product_launches == "Yes"
                                }
                                onChange={(e) => {
                                  changePartnerHandler(e);
                                  setPartnerManageRaffleError("");
                                }}
                                className="hidden"
                              />
                              <span className="custom-radio"></span>
                              Yes
                            </label>
                            {/* No  */}
                            <label>
                              <input
                                type="radio"
                                name="managed_product_launches"
                                value="No"
                                checked={
                                  partnerData.managed_product_launches == "No"
                                }
                                onChange={(e) => {
                                  changePartnerHandler(e);
                                  setPartnerManageRaffleError("");
                                }}
                                className="hidden"
                              />
                              <span className="custom-radio"></span>
                              No
                            </label>
                            {/* Not Yet  */}
                            <label>
                              <input
                                type="radio"
                                name="managed_product_launches"
                                value="Not Yet"
                                checked={
                                  partnerData.managed_product_launches ==
                                  "Not Yet"
                                }
                                onChange={(e) => {
                                  changePartnerHandler(e);
                                  setPartnerManageRaffleError("");
                                }}
                                className="hidden"
                              />
                              <span className="custom-radio"></span>
                              Not Yet
                            </label>

                            {/* Want to Learn More  */}
                            <label>
                              <input
                                type="radio"
                                name="managed_product_launches"
                                value="Want to Learn More"
                                checked={
                                  partnerData.managed_product_launches ==
                                  "Want to Learn More"
                                }
                                onChange={(e) => {
                                  changePartnerHandler(e);
                                  setPartnerManageRaffleError("");
                                }}
                                className="hidden"
                              />
                              <span className="custom-radio"></span>
                              Want to Learn More
                            </label>
                          </div>
                          {partnerManageRaffleError && (
                            <p className="text-track-red">
                              {partnerManageRaffleError}
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  ))}
                {/* When the Brick & Mortar Store box is checked  */}
                {showBrickAndMortarFields && (
                  <>
                    {/* "IF" Brick and Mortar, what POS system do you use? */}
                    {props.formType === "partner" && (
                      <div className="mb-4">
                        <label>
                          "IF" Brick and Mortar, what POS system do you use? *
                        </label>
                        <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 grid-rows-3 sm:grid-rows-2 gap-6 mb-4 mt-5">
                          {/* Shopify  */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="Shopify"
                              checked={partnerData.pos_system === "Shopify"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Shopify
                          </label>
                          {/* Lightspeed  */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="Lightspeed"
                              checked={partnerData.pos_system === "Lightspeed"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Lightspeed
                          </label>
                          {/* Square  */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="Square"
                              checked={partnerData.pos_system === "Square"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Square
                          </label>

                          {/* Clover  */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="Clover"
                              checked={partnerData.pos_system === "Clover"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Clover
                          </label>

                          {/* PayPal */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="PayPal"
                              checked={partnerData.pos_system === "PayPal"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            PayPal
                          </label>

                          {/* Other */}
                          <label>
                            <input
                              type="radio"
                              name="pos_system"
                              value="Other"
                              checked={partnerData.pos_system === "Other"}
                              onChange={(e) => {
                                changePartnerHandler(e);
                                setPartnerPOSSystemError("");
                              }}
                              className="hidden"
                            />
                            <span className="custom-radio"></span>
                            Other
                          </label>
                        </div>
                        {partnerPOSSystemError && (
                          <p className="text-track-red">
                            {partnerPOSSystemError}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* When either Community or Influencer or both box are checked  */}
                {/* {showCommunityFields || showInfluencerFields ? ( */}
                {isPartnerChecked('Community') || isPartnerChecked('Influencer') ? (
                  <>
                    {props.formType === "partner" && (
                      <>
                        {/* IG Handle, Website or Facebook Group (add all relevant) * */}

                        <div className="grid mb-4">
                          <label>
                            IG Handle, Website or Facebook Group (add all
                            relevant) *
                          </label>
                          <input
                            type="text"
                            name="social_group"
                            className="mt-4 w-[23rem] sm:w-auto 2xl:w-auto  text-[30px] leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2 outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                            value={
                              props.formType === "partner"
                                ? partnerData.social_group
                                : null
                            }
                            onChange={
                              props.formType === "partner"
                                ? (e) => {
                                    changePartnerHandler(e);
                                    setPartnerIGHandleError("");
                                  }
                                : null
                            }
                            placeholder="add here"
                          />
                        </div>
                        {partnerIGHandleError && (
                          <p className="text-track-red">
                            {partnerIGHandleError}
                          </p>
                        )}
                        {/* Amount of people in group (approximately) * */}
                        <div className="grid mb-4">
                          <label>
                            Amount of people in group (approximately) *
                          </label>
                          <input
                            type="text"
                            name="people_in_social_group"
                            className="mt-4 w-[23rem] sm:w-auto 2xl:w-auto  text-[30px] leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2 outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                            value={
                              props.formType === "partner"
                                ? partnerData.people_in_social_group
                                : null
                            }
                            onChange={
                              props.formType === "partner"
                                ? (e) => {
                                    changePartnerHandler(e);
                                    setPartnerAmountOfPeopleError("");
                                  }
                                : null
                            }
                            placeholder="add here"
                          />
                        </div>
                        {partnerAmountOfPeopleError && (
                          <p className="text-track-red">
                            {partnerAmountOfPeopleError}
                          </p>
                        )}
                      </>
                    )}
                  </>
                ) : null}
                {/* 
                {props.formType === "partner" && (
                  <>
                    <label>Currently offer an insurance product?</label>
                    <div className="flex mb-4">
                      <label>
                        <input
                          type="radio"
                          name="is_offer_insurance_product"
                          value="true"
                          checked={
                            partnerData.is_offer_insurance_product == "true"
                          }
                          onChange={(e) => {
                            changePartnerHandler(e);
                            // setNameError("");
                          }}
                          className="mr-3"
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="is_offer_insurance_product"
                          value="false"
                          checked={
                            partnerData.is_offer_insurance_product == "false"
                          }
                          onChange={(e) => {
                            changePartnerHandler(e);
                          }}
                          className="mr-3 ml-6"
                        />
                        No
                      </label>
                    </div>
                  </>
                )} */}

                <label>Message *</label>
                <input
                  type="text"
                  name="body"
                  className="w-[23rem] sm:w-auto 2xl:w-auto  text-[30px] leading-[40px] font-medium mytext--stroke placeholder:font-druk placeholder:text-[30px] placeholder:leading[40px] placeholder:font-medium border-b-2 outline-none opacity-50 focus:opacity-100 bg-black focus:border-b-2 focus:border-white"
                  value={
                    props.formType === "user"
                      ? data.body
                      : props.formType === "partner"
                      ? partnerData.body
                      : null
                  }
                  onChange={
                    props.formType === "user"
                      ? (e) => {
                          changeUserHandler(e);
                          setBodyError("");
                        }
                      : props.formType === "partner"
                      ? (e) => {
                          changePartnerHandler(e);
                          setPartnerBodyError("");
                        }
                      : null
                  }
                  placeholder="add here"
                />
                {props.formType === "user" && (
                  <p className="text-track-red">{bodyError}</p>
                )}
                {props.formType === "partner" && (
                  <p className="text-track-red">{partnerBodyError}</p>
                )}
              </div>
              <div className="grid  sm:flex gap-8 sm:gap-20 xl:col-span-6 mx-2 justify-center xl:justify-start">
                <button
                  className="flex justify-center items-center w- sm:w-56 sm:my-0 col-span-8 sm:col-span-3 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4 "
                  type="submit"
                >
                  {loading ? (
                    <>
                      <svg className="animate-spin h-5 w-5 mr-3 bg-blue" />
                      loading...
                    </>
                  ) : (
                    <>
                      SEND MESSAGE
                      <div className="cursor-pointer rotate-90 ml-6 ">
                        <img src={scrollArrow} alt='' className="scroll-smooth " />
                      </div>
                    </>
                  )}
                </button>

                <h2 className="col-span-8 text-center sm:ml-0 sm:mt-3 font-inter text-[16px] leading-[24px]">
                  You can also reach us at{" "}
                  <span className="text-blue cursor-pointer">
                    hello@solesafe.co
                  </span>
                </h2>
              </div>

              {emailResponse.message && (
                <p
                  className={`${
                    emailResponse.success ? "text-lime-500" : "text-red-700"
                  } mt-4 ml-2`}
                >
                  {emailResponse.message}
                </p>
              )}
              {partnerEmailResponse.message && (
                <p
                  className={`${
                    partnerEmailResponse.success
                      ? "text-lime-500"
                      : "text-red-700"
                  } mt-4 ml-2`}
                >
                  {partnerEmailResponse.message}
                </p>
              )}
            </form>
          </>
          {/* ) : (
            ""
          )} */}
        </div>

        <div className="xl:col-span-3 2xl:col-span-4 xl:mt-20 2xl:mt-0">
          {" "}
          <img
            src={supportMobile}
            alt=''
            className="w-full h-100 xl:-ml-10 p-12 sm:p-44 xl:p-0 2xl:p-4"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GotQuestion;
