import { BrowserRouter, Routes, Route } from "react-router-dom";

import { routes } from "./routes";

import "./App.scss";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.component />}
                exact={route.exact}
                key={index}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
