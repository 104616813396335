import React, { useState, useEffect } from "react";
import LeftArrow from "../../assets/images/png/left-arrow.png";
import RightArrow from "../../assets/images/png/right-arrow.png";
import PartnerQuickInstall from "../../assets/images/svg/partner-quickInstall.svg";
import PartnerUnifiedCheckout from "../../assets/images/svg/partner-unifiedCheckout.svg";
import PartnerSeeTheProfits from "../../assets/images/svg/partner-seeTheProfits.svg";

const YellowBox = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  const HowItWorksContent = [
    {
      id: "01",
      heading: "Quick Install",
      description: (
        <div>
          {" "}
          Simply install the <b>SoleSafe™</b> Shopify App in less than
          10-minutes
        </div>
      ),
      image: <img src={PartnerQuickInstall} />,
    },
    {
      id: "02",
      heading: "Unified Checkout",
      description: "Drive More Revenue and Reduce Store Risk at Checkout.",
      image: <img src={PartnerUnifiedCheckout} />,
    },
    {
      id: "03",
      heading: "See the Profits",
      description:
        "See your insurance purchase and revenue stats in a comprehensive seller dashboard.",
      image: <img src={PartnerSeeTheProfits} />,
    },
  ];

  const increaseCounter = () => {
    if (currentStep < HowItWorksContent.length - 1) {
      setCurrentStep((prev) => prev + 1);
      if (window.innerWidth >= 1024) {
        setAnimationClass("animate-horizontalFloat");
      } else {
        setAnimationClass("animate-verticalFloat");
      }
    }
  };

  const decreaseCounter = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      if (window.innerWidth >= 1024) {
        setAnimationClass("animate-horizontalFloat");
      } else {
        setAnimationClass("animate-verticalFloat");
      }
    }
  };

  useEffect(() => {
    if (animationClass !== "") {
      const timeout = setTimeout(() => {
        setAnimationClass("");
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timeout);
    }
  }, [animationClass]);

  return (
    <React.Fragment>
      <div className="relative">
        <div className="relative lg:flex lg:justify-between lg:bg-yellow h-72 lg:h-96 text-black py-8 rounded-2xl sm:mx-32 lg:mx-0 lg:mr-12 2xl:mr-24">
          <div>
            {HowItWorksContent.map((ele, id) => (
              <div
                key={id}
                style={{ display: id === currentStep ? "block" : "none" }}
                className="bg-yellow w-[20rem] sm:w-[44rem] lg:w-auto h-[277px] sm:h-[300px] lg:h-auto mx-auto pb-10 sm:p-8 rounded-2xl  lg:mx-0"
              >
                {/* Slider Image  */}
                <div
                  className={`${
                    id === currentStep ? animationClass : ""
                  } px-4 sm:px-0 sm:mx-0 absolute inset-y-0 -top-[12rem] sm:-top-[29rem] lg:-top-14 right-0 2xl:right-32 z-10`}
                >
                  {ele.image}
                </div>

                <div className="mx-6 sm:mx-0 font-druk font-bold lg:font-medium text-[22px] lg:text-[30px] space-y-4 pt-12 lg:pt-0">
                  {/* Yellow background Id no  */}
                  <h1>{ele.id}</h1>
                  {/* Yellow background Heading  */}
                  <h1 className="lg:pt-8 2xl:pt-12">{ele.heading}</h1>
                </div>
                {/* Yellow background Description  */}
                <h3 className="mx-6 sm:mx-0 pb-12 font-inter font-normal text-[16px] py-4 sm:w-[60%] lg:w-2/3">
                  {ele.description}
                </h3>
              </div>
            ))}
          </div>
        </div>
        {/* Arrow navigation */}
        <div className="bg-yellow px-2 sm:px-9 lg:px-0 lg:bg-transparent absolute inset-x-16 sm:inset-x-40 lg:inset-x-9 bottom-0 sm:-bottom-5 lg:bottom-10 flex space-x-4 z-20">
          <img
            src={LeftArrow}
            onClick={decreaseCounter}
            className="cursor-pointer"
          />
          <img
            src={RightArrow}
            onClick={increaseCounter}
            className="cursor-pointer"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default YellowBox;
