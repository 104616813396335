import React from "react";
import arrowRight from "../../assets/images/svg/arrow-right.svg";
import UpwardArrow from "../../assets/images/png/upward_arrow.png";
import DownwardArrow from "../../assets/images/png/downward_arrow.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const FreshRaffleAccordion = () => {
  const [currentStep, setCurrentStep] = useState("01");

  const FreshRaffleAccordionContent = [
    {
      id: "01",
      heading: "01 — PRE LAUNCH",
      description: (
        <div className="space-y-4 mb-8">
          <h1>
            <b>Built for Scale: </b>
            Our back-end is tested to handle the volume of the most sought-after
            drops. This is scales from one customer to thousands.
          </h1>
          <h1>
            <b> Launch Collaboration: </b>
            Partnering with you each step of the way is important for our team.
            Less drama equals smoother drops!
          </h1>
          <h1>
            <b> Familiar Design: </b>
            Easily setup a launch with your existing branding on Shopify. No
            separate page required that doesn't reflect your brand.
          </h1>
          <h1>
            <b> Package Protection Available: </b>
            Offer your customers package protection or Sneaker Insurance™ when
            checking out.
          </h1>
        </div>
      )
    },
    {
      id: "02",
      heading: "02 — DURING LAUNCH",
      description: (
        <div className="space-y-4 mb-8">
          <h1>
            <b> F*ck Bots: </b>
            We do the dirty work to create fairness using our raffle technology
            to remove bots, scammers, and duplicate entries.
          </h1>
          <h1>
            <b> EntryBoost™: </b>
            Fair raffles require thinking. We learn from purchases, loyalty,
            past raffle history, proximity, local preferences, and more.
          </h1>
          <h1>
            <b>Real-Time Insights: </b>
            Stay current on raffle results from entries, winners, losers, and
            much more.
          </h1>
          <h1>
            <b> Smooth Payments: </b>
            Payments are made easy for customers entering raffles. This saves
            you time and makes money faster on every raffle.
          </h1>
        </div>
      )
    },
    {
      id: "03",
      heading: "03 — AFTER LAUNCH",
      description: (
        <div className="space-y-4 mb-8">
          <h1>
            <b> Community Growth: </b>
            Raffle entries will have increased opt-in metrics meaning you have
            more customers acquired, more opportunities to re-engage, sell more
            products, and build local community.
          </h1>
          <h1>
            <b> Complete Orders: </b>
            We integrate directly into Shopify making it easy to fulfill orders.
            This means we are helping make it easier to do what you do best -
            serve your customers!
          </h1>
          <h1>
            <b> Run it Back: </b>
            One successful raffle means more to come - lets get it!
          </h1>
        </div>
      )
    }
  ];

  const handleHeadingClick = (id) => {
    setCurrentStep(id);
  };
  return (
    <React.Fragment>
      <div className="2xl:pr-24">
        {FreshRaffleAccordionContent.map((ele, id) => (
          <div key={id} className="border-t-[1px] border-black">
            <div
              className="flex justify-between font-druk font-medium lg:font-medium text-[15px] leading-[20px] my-5 cursor-pointer"
              onClick={() => handleHeadingClick(ele.id)}
            >
              <h1 className="text-black">{ele.heading}</h1>
              <img src={currentStep === ele.id ? UpwardArrow : DownwardArrow} />
            </div>
            {/* Yellow background Description  */}
            {currentStep === ele.id && (
              <h3 className="font-inter font-normal text-[16px] leading-[23px]">
                {ele.description}
              </h3>
            )}
          </div>
        ))}
        <div className="border-t-[1px] border-black" />
        <div className="flex sm:hidden bg-black mt-12 text-white rounded-full  justify-center items-center sm:justify-start mx-auto sm:mx-0 w-full sm:w-[50%] xl:w-2/5 2xl:w-4/5">
          <div className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto sm:px-4">
            <a
              href="https://apps.shopify.com/safedrop"
              className="flex justify-center items-center lg:w-full py-4"
            >
              <h1>INSTALL APP</h1>
              <img
                src={arrowRight}
                alt=''
                className="scroll-smooth cursor-pointer ml-4 "
              />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FreshRaffleAccordion;
