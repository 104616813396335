import React, { useState, useEffect, useRef } from "react";
import Original from "../../assets/images/png/original.png";
import featureBoxTwo from "../../assets/images/png/featureBoxTwo.png";
import featureBoxThree from "../../assets/images/png/featureBoxThree.png";
import featureBoxFour from "../../assets/images/png/featureBoxFour.png";
import featureBoxFive from "../../assets/images/png/featureBoxFive.png";
import "./feature.scss";

function Features() {
  const [marquee, setMarquee] = useState(" - FEATURES");
  const items = [
    {
      id: 1,
      label: " Authentication Built-in",
      feature: <img src={Original} />,
      Description: (
        <>
          <p>
            {" "}
            Verifying ownership for each sneaker insured on the platform. This
            comes with a 30-day window to make it easier for you to provide
            photos.
          </p>
        </>
      ),
    },

    {
      id: 2,
      label: "Automated Quotes",
      feature: <img src={featureBoxThree} />,
      Description: (
        <>
          <p> Automated Underwriting + Authentication for Instant Quotes</p>
        </>
      ),
    },
    {
      id: 3,
      label: "Dynamic Coverage",
      feature: <img src={featureBoxFour} />,
      Description: (
        <>
          <p> Real-Time, Dynamic Coverage Driven By Market Changes</p>
        </>
      ),
    },
    {
      id: 4,
      label: "Frictionless Claims",
      feature: <img src={featureBoxFive} />,
      Description: (
        <>
          <p> Hassle, Stress Free Claims Process Network Partners</p>
        </>
      ),
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [transformDim, setTransformDim] = useState(0);
  const [initialTransformDone, setInitialTransformDone] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const animateRef = useRef(null);
  const itemRef = useRef(null);

  const increaseCounter = () => {
    if (currentStep < items.length - 1) {
      setCurrentStep((prev) => prev + 1);
      if (currentStep <= 0) {
        setInitialTransformDone(true);
      }
    }
  };

  const decreaseCounter = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (animateRef && itemRef && windowWidth < 640) {
      const width = itemRef.current.getBoundingClientRect().width;
      setItemWidth(width);
      const initialTransform = windowWidth / 2.7 - width / 2;
      setTransformDim(initialTransform);
      animateRef.current.style.webkitTransform = `-webkit-translateX(-webkit-${initialTransform}px)`;
      setInitialTransformDone(true);
    }
    if (windowWidth >= 640) {
      animateRef.current.style.transform = `translateX(${0}px)`;
    }
  }, [windowWidth]);
  useEffect(() => {
    if (transformDim && initialTransformDone) {
      const transform =
        transformDim -
        currentStep * itemWidth -
        currentStep * 2 -
        currentStep * 20;
      animateRef.current.style.webkitTransform = `translateX(${transform}px)`;
    }
  }, [currentStep, transformDim, itemWidth]);

  const calculateWindowWidth = () => {
    setInitialTransformDone(false);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // need debouncing
    window.addEventListener("resize", calculateWindowWidth, true);

    return () =>
      window.removeEventListener("resize", calculateWindowWidth, true);
  });
  return (
    <React.Fragment>
      <div className="relative flex overflow-x-hidden mt-[10rem]">
        <div className="py-12 animate-marquee whitespace-nowrap  mytext--filled">
          <span className=" text-[52px] leading-[55px] font-medium font-druk">
            {" "}
            {marquee}
          </span>
          <span className="text-[52px] leading-[55px] font-medium font-druk ">
            {marquee}
          </span>
          <span className="text-[52px] leading-[55px] font-medium font-druk">
            {marquee}
          </span>
        </div>

        <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap font-druk mytext--filled">
          <span className="text-[52px] leading-[55px] font-medium font-druk">
            {marquee}
          </span>
          <span className="text-[52px] leading-[55px] font-medium font-druk">
            {marquee}
          </span>
          <span className="text-[52px] leading-[55px] font-medium font-druk">
            {marquee}
          </span>
        </div>
      </div>
      <div className="text-center py-28  px-10">
        We’re building insurance solutions authentic to the sneaker community.
        <br />
        We call it cultural asset protection™.
      </div>
      <div className="features ">
        <div className=" float-left ml-8 mb-4 flex sm:hidden ">
          {/* Slider Left Button  */}
          <button className=" w-14 " onClick={decreaseCounter}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="32.7868"
                cy="32.7868"
                r="32.7869"
                webkitTransform="rotate(-180 32.7868 32.7868)"
                fill="white"
              />
              <path
                d="M45.6837 30.7598L45.6837 34.0753L25.7905 34.0753L34.9082 43.193L32.5542 45.5471L19.4247 32.4176L32.5542 19.2881L34.9083 21.6421L25.7905 30.7598L45.6837 30.7598Z"
                fill="black"
              />
            </svg>
          </button>
          {/* Slider Right Button  */}
          <button className=" w-32" onClick={increaseCounter}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 66 66"
              webkitTransform="rotate(-180 32.7868 32.7868)"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="33.2132" cy="32.7869" r="32.7869" fill="white" />
              <path
                d="M20.3204 34.8139L20.3204 31.4984L40.2136 31.4984L31.0959 22.3807L33.4499 20.0267L46.5794 33.1562L33.4499 46.2856L31.0959 43.9316L40.2136 34.8139L20.3204 34.8139Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="features-alt__items">
          <div
            className="features-alt__items__animate transition ease-in-out delay-150 flex flex-row gap-5 mx-12 xl:mx-48 2xl:mx-64"
            data-translate={currentStep}
            ref={animateRef}
          >
            {items.map((item, id) => {
              return (
                <div
                  className={` features-alt__items__item sm:basis-1/5 text-center opacity-75 ${
                    id === currentStep
                      ? "features-alt__items__item--active"
                      : ""
                  } text-center `}
                  key={id}
                  ref={itemRef}
                >
                  <div className="">{item.feature}</div>
                  <h2 className="font-inter font-bold text-[16px] leading-[24px] py-6">
                    {item.label}
                  </h2>
                  <p className="font-inter font-normal text-[16px] leading-[24px]">
                    {item.Description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Features;
