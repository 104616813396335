import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import RelatedArticles from "./RelatedArticles";
import scrollBottomImg from "../../assets/images/svg/scroll-bottom.svg";
import BlogLists from "./BlogLists";
import { getStrapiImages } from "../../helpers";

function BlogDetails(props) {
  const { id } = useParams();

  const urlPrefix = process.env.REACT_APP_STRAPI_API;
  const socialUrl = `${urlPrefix}/api/social-medias?populate=*`;
  const blogsUrl = `${urlPrefix}/api/posts/${id}?populate=*`;
  const [socialLinks, setSocialLinks] = useState([]);
  const [postContent, setPostContent] = useState("");

  const { loading, error, data } = useFetch(blogsUrl);
  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(socialUrl);
      const { data } = await response.json();
      setSocialLinks(data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchSocialLinks();
  }, [socialUrl]);

  // Use the returned data to conditionally render the appropriate content
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :</p>;

  // get the categories of the current blog
  const categories = data?.attributes?.categories?.data?.map(
    (category) => category.id
  );

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const el = document.createElement("html");
      el.innerHTML = data?.attributes?.content;

      const blogImages = el.getElementsByTagName("img");

      for (let img of blogImages) {
        img.src = getStrapiImages(img.src);

        let srcSetValue = "";

        img.srcset.split(",").forEach((src) => {
          if (src !== "") {
            srcSetValue += `${getStrapiImages(src)},`;
          }
        });
        img.srcset = srcSetValue;
      }

      setPostContent(el.innerHTML);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;
  return (
    <>
      <Helmet>
        <style type="text/css">
          {`body {
                background-color: white;
            }
            .header__menu-icon__bar {
              background-color: black
            }
            #root .header, #root .header__right {
              background-color: #fff;
            }
            #root .download-button {              
              color: #fff;
              background: #000;
              border: 2px solid #000;
            }
            `}
        </style>
      </Helmet>
      <Header logoColor="black" barColor="black" theme="light" />
      <div className="mt-32 mx-4 sm:mx-12 lg:mx-0 2xl:mx-14 text-black flex flex-col lg:flex-row gap-4 xl:gap-20 2xl:gap-32">
        <div className="basis-1/4 justify-self-center lg:mx-12">
          <div className="grid space-y-8 sm:flex justify-between items-center lg:grid">
            <div>
              <h2 className="space-x-2 font-inter font-normal text-[16px]">
                <b> Writing By</b>
                <span>{data?.attributes?.author?.data?.attributes?.name}</span>
              </h2>
              <h2 className="">
                {moment(`${data?.attributes?.publishedAt}`).format(
                  "DD MMM, YYYY hh:mm A"
                )}{" "}
              </h2>
            </div>
            <div className=" gap-2 lg:mt-12">
              {data?.attributes?.categories?.data?.map((ele) => (
                <div
                  key={ele?.id}
                  className="my-4 border-2 border-black  rounded-full bg-yellow text-center"
                >
                  <button className=" my-2 px-12 py-2 font-inter font-normal text-center text-[20px] leading-[24px]">
                    {ele?.attributes?.name}
                  </button>
                </div>
              ))}
            </div>
            <div className="lg:mt-60 text-black text-[16px]">
              <div className="flex mb-2 gap-4">
                <h2 className="font-bold">SHARE</h2>
                <div className="w-[10%] lg:w-[17%]">
                  <img
                    src={scrollBottomImg}
                    alt="scroll bottom"
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex gap-4 lg:grid ">
                {socialLinks?.map((socialLink) => {
                  return (
                    <a
                      key={socialLink?.id}
                      href={socialLink?.attributes?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ul className="font-normal " key={socialLink?.id}>
                        <li className="">{socialLink?.attributes?.title}</li>
                      </ul>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="basis-3/4">
          <img
            className="mb-[123px]"
            src={`${urlPrefix}${data?.attributes?.featuredImage.data?.attributes?.url}`}
            alt="feature"
          />
          <div className="text-black mt-12 sm:mt-0 lg:pr-32">
            <h2 className=" font-inter font-bold text-[20px] leading-[26px]">
              {data?.attributes.title}
            </h2>
            <p className="my-4 font-inter font-normal text-[16px] leading-[24px]">
              <div dangerouslySetInnerHTML={{ __html: postContent }} />
            </p>
          </div>
        </div>
      </div>
      {/* <BlogLists /> */}
      <RelatedArticles
        categories={categories}
        key="related-articles"
        currentArticle={data.id}
      />
      <Footer theme="dark" />
    </>
  );
}

export default BlogDetails;
