import React from "react";
import "tw-elements";
import HowItWorksCarousel from "./carousel";

const HowItWorks = () => {
  return (
    <React.Fragment>
      <div className="font-medium text-center sm:text-left p-10  sm:w-2/3 2xl:w-1/2 xl:ml-48 2xl:ml-56">
        <h2 className="font-druk text-[52px] leading-[55px]">HOW IT <span className="mytext--stroke">WORKS</span></h2>
        <p className="font-inter text-[16px] leading-[24px] sm:w-2/3 py-10 ">
          <span className="font-bold">Sneaker insurance™</span> in minutes. Our
          team wanted to get you from searching a sneaker in your collection to
          covered before your next "W”.
        </p>
      </div>
      <HowItWorksCarousel />
    </React.Fragment>
  );
};

export default HowItWorks;
