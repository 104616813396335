import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useLocation } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import redCircle from "../../assets/images/svg/red-circle.svg";
import Terms from "../../components/PrivacyTerms/Terms";
import Privacy from "../../components/PrivacyTerms/Privacy";

const PrivacyTerms = () => {
  const { pathname } = useLocation();

  const show = pathname;

  return (
    <React.Fragment>
      <HelmetProvider>
        <div>
          <Helmet>
            <style type="text/css">
              {`body {
                background-color: black;
            }
        .header__menu-icon__bar {
            background-color: white
            }`}
            </style>
          </Helmet>
        </div>
      </HelmetProvider>
      <Header logoColor="white" barColor="white" theme="dark" />

      <div className="sm:bg-white text-black rounded-t-[3rem] sm:mt-14 py-20">
        <div className="flex flex-col sm:grid sm:grid-rows- sm:grid-flow-col gap-4 xl:gap-20 2xl:gap-32">
          <div className="bg-black sm:bg-white sm:row-span-3 sm:col-span-1 rounded-lg ">
            <div className="flex justify-around sm:grid -ml-4 sm:ml-12 2xl:ml-20 gap-3 sm:gap-6  bg-black sm:bg-white text-platinum selected:text-track-red font-bold font-inter text-[16px] leading-[24px] sticky top-4">
              <div className="flex gap-2">
                <a
                  href="/terms-of-use"
                  className={show === "/terms-of-use" ? "text-track-red" : ""}
                >
                  TERMS OF USE
                </a>
                {show === "/terms-of-use" && (
                  <img src={redCircle} className="rotate-90 sm:rotate-0" />
                )}
              </div>
              <div className="hidden sm:block w-40 border border-b-2  border-platinum"></div>
              <div className="flex gap-2">
                <a
                  href="/privacy-policy"
                  className={show === "/privacy-policy" ? "text-track-red" : ""}
                >
                  PRIVACY POLICY
                </a>
                {show === "/privacy-policy" && (
                  <img src={redCircle} className="rotate-90 sm:rotate-0" />
                )}
              </div>
            </div>
          </div>
          <div className="rounded-t-[3rem] bg-white sm:row-span-1 sm:col-span-3 px-5 sm:px-0 sm:pl-14 sm:pr-10 pt-32 sm:pt-0 pb-4 sm:pb-0 mt-12 sm:mt-0">
            {show === "/terms-of-use" ? (
              <Terms />
            ) : show === "/privacy-policy" ? (
              <Privacy />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyTerms;
