import { useState, useEffect } from "react";

const urlPrefix = process.env.REACT_APP_STRAPI_API;

const AppStores = () => {
  const [data, setData] = useState({});
  const footerUrl = `${urlPrefix}/api/footer?populate=*`;
  useEffect(() => {
    fetch(footerUrl)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response");
        }
        return response.json();
      })

      .then((footer) => {
        setData(footer.data);
      });
  }, []);

  return (
    <div className="flex flex-row mt-10">
      <a
        className="flex"
        href={data?.attributes?.appStore?.href}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          src={`${urlPrefix}${data?.attributes?.appleIcon?.data?.attributes?.url}`}
          alt="apple store"
        />
      </a>
      <a
        className="flex w-[9rem]"
        href={data?.attributes?.playStore?.href}
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        <img
          className=""
          src={`${urlPrefix}${data?.attributes?.playstoreIcon?.data?.attributes?.url}`}
          alt="google play store"
        />{" "}
      </a>
    </div>
  );
};

export default AppStores;
