import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import communityYourSneaker from "../../assets/images/svg/community_your_sneaker.svg";
import communityBlueCoin from "../../assets/images/svg/community_blue_coin.svg";
import communityYellowBlurCoin from "../../assets/images/svg/community_yellow_blur_coin.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

const YourSneaker = () => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
      <div className="grid sm:grid sm:grid-cols-9 gap-8 sm:gap-0 mt-12 xl:mt-44 2xl:mt-48 overflow-x-hidden">
        <div className="sm:col-span-8 lg:col-span-5 2xl:col-span-5 sm:ml-12 2xl:pl-12">
          <h2 className=" mx-auto xl:mx-0 w-auto xl:w-auto 2xl:w-5/6 text-center mytext--filled sm:text-left font-druk font-medium sm:font-bold text-[36px] sm:text-[72px] leading-[40px] sm:leading-[72px] px-4 sm:px-0">
            <p className="mx-auto sm:mx-0 mytext--stroke ">YOUR SNEAKER</p>
            COMMUNITY’S NEW HOME
          </h2>
          <p className="mx-auto xl:mx-0 w-auto sm:mx-0 sm:w-1/2 xl:w-3/4 2xl:w-3/5 text-center sm:text-left text-[16px] leading-[19.36px] font-inter my-10 sm:my-12 px-4 sm:px-0 sm:mr-56 xl:mr-0">
            <b> SoleSafe™ </b>
            offers a community management platform built from the ground up as a
            gathering spot to celebrate the people, products, and stories that
            connect us through sneakers.
          </p>

          <div className="bg-white text-black rounded-full flex justify-center items-center sm:justify-start mx-4 sm:mx-0 sm:w-[30%] xl:w-1/3 2xl:w-1/4">
            <button
              className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto"
              type="submit"
              onClick={() => {
                navigate("/contact", { state: "partner" });
              }}
            >
              <div className="flex justify-center items-center lg:w-full py-3 ">
                <h1>REQUEST A DEMO </h1>
                <img
                  src={scrollArrow}
                  className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                />
              </div>
            </button>
          </div>
        </div>

        <div className="sm:col-span-1 lg:col-span-4 2xl:col-span-4 sm:w-[34rem] lg:w-[100%] sm:mr-[20rem] lg:mr-0 sm:mt-[12rem] lg:mt-0 xl:mt-[-3rem] place-self-center">
          {" "}
          <img
            src={communityYellowBlurCoin}
            className="absolute sm:flex sm:right-[20rem] xl:right-[28rem] 2xl:right-[41rem] w-24 sm:w-28 h-28"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          />
          <img
            src={communityBlueCoin}
            className="absolute sm:flex right-[3rem] xl:right-[7rem] 2xl:right-[17rem] mt-[16rem] sm:mt-[22rem] xl:mt-[26rem] 2xl:mt-[30rem] w-[5.5rem] sm:w-28 xl:w-32"
            style={{ transform: `translateY(${offsetY * 0.1}px)` }}
          />
          <img
            src={communityYourSneaker}
            //   style={{ transform: `translateY(-${offsetY * 0.14}px)` }}
            className="p-4"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default YourSneaker;
