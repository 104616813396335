import React from "react";
import FreshRaffleAccordion from "./FreshRaffleAccordion";
import arrowRight from "../../assets/images/svg/arrow-right.svg";
import { Link } from "react-router-dom";

const FreshRaffle = () => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-12 grid-flow-row bg-blue px-4 py-8 sm:p-10 rounded-xl mx-4 sm:mx-12 2xl:mx-56">
        <div className="flex flex-col justify-between col-span-12 sm:col-span-6 font-druk font-medium text-[20px] sm:text-[35px] xl:text-[48px] leading-[24px] sm:leading-[40px] xl:leading-[48px] ">
          <h1 className="w-3/4 sm:w-auto pb-10 sm:pb-0">
            FRESH RAFFLE MANAGEMENT EXPERIENCE POWERED BY SAFEDROP™
          </h1>
          <div className="hidden sm:flex bg-black my-4 text-white rounded-full  justify-center items-center sm:justify-start mx-auto sm:mx-0 w-3/5 sm:w-[50%] xl:w-2/5 2xl:w-1/3">
            <div className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto sm:px-4">
              <a
                 href="https://apps.shopify.com/safedrop"
                className="flex justify-center items-center lg:w-full py-3"
              >
                <h1>INSTALL APP</h1>
                <img
                  src={arrowRight}
                  className="scroll-smooth cursor-pointer ml-4 "
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <FreshRaffleAccordion />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FreshRaffle;
