//external imports
import React from "react";
import { Helmet } from "react-helmet";
//internal imports
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SneakerDrops from "../../components/RaffleManagement/SneakerDrops";
import HowItWorks from "../../components/RaffleManagement/HowItWorks";
import FreshRaffle from "../../components/RaffleManagement/FreshRaffle";
import WhyChoose from "../../components/RaffleManagement/WhyChoose";
import ReadyForSafedrop from "../../components/RaffleManagement/ReadyForSafedrop/ReadyForSafedrop";
import RaffleFaq from "../../components/RaffleManagement/RaffleFaq/RaffleFaq";
import JoinOurMission from "../../components/RaffleManagement/JoinOurMission";

const RaffleManagement = () => {
  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">
          {`body {
                background-color: black;
            }
        .header__menu-icon__bar {
            background-color: white
            }`}
        </style>
      </Helmet>

      <Header logoColor="white" barColor="white" theme="dark" />
      <div className="z-50">
        <SneakerDrops />
      </div>
      <HowItWorks />
      <FreshRaffle />
      <WhyChoose />
      <ReadyForSafedrop />
      <RaffleFaq />
      <JoinOurMission />
      <div className="mt-[12rem] mb-10">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default RaffleManagement;
