import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import RaffleJoinOurMissionTicket from "../../assets/images/svg/raffle_join_our_mission_ticket.svg";
import RaffleBlueBlurBall from "../../assets/images/svg/raffle_blue_blur_ball.svg";
import RaffleRedBall from "../../assets/images/svg/raffle_red_ball.svg";

const JoinOurMission = () => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <React.Fragment>
      <div className="relative text-center xl:pt-20 2xl:pt-32">
        <h1 className="font-druk font-medium text-[30px] sm:text-[48px] 2xl:text-[72px] leading-[32px] sm:leading-[48px] 2xl:leading-[72px] w-[18rem] sm:w-[56rem] 2xl:w-1/2 mx-auto">
          JOIN OUR MISSION <br />
          TO LEVEL THE RAFFLE PLAYING FIELD
        </h1>
        <h2 className="font-inter font-normal text-[16px] leading-[23px] w-[16rem] sm:w-[28rem] mx-auto my-8">
          Elevate your sneaker releases, engage your customers like never
          before, and increase your revenue. Join us today!
        </h2>

        {/* Schedule demo button  */}
        <div className="bg-white sm:my-4 text-black rounded-full flex justify-center items-center mx-auto mt-2 w-[15rem] sm:w-[30%] xl:w-1/6 2xl:w-60">
          <button
            className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto"
            type="submit"
            onClick={() => {
              navigate("/contact", { state: "partner" });
            }}
          >
            <div className="flex justify-center items-center lg:w-full py-3">
              <h1>SCHEDULE DEMO</h1>
              <img
                src={scrollArrow}
                className="scroll-smooth cursor-pointer rotate-90 ml-4 "
              />
            </div>
          </button>
        </div>
        {/* Parallax Effect Images  */}
        <img
          src={RaffleRedBall}
          className="absolute -bottom-[204rem] sm:-bottom-[178rem] xl:-bottom-[168rem] 2xl:-bottom-[174rem] right-[18.5rem] sm:right-[50rem] xl:right-[65rem] 2xl:right-[90rem] mt-[1rem] xl:mt-[16rem] w-20 xl:w-28"
          style={{ transform: `translateY(-${offsetY * 0.55}px)` }}
        />
        <img
          src={RaffleBlueBlurBall}
          className="absolute -bottom-[245rem] sm:-bottom-[210rem] xl:-bottom-[202rem] 2xl:-bottom-[215rem] right-[19rem] sm:right-[47rem] xl:right-[60rem] 2xl:right-[76rem] mt-[18rem] xl:mt-[16rem] w-20 xl:w-28"
          style={{ transform: `translateY(-${offsetY * 0.6}px)` }}
        />
        <img
          src={RaffleJoinOurMissionTicket}
          style={{ transform: `translateY(-${offsetY * 0.4}px)` }}
          className="absolute -bottom-[185rem] sm:-bottom-[158rem] xl:-bottom-[149rem] 2xl:-bottom-[160rem] right-[0rem] sm:right-[7rem] xl:right-72 2xl:right-96 p-4 w-[12rem] xl:w-[15rem] 2xl:w-[20rem] h-[34.6rem] z-40"
        />
      </div>
    </React.Fragment>
  );
};

export default JoinOurMission;
