import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectMethodName: "",
  importNav: "selectMethod",
  solesafeSneakers: [],
  stockXSneakers: [],
  collections: [],
};

export const importSlice = createSlice({
  name: "import",
  initialState,
  reducers: {
    setImportNav: (state, action) => {
      state.importNav = action.payload;
    },
    setSelectMethodName: (state, action) => {
      state.selectMethodName = action.payload;
    },
    updateSolesafeSneakers: (state, action) => {
      state.solesafeSneakers = action.payload;
    },
    updateStockxSneakers: (state, action) => {
      state.stockXSneakers = action.payload;
    },
    updateCollections: (state, action) => {
      state.collections = action.payload;
    },
    analyseSolesafeCSV() {},
  },
});

export const {
  setImportNav,
  setSelectMethodName,
  updateSolesafeSneakers,
  updateStockxSneakers,
  updateCollections,
  analyseSolesafeCSV,
} = importSlice.actions;

export default importSlice.reducer;
