import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import RaffleSneakerDropsShoe from "../../assets/images/svg/raffle_sneakerDrops_shoe.svg";
import RaffleSneakerDropsEnterRaffle from "../../assets/images/svg/raffle_sneakerDrops_enter_raffle.svg";
import RaffleSneakerDropsYellowTicket from "../../assets/images/svg/raffle_sneakerDrops_yellow_ticket.svg";
import RaffleSneakerDropsBlueTicket from "../../assets/images/svg/raffle_sneakerDrops_blue_ticket.svg";
import RaffleSneakerDropsRedTicket from "../../assets/images/svg/raffle_sneakerDrops_red_ticket.svg";
import "./sneakerDrop.scss";

const SneakerDrops = () => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const sneakerHeroLogo = useRef(null);

  const handleScroll = () => {
    // apply the offset only when the element is visible in the screen.
    if (sneakerHeroLogo.current) {
      const position = sneakerHeroLogo.current.getBoundingClientRect();
      const isElementWithinTheViewport =
        window.innerHeight - (position.top + position.y);
      if (window.innerWidth >= 1024) {
        setOffsetY(window.pageYOffset);
      } else {
        if (window.scrollY === 0) {
          setOffsetY(0);
        } else if (isElementWithinTheViewport > position.height / 3) {
          setOffsetY(
            window.pageYOffset -
              isElementWithinTheViewport -
              position.height / 3
          );
        } else if (isElementWithinTheViewport < -position.height) {
          setOffsetY(0);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
      {" "}
      <div className="flex flex-col lg:grid lg:grid-cols-9 gap-8 lg:gap-0 pt-12 2xl:pt-48 lg:relative desktop1:flex desktop1:flex-row">
        <div className="lg:col-span-8 lg:col-span-5 2xl:col-span-5 lg:ml-12 2xl:pl-12">
          <h2 className=" mx-auto lg:mx-0 w-auto lg:w-4/5 2xl:w-5/6 text-center mytext--filled lg:text-left font-druk font-medium text-[36px] lg:text-[72px] leading-[40px] lg:leading-[72px] px-4 lg:px-0 sm:font-bold">
            <p className="mx-auto lg:mx-0 mytext--stroke ">
              SNEAKER <br />
              DROPS MADE
            </p>
            SAFE & FAIR FOR EVERYONE
          </h2>
          <p
            className="mx-auto w-auto lg:mx-0 lg:w-1/2 2xl:w-3/4 text-center lg:text-left 
          text-[16px] leading-[19.36px] font-inter my-10 lg:my-12 px-4 lg:px-0 lg:mr-56"
          >
            Unlock the power of <b> SafeDrop™</b> – supercharge your store on
            Shopify using our raffle management platform built for fairness, a
            little fun, and better community engagement.
          </p>
          <div className="grid lg:flex gap-0 lg:gap-8">
            <div
              className="bg-white mt-4 lg:my-4 text-black rounded-full flex justify-center items-center 
            lg:justify-start mx-auto lg:mx-0 w-3/5 lg:w-[25%] 2xl:w-1/4"
            >
              <div className="cursor-pointer font-inter font-bold text-[16px] leading-[19.36px] lg:mx-auto">
                <a
                   href="https://apps.shopify.com/safedrop"
                  className="flex justify-center items-center lg:w-full py-3"
                >
                  <h1>INSTALL APP</h1>
                  <img
                    src={scrollArrow}
                    alt=''
                    className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                  />
                </a>
              </div>
            </div>
            <div className="bg-white my-4 text-black rounded-full flex justify-center items-center lg:justify-start mx-auto lg:mx-0 mt-4 w-3/5 lg:w-[28%] 2xl:w-1/4">
              <button
                className="font-inter font-bold text-[16px] leading-[19.36px] lg:mx-auto"
                type="submit"
                onClick={() => {
                  navigate("/contact", { state: "partner" });
                }}
              >
                <div className="flex justify-center items-center lg:w-full py-3">
                  <h1>SCHEDULE DEMO</h1>
                  <img
                    src={scrollArrow}
                    className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div
          className="relative lg:absolute desktop1:relative lg:right-0 lg:col-span-1 lg:col-span-4 2xl:col-span-4 lg:w-[25rem] lg:mr-[2rem] lg:mr-0 lg:mt-[16rem] 
          lg:mt-0 place-self-center z-40 sneaker-hero-logo mt-[7rem]"
          ref={sneakerHeroLogo}
        >
          {" "}
          <img
            src={RaffleSneakerDropsYellowTicket}
            // className="absolute sm:flex top-[46rem] sm:top-[29rem] xl:top-56 2xl:top-52 right-[5rem] xl:right-[10rem] 2xl:right-[24rem] w-28 xl:w-40 z-40"
            className="absolute lg:flex top-[-7%] right-[21%] w-28 sneaker-hero-logo__RaffleSneakerDropsYellowTicket"
            style={{ transform: `translateY(${offsetY * 0.1}px)` }}
          />
          <img
            src={RaffleSneakerDropsRedTicket}
            className="absolute lg:flex top-[49rem] lg:top-[33rem] right-[14rem] 2xl:right-[38rem] w-24 -z-10
            sneaker-hero-logo__RaffleSneakerDropsRedTicket"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          />
          <img
            src={RaffleSneakerDropsEnterRaffle}
            className="absolute lg:flex right-[1.5rem] 2xl:right-[20rem] w-36 z-30
            sneaker-hero-logo__RaffleSneakerDropsEnterRaffle"
            style={{ transform: `translateY(-${offsetY * 0.6}px)` }}
          />
          <img
            src={RaffleSneakerDropsBlueTicket}
            className="absolute lg:flex right-[18.5rem] lg:right-[20rem] 2xl:right-[46rem]  w-20 
            sneaker-hero-logo__RaffleSneakerDropsBlueTicket"
            style={{ transform: `translateY(-${offsetY * 0.7}px)` }}
          />
          <img
            src={RaffleSneakerDropsShoe}
            style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
            // className="p-4 w-[19rem] xl:w-[35rem] h-[34.6rem] z-40"
            className="sneaker-hero-logo__RaffleSneakerDropsShoe p-4 z-40"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SneakerDrops;
