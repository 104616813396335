import React from "react";
import solesafeBlackLogo from "../../assets/images/svg/solesafe-black-logo.svg";
import blackChecklist from "../../assets/images/svg/black-checklist.svg";
import blurCrosslist from "../../assets/images/svg/blur-crosslist.svg";

const Comparison = () => {
  const ComparisonData = [
    {
      id: 1,
      label: (
        <div className="font-bold">In-Transit Coverages - All Packages</div>
      ),
    },
    {
      id: 2,
      label: "Lost Packages",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 3,
      label: "Stolen Packages",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 4,
      label: "Damaged Packages",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },

    {
      id: 5,
      label: "Doorstep Theft Coverage",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 6,
      label: "No Deductible",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 7,
      label: "Understand Sneaker Culture",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 8,
      label: "Coverage Provided Beyond Delivery",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 9,
      label: <div className="font-bold">Sneaker Insurance™ Coverages</div>,
    },
    {
      id: 10,
      label: "In-Transit",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 11,
      label: "Theft",
      solesafe: true,
      carrierInsurance: true,
      noInsurance: false,
    },
    {
      id: 12,
      label: "Accidental Damage (Spills/Stains)",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 13,
      label: "Consignment",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 14,
      label: "Pet",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 15,
      label: "Flood (Non-Flood Zones)",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 16,
      label: "Fire",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 17,
      label: "Checked Baggage",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
    {
      id: 18,
      label: "Mold",
      solesafe: true,
      carrierInsurance: false,
      noInsurance: false,
    },
  ];

  return (
    <React.Fragment>
      {/* COMPARISON */}
      <div className="my-16 2xl:pt-16">
        <div className="mx-12">
          <p className="mx-2 pb-6 font-druk text-white text-center text-[30px] sm:text-[48px] leading-[40px] sm:leading-[55px] font-medium  col-start-2 col-span-4  ">
            COMPARISON
            <p className="font-inter font-normal text-[16px] leading-[24px] text-center text-white mt-6  mx-auto md:w-96">
              A different approach, using a new method of underwriting
              automation for <b>Sneaker Insurance™.</b>
            </p>
          </p>
        </div>

        <div className="text-black grid grid-cols-12">
          <div className="col-span-12 mx-[0.5rem] sm:mx-[5rem] lg:mx-[10rem] xl:mx-[14rem] 2xl:mx-[25rem] bg-white rounded-[1rem]">
            <div className="grid grid-cols-12 sm:gap-4 2xl:gap-0 rounded-t-[1rem]  bg-yellow">
              {/* FEATURES   */}
              <div className="col-span-5">
                <h1 className="flex justify-start items-center  font-druk font-medium text-[12px] sm:text-[20px] 2xl:text-[22px] h-24 pl-4 sm:pl-8">
                  {" "}
                  FEATURES
                </h1>
              </div>
              {/* Solesafe  */}
              <div className="col-span-2 flex justify-center">
                <div className="flex justify-center items-center h-24">
                  {" "}
                  <img src={solesafeBlackLogo} className="" />
                </div>
              </div>
              {/* CARRIER INSURANCE */}
              <div className="col-span-2 flex justify-center">
                <h1 className="2xl:w-1/2 mx-auto flex items-center text-center font-druk font-medium sm:font-bold text-[8px] lg:text-[15px] h-24">
                  {" "}
                  CARRIER INSURANCE
                </h1>
              </div>
              {/* NO INSURANCE  */}
              <div className="col-span-3 flex justify-center">
                <h1 className=" w-1/2 lg:w-[150px] desktop2:w-[191px] flex items-center text-center font-druk font-medium sm:font-bold text-[8px] lg:text-[15px] h-24">
                  {" "}
                  NO INSURANCE
                </h1>
              </div>
            </div>
            <div className=" col-span-12 border-t-2  border-black">
              {ComparisonData.map((item, index) => {
                return (
                  <div
                    className={`grid grid-cols-12 border-b-2  border-black py-4 ${
                      item.id === 1 || item.id === 9
                        ? "bg-lightYellow"
                        : "bg-white"
                    }`}
                    key={index}
                  >
                    <div className="col-span-5 pl-4 sm:pl-8">
                      <ul>
                        <li className="py-2 font-inter text-[16px] font-normal">
                          {" "}
                          {item.label}
                        </li>
                      </ul>
                    </div>

                    <div className="col-span-2 flex justify-center items-center">
                      {item.id === 1 || item.id === 9 ? null : (
                        <img
                          src={item.solesafe ? blackChecklist : blurCrosslist}
                          alt="check/cross mark"
                          className="h-[20px] lg:h-[24px] desktop2:h-[30px]"
                        />
                      )}
                    </div>

                    <div className="col-span-2 flex justify-center items-center">
                      {item.id === 1 || item.id === 9 ? null : (
                        <img
                          src={
                            item.carrierInsurance
                              ? blackChecklist
                              : blurCrosslist
                          }
                          alt="check/cross mark"
                          className="h-[20px] lg:h-[24px] desktop2:h-[30px]"
                        />
                      )}
                    </div>

                    <div className="col-span-3 flex justify-center items-center">
                      {item.id === 1 || item.id === 9 ? null : (
                        <img
                          src={
                            item.noInsurance ? blackChecklist : blurCrosslist
                          }
                          alt="check/cross mark"
                          className="h-[20px] lg:h-[24px] desktop2:h-[30px]"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Comparison;
