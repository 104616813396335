import React from "react";
import blackChecklist from "../../../assets/images/svg/black-checklist.svg";
import RafflePremiumBadge from "../../../assets/images/svg/raffle_premium_badge.svg";

const StandardPremiumBox = () => {
  const standardContent = [
    {
      id: 1,
      description: (
        <div>
          Manage up to <b className="ml-1">two (2)</b> sneaker drops per month.
        </div>
      ),
    },
    {
      id: 2,
      description: (
        <div>
          Access to <b className="ml-1">EntryBoost™ </b> and{" "}
          <b className="ml-1">DropTarget™ </b>features.
        </div>
      ),
    },
    {
      id: 3,
      description: (
        <div>Fairness-focused AI & Machine Learning Technology.</div>
      ),
    },
    {
      id: 4,
      description: <div>Bot Detection & Mitigation.</div>,
    },
    {
      id: 5,
      description: <div>Customizable Storefront UI.</div>,
    },
    {
      id: 6,
      description: <div>Curated Launch Dashboard.</div>,
    },
    {
      id: 7,
      description: (
        <div>
          {" "}
          <b>Sneaker Insurance™ </b> & Package Protection Upsell.
        </div>
      ),
    },
    {
      id: 8,
      description: <div>Automated Email Alerts.</div>,
    },
  ];
  const premiumContent = [
    {
      id: 1,
      description: (
        <div>
          <b>Unlimited</b> sneaker drops per month.
        </div>
      ),
    },
    {
      id: 2,
      description: <div>Everything included in Standard.</div>,
    },
    {
      id: 3,
      description: <div>In-depth retailer analytics and insights.</div>,
    },
    {
      id: 4,
      description: <div>Enhanced customer support.</div>,
    },
  ];

  return (
    <React.Fragment>
      <div className="grid sm:flex gap-7">
        {/* STANDARD  */}
        <div className="w-[23rem] sm:w-[28rem] xl:w-[34.3rem]  bg-lightYellow rounded-2xl p-9">
          <div className="text-black">
            <div className="bg-platinum text-black rounded-full w-max px-4 py-2 font-druk font-medium text-[15px] leading-[20px]">
              STANDARD
            </div>
            <div className="flex py-10">
              <h2 className="font-druk font-medium text-[19.3px] leading-[19.3px]">
                $
              </h2>
              <h1 className="font-druk font-medium text-[48px] leading-[48px]">
                0
              </h1>
              <h2 className="font-inter font-normal text-[16px] leading-[23px] text-lightGray mt-6">
                / month
              </h2>
            </div>
            <div className="border-b-[0.5px] border-lightGray" />
            {/* Standard content */}
            <div className="pt-10">
              {standardContent.map((ele, id) => (
                <div key={id}>
                  <div className="relative flex items-center font-inter font-normal text-[16px] leading-[23px] space-y-2 space-x-7">
                    <img
                      src={blackChecklist}
                      className="absolute w-6 pr-2 mr-4"
                    />

                    {ele.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* PREMIUM  */}
        <div className="relative w-[23rem] sm:w-[28rem] h-[34rem] sm:h-auto xl:w-[34.3rem] bg-yellow rounded-2xl p-9">
          <div className="absolute top-[-1px] right-8">
            <img src={RafflePremiumBadge} />
          </div>
          <div className="text-black">
            <div className="bg-black text-white rounded-full w-max px-4 py-2 font-druk font-medium text-[15px] leading-[20px]">
              PREMIUM
            </div>
            <div className="flex py-10">
              <h2 className="font-druk font-medium text-[19.3px] leading-[19.3px]">
                $
              </h2>
              <h1 className="font-druk font-medium text-[48px] leading-[48px]">
                100
              </h1>
              <h2 className="font-inter font-normal text-[16px] leading-[23px] text-lightGray mt-6">
                / month
              </h2>
            </div>
            <div className="border-b-[0.5px] border-lightGray" />
            {/* Premium content */}
            <div className="pt-10">
              {premiumContent.map((ele, id) => (
                <div key={id}>
                  <div className="flex items-center font-inter font-normal text-[16px] leading-[23px] space-y-2">
                    <img src={blackChecklist} className="w-6 mt-2 pr-2" />
                    {ele.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StandardPremiumBox;
