import React from "react";
import YellowBox from "./YellowBox";

const HowItWorks = () => {
  return (
    <React.Fragment>
      <div className="mb-48 mt-[7rem] sm:mt-12 xl:mt-40 2xl:my-32 xl:pb-36  2xl:pt-32 lg:ml-12 2xl:pl-12">
        <div className="mb-[15rem] sm:mb-[33rem] lg:mb-[5rem] text-center lg:text-left">
          <h1 className="font-druk font-medium text-[30px] sm:text-[48px] leading-[32px] sm:leading-[48px] w-2/5 xl:w-1/5 mx-auto lg:mx-0">
            HOW IT WORKS
          </h1>
          <h2 className="font-inter font-normal text-[16px] leading-[23px] w-3/5 sm:w-1/4 lg:w-1/5 xl:w-72 mx-auto lg:mx-0 my-8">
            Secure and Equitable Sneaker Releases for All.
          </h2>
        </div>
        <YellowBox />
      </div>
    </React.Fragment>
  );
};

export default HowItWorks;
