import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import arrowDown from "../../assets/images/svg/arrow-down.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

import "./footer.scss";

const urlPrefix = process.env.REACT_APP_STRAPI_API;

const footerUrl = `${urlPrefix}/api/footer?populate=*`;
const socialUrl = `${urlPrefix}/api/social-medias?populate=*`;

const Footer = (props) => {
  const [footerLinks, setFooterLinks] = useState([]);
  const [copyrightLinks, setCopyrightLinks] = useState([]);
  const [data, setData] = useState({});
  const [followShow, setFollowShow] = useState(true);
  const [socialLinks, setSocialLinks] = useState([]);
  const [marquee, setMarquee] = useState(" - KEEP IT SAFE");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const handleScrollToTop = (props) => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  };

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(socialUrl);
      const { data } = await response.json();
      setSocialLinks(data);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  useEffect(() => {
    fetch(footerUrl)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response");
        }
        return response.json();
      })

      .then((footer) => {
        setData(footer.data);
        setFooterLinks(footer.data.attributes.footerLinks);
        setCopyrightLinks(footer.data.attributes.copyrightLinks);
      });
    fetchSocialLinks();
  }, []);

  return (
    <React.Fragment>
      <div className={` ${props.theme === "dark" ? "bg-black" : ""} `}>
        <div className="relative flex overflow-x-hidden ">
          <div className="py-12 animate-marquee whitespace-nowrap  mytext--stroke">
            <span className=" text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk">
              {" "}
              {marquee}
            </span>
            <span className="text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk ">
              {marquee}
            </span>
            <span className="text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk">
              {marquee}
            </span>
          </div>

          <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap font-druk mytext--stroke">
            <span className="text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk">
              {marquee}
            </span>
            <span className="text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk">
              {marquee}
            </span>
            <span className="text-[30px] leading-[40px] sm:text-[52px] sm:leading-[55px] font-medium font-druk">
              {marquee}
            </span>
          </div>
        </div>
        {/* {errorMessage && (
        <p className="error color-red bg-white"> {errorMessage} </p>
      )} */}
        <footer
          className={`${
            props.theme === "dark" ? "bg-black" : ""
          } grid grid-cols-1 sm:grid-cols-8 gap-8 text-white`}
        >
          <div className=" pt-6 ml-4 sm:ml-12  2xl:ml-36 col-span-2 xl:col-span-4">
            {/* SOLESAFE LOGO  */}
            <Link to="/">
              <img
                className="cursor-pointer pb-6 xl:w-40"
                src={`${urlPrefix}${data?.attributes?.logo?.data?.attributes?.url}`}
                onClick={handleScrollToTop}
              />
            </Link>
            <p className="w-1/2 pb-6 font-druk font-medium text-[48px] mobileMax:text-[36px] sm-text-[55px] leading-[55px] mobileMax:leading-[45px]">
              {data?.attributes?.DoSneakers}
            </p>
            {/* BUTTON  */}
            <div className="cursor-pointer flex space-x-2 justify-start my-4">
              <a
                className="flex "
                href={data?.attributes?.appStore?.href}
                target="_blank"
              >
                <img
                  className="cursor-pointer"
                  src={`${urlPrefix}${data?.attributes?.appleIcon?.data?.attributes?.url}`}
                />
              </a>
              <a
                className="flex w-[9.5rem] "
                href={data?.attributes?.playStore?.href}
                target="_blank"
              >
                {" "}
                <img
                  className=""
                  src={`${urlPrefix}${data?.attributes?.playstoreIcon?.data?.attributes?.url}`}
                />{" "}
              </a>
            </div>
          </div>

          <div className="flex justify-between sm:mx-12 xl:mx-0 xl:justify-center sm:col-start-5 sm:sm:col-span-4 xl:col-start-5 mt-10 py-4 gap-14 xl:pl-9 xl:gap-32 2xl:gap-[10rem] xl:w-5/6 2xl:w-[48rem]">
            {/* COLLAPSIBLE LINKS*/}
            <div className=" lg:col-span-2 ml-8 sm:ml-0">
              <p className="flex font-bold pb-4">
                LINKS
                <img
                  className="cursor-pointer w-6 ml-4 "
                  src={arrowDown}
                  alt="collapsible arrow"
                />
              </p>
              {footerLinks?.map((footerLink) => {
                return (
                  <ul className="font-normal" key={footerLink?.id}>
                    <li className="my-3">
                      <a href={footerLink?.href}>{footerLink?.label}</a>
                    </li>
                  </ul>
                );
              })}
            </div>

            <div className="lg:col-span-2 2xl:pl-4">
              <p className="flex font-bold pb-4">
                FOLLOW
                <img
                  className="cursor-pointer w-6 ml-4 "
                  src={arrowDown}
                  alt="collapsible arrow"
                  onClick={() => setFollowShow(!followShow)}
                />
              </p>
              {socialLinks?.map((socialLink) => {
                return (
                  <a
                    key={socialLink?.id}
                    href={socialLink?.attributes?.link}
                    target="_blank"
                  >
                    <ul className="font-normal flex my-3" key={socialLink?.id}>
                      <img
                        className={`mx-2 ${
                          socialLink?.attributes?.title === "Twitter"
                            ? "w-4 ml-3"
                            : ""
                        }`}
                        src={`${urlPrefix}${socialLink?.attributes?.logo?.data?.attributes?.url}`}
                      />
                      <li
                        className={`mx-2 ${
                          socialLink?.attributes?.title === "Twitter"
                            ? "pl-1"
                            : ""
                        }`}
                      >
                        {socialLink?.attributes?.title === "Twitter"
                          ? "X (Twitter)"
                          : socialLink?.attributes?.title}
                      </li>
                    </ul>
                  </a>
                );
              })}
            </div>
          </div>
          {/* COPYRIGHT SECTION  */}

          <div className="col-start-1 sm:col-span-8  sm:flex font-inter font-normal text-xs">
            {/* 2022 copyright */}
            <div className="w-100 sm:w-72  xl:w-5/6 2xl:ml-24 text-center xl:text-left xl:pl-12 sm:col-span-2 xl:col-start-1 mb-10 ">
              {" "}
              {data?.attributes?.copyright}
            </div>
            {/* Privacy policy & terms of use*/}
            <div className="flex justify-around sm:w-4/5 xl:w-[58rem] 2xl:w-[73rem] xl:col-span-6 2xl:mr-20">
              {copyrightLinks?.map((copyrightLink) => {
                return (
                  <ul className="font-normal ml-[3rem]" key={copyrightLink?.id}>
                    <li className="">
                      <a href={copyrightLink?.href}>{copyrightLink?.label}</a>
                    </li>
                  </ul>
                );
              })}

              <div className=" hidden sm:block cursor-pointer scroll-smooth rounded-full xl:pl-6 2xl:pl-0">
                <img
                  src={scrollArrow}
                  className="scroll-smooth hidden sm:block rounded-full bg-white p-1"
                  direction="up"
                  onClick={handleScrollToTop}
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
