//external imports
import React, { useState, useEffect } from "react";
//internal imports
import checklist from "../../assets/images/svg/checklist.svg";
import crosslist from "../../assets/images/svg/crosslist.svg";
import blackChecklist from "../../assets/images/svg/black-checklist.svg";
import insureChecklist from "../../assets/images/svg/insure-checklist.svg";
import insureCross from "../../assets/images/svg/insure-cross.svg";
import insureShoeWhitebox from "../../assets/images/png/insure_shoe_whitebox.png";
import insureShoeBlue from "../../assets/images/png/insure-shoe-blue.png";
import solesafeWhiteLogo from "../../assets/images/svg/solesafe-white-logo.svg";
import AppStores from "../AppStores";

const WhiteBox = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const crumbles = [
    {
      id: 1,
      content: ["Must add at the time of coverage"],
    },
    {
      id: 2,
      content: ["Sneaker is 10 years old or less at the time of coverage"],
    },
    {
      id: 3,
      content: [
        "Cannot already be experiencing hydrolysis, deteriorated, or falling apart",
      ],
    },
  ];

  const whySolesafe = [
    {
      id: 1,
      imageType: "cross",
      features: "Market Valuation",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 2,
      imageType: "cross",
      features: "Low Deductible ",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 3,
      imageType: "cross",
      features: "Crumble Coverage™ ",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 4,
      features: "Wear Your Shoes ",
      imageType: "cross",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 5,
      imageType: "cross",
      features: "SoleSafe ID™ / Unique ID ",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 6,
      imageType: "check",
      features: "Theft ",
      solesafe: <img src={insureChecklist} />,
      homeowners: "Limited",
    },
    {
      id: 7,
      imageType: "check",
      features: "Pet ",
      solesafe: <img src={insureChecklist} />,
      homeowners: "Limited",
    },
    {
      id: 8,
      imageType: "check",
      features: "Shipping / In-Transit",
      solesafe: <img src={insureChecklist} />,
      homeowners: "Limited",
    },
    {
      id: 9,
      imageType: "check",
      features: "Accidental Damage (Spills/Stains)",
      solesafe: <img src={insureChecklist} />,
      homeowners: "Limited",
    },
    {
      id: 10,
      imageType: "cross",
      features: "Flood",
      solesafe: "Limited",
      homeowners: <img src={insureCross} />,
    },
    {
      id: 11,
      imageType: "check",
      features: "Fire",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureChecklist} />,
    },
    {
      id: 12,
      imageType: "cross",
      features: "Travel / Checked Baggage",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 13,
      imageType: "cross",
      features: "Choose Coverage Limit",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 14,
      imageType: "cross",
      features: "Multiple Coverage Options",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 15,
      imageType: "check",
      features: "Coinsurance Clause",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureChecklist} />,
    },
    {
      id: 16,
      imageType: "cross",
      features: "Restoration Services",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 17,
      imageType: "cross",
      features: "Authentication",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },

    {
      id: 18,
      imageType: "cross",
      features: "Community",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
    {
      id: 19,
      imageType: "cross",
      features: "Salvage",
      solesafe: <img src={insureChecklist} />,
      homeowners: <img src={insureCross} />,
    },
  ];

  const lockPlan = [
    { label: "Fire / Smoke", check: true },
    { label: "Wind / Hail", check: true },
    { label: "Accidental Water", check: true },
    { label: "Fungi / Mold", check: true },
    { label: "Accidental Damage (Scuff, Spills, etc.)", check: true },
    { label: "Pet", check: true },
    { label: "Theft / Vandalism / Mischief", check: false },
    { label: "Sewer Back-Up", check: false },
    { label: "Checked Baggage", check: false },
    {
      label: (
        <div>
          <p>Limited Flood*</p>
          <p className="text-xs">Note: Non-Flood Zones</p>
        </div>
      ),
      check: false,
    },
  ];

  const safePlan = [
    { label: "Fire / Smoke", check: true },
    { label: "Wind / Hail", check: true },
    { label: "Accidental Water", check: true },
    { label: "Fungi / Mold", check: true },
    { label: "Accidental Damage (Scuff, Spills, etc.)", check: true },
    { label: "Pet", check: true },
    { label: "Theft / Vandalism / Mischief", check: true },
    { label: "Sewer Back-Up", check: true },
    { label: "Checked Baggage", check: true },
    {
      label: (
        <div>
          <p>Limited Flood*</p>
          <p className="text-xs">Note: Non-Flood Zones</p>
        </div>
      ),
      check: true,
    },
    {
      label: (
        <div>
          <p>In-Transit</p>
          <p className="text-xs">(Lost, Stolen, Damaged Packages)</p>
        </div>
      ),
      check: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="relative mt-[10rem]  bg-white rounded-[3rem] sm:rounded-[5rem] grid sm:grid sm:grid-cols-12  gap-4 px-4 xl:px-40">
        {/* RED BOX  */}
        <div className="mt-[-16rem] sm:mt-[-14rem] bg-track-red border-2 border-white col-span-12 sm:col-span-6 py-8 sm:py-32">
          <div className="font-druk text-[52px] leading-[55px] font-medium text-center text--stroke">
            LOCK
          </div>

          <div className="font-inter text-[16px] leading-[24px] font-normal grid grid-cols-12 justify-items-center">
            <div className="col-start-3 col-span-8">
              <p className="font-inter text-[20px] leading-[26px] font-bold my-8 2xl:my-[6rem] ">
                The perfect plan to protect the first pairs in your collection.
              </p>
              <p className="text-[16px] leading-[24px] col-span-7  ">
                What’s covered with the Lock:
              </p>
              <div className="col-span-8">
                {lockPlan.map((item, index) => (
                  <ul key={index}>
                    <li className="flex gap-4 py-2">
                      {" "}
                      <img
                        src={item.check ? checklist : crosslist}
                        alt="check or cross mark"
                      />
                      {item.label}
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* BLUE BOX  */}
        <div className="sm:mt-[-14rem] bg-blue border-2 border-white py-8  col-span-12 sm:col-span-6 sm:py-32">
          <div className="font-druk text-[52px] leading-[55px] font-medium text-center text--stroke">
            SAFE
          </div>

          <div className="font-inter text-[16px] leading-[24px] font-normal grid grid-cols-12 justify-items-center">
            <div className=" col-start-3 col-span-8">
              <p className="font-inter text-[20px] leading-[26px] font-bold  my-8  2xl:my-[6rem] ">
                Maximum protection and complete peace of mind for your
                collection.
              </p>
              <p className="text-[16px] leading-[24px] col-start-1 col-span-10 ">
                What’s covered with the Safe:
              </p>
              <div className="col-span-8">
                {safePlan.map((item, index) => (
                  <ul key={index}>
                    <li className="flex gap-4 py-2">
                      {" "}
                      <img
                        src={item.check ? checklist : crosslist}
                        alt="check or cross mark"
                      />
                      {item.label}
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* YELLOW BOX  */}
        <div className="bg-yellow border-2 border-black py-8 sm:my-12 col-span-12 ">
          <div className="sm:grid sm:grid-rows-2 sm:grid-flow-col ">
            <div className="sm:row-span-2 col-span-1 2xl:pt-[6rem]">
              <div className=" font-inter text-black text-[16px] leading-[24px] font-normal grid grid-cols-12 ">
                <div className="sm:col-start-2 col-span-12">
                  <p className="sm:ml-8 font-druk text-[30px] sm:text-[48px] leading-[40px] sm:leading-[55px] font-medium text-center sm:text-left  text--stroke">
                    CRUMBLE COVERAGE™ IS HERE!
                  </p>
                  <p className="ml-8 font-inter text-black text-[16px] sm:text-[20px] leading-[24px] sm:leading-[26px] font-bold xl:text-left my-10 ">
                    No one wants their sneakers to experience crumbling, but
                    this is part of the game. We thought about how to cover your
                    sneakers today that may crumble in the future.
                  </p>
                  <p className=" ml-8 col-start-2 col-span-10 my-10">
                    Eligibility Requirements:
                  </p>
                  <div className="ml-8 col-span-8">
                    {crumbles.map((crumble) => {
                      return (
                        <ul key={crumble.id}>
                          <li className="flex gap-4 items-center py-2 font-inter text-[16px] leading-[24px] ">
                            {" "}
                            <img src={blackChecklist} className="" />
                            {crumble.content}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <img
              src={insureShoeWhitebox}
              className="sm:row-span-3 col-span-1"
            />
          </div>
        </div>
        {/* WHY SOLESAFE SECTION */}
        <div className="my-16 col-span-12">
          <div className="mx-12">
            <p className="mx-2 pb-6 font-druk text-black text-center text-[30px]  sm:text-[52px] leading-[40px] sm:leading-[55px] font-medium  col-start-2 col-span-4  ">
              {" "}
              WHY SOLESAFE?
              <p className="font-inter font-normal text-[16px] leading-[24px] text-center text-black mt-12  mx-auto md:w-96">
                A different approach, using a new method of underwriting
                automation for sneaker insurance™
              </p>
            </p>
          </div>
        </div>
        {/* THREE COLUMNS */}
        <div className=" flex flex-row text-center gap-4 col-span-12 xl:mx-20">
          <div className="basis-2/4  py-8 bg-black font-inter font-bold text-[12px] sm:text-[16px] leading-[18px] sm:leading-[24px]">
            Features
          </div>
          <div className="basis-1/4  bg-black flex justify-center items-center ">
            <img src={solesafeWhiteLogo} className="" />
          </div>

          <div className="basis-1/4 py-8 bg-black font-inter font-bold text-[12px] sm:text-[16px] leading-[18px] sm:leading-[24px]">
            Homeowners
          </div>
        </div>
        <div className="grid  grid-cols-1 gap-4 col-span-12 xl:mx-20">
          {whySolesafe.map((whySolesafe) => {
            return (
              <div
                key={whySolesafe.id}
                className="font-inter font-normal text-[12px] sm:text-[16px] leading-[18px] sm:leading-[24px] flex flex-cols-3 col-span-12 gap-4 justify-center items-center text-center"
              >
                <div className="bg-platinum text-black w-2/4 py-2">
                  {" "}
                  {whySolesafe.features}
                </div>
                <div className="bg-blue text-black place-self-center w-1/4 flex justify-center py-2 ">
                  {whySolesafe.solesafe}
                </div>
                <div
                  className={`text-black w-1/4 flex justify-center py-2 ${
                    whySolesafe.imageType === "cross"
                      ? "bg-platinum"
                      : "bg-blue"
                  }`}
                >
                  {whySolesafe.homeowners}
                </div>
              </div>
            );
          })}
        </div>

        {/* Download Button  */}
        <div className="rounded-full font-inter font-bold text-[16px] leading-[19.36px]  flex col-span-12 sm:col-start-4 sm:col-span-6 gap-2  mb-60 justify-center py-3">
          <AppStores />
        </div>
        {/* Blue Shoe */}
        <div className="absolute top-[136%] w-80 sm:w-100 sm:pl-0 left-[50%] -translate-x-2/4	">
          <img
            src={insureShoeBlue}
            style={{ transform: `translateY(-${offsetY * 0.16}px)` }}
            className=" object-scale-down  bg-fixed"
            alt="Insurance shoe"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhiteBox;
