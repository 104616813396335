import React, { useState, useEffect, useRef } from "react";

import "./carousel.scss";

const items = [
  {
    number: "01",
    label: "Create Risk Profile",
    description: (
      <>
        <p className="mb-6">
          Each user is asked to create an initial risk profile that gives us
          information on who you are, where you store sneakers, what protective
          safeguards you have in place, and how we can best work together in the
          future to protect their collection.{" "}
        </p>
        <p>
          We <span className="font-bold">don't use credit score</span> to assess
          risk, but do ask about prior loss history. wants to offer everyone
          coverage, but has to make sure we create a balanced partnership along
          the way.
        </p>
      </>
    ),
  },
  {
    number: "02",
    label: "Inventory Sneakers",
    description:
      "SoleSafe™ makes it very simple and easy to search our database of over 160,000 sneakers to build their digital collection.",
  },
  {
    number: "03",
    label: "Select Coverage",
    description:
      "Once users have added sneakers to their collections, they can select which coverages they want to apply one or all sneakers in minutes..",
  },
  {
    number: "04",
    label: "Checkout",
    description: (
      <>
        <p className="mb-6">
          Throughout the experience we are automatically calculating the
          estimated payment (premium) based on the coverage value.{" "}
        </p>
        <p>
          At checkout, the user selects their payment method and checks out.{" "}
        </p>
      </>
    ),
  },
  {
    number: "05",
    label: "Take Photos",
    description:
      "While photos for each pair of insured sneakers is required, users can decide when to provide those within a 30-day period. If required photos are not provided, we would cancel coverage for that specific sneaker.",
  },
];

const totalItems = items.length;

const margin = 25;

const HowItWorksCarousel = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [transformDim, setTransformDim] = useState(0);
  const [initialTransformDone, setInitialTransformDone] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const animateRef = useRef(null);
  const itemRef = useRef(null);

  const increaseCounter = () => {
    if (currentStep < items.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const decreaseCounter = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (animateRef && itemRef && windowWidth > 768) {
      const width = itemRef.current.getBoundingClientRect().width;
      setItemWidth(width);
      const initialTransform = windowWidth / 2 - width / 2 - margin;
      setTransformDim(initialTransform);
      animateRef.current.style.transform = `translateX(${initialTransform}px)`;
      setInitialTransformDone(true);
    }
    if (windowWidth < 768) {
      animateRef.current.style.transform = `translateX(${0}px)`;
    }
  }, [windowWidth]);

  useEffect(() => {
    if (transformDim && initialTransformDone) {
      const transform =
        transformDim - currentStep * itemWidth - currentStep * margin * 2;
      animateRef.current.style.transform = `translateX(${transform}px)`;
    }
  }, [currentStep, transformDim, itemWidth]);

  const calculateWindowWidth = () => {
    setInitialTransformDone(false);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // need debouncing
    window.addEventListener("resize", calculateWindowWidth, true);

    return () =>
      window.removeEventListener("resize", calculateWindowWidth, true);
  });

  return (
    <>
      <div className="how-it-works">
        <div className="how-it-works__buttons float-right -mt-[10rem] hidden sm:block">
          {/* Slider Left Button  */}
          <button
            className="how-it-works__buttons__button w-32 "
            onClick={decreaseCounter}
          >
            <svg
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="32.7868"
                cy="32.7868"
                r="32.7869"
                transform="rotate(-180 32.7868 32.7868)"
                fill="white"
              />
              <path
                d="M45.6837 30.7598L45.6837 34.0753L25.7905 34.0753L34.9082 43.193L32.5542 45.5471L19.4247 32.4176L32.5542 19.2881L34.9083 21.6421L25.7905 30.7598L45.6837 30.7598Z"
                fill="black"
              />
            </svg>
          </button>
          {/* Slider Right Button  */}
          <button
            className="how-it-works__buttons__button w-32"
            onClick={increaseCounter}
          >
            <svg
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="33.2132" cy="32.7869" r="32.7869" fill="white" />
              <path
                d="M20.3204 34.8139L20.3204 31.4984L40.2136 31.4984L31.0959 22.3807L33.4499 20.0267L46.5794 33.1562L33.4499 46.2856L31.0959 43.9316L40.2136 34.8139L20.3204 34.8139Z"
                fill="black"
              />
            </svg>
          </button>
        </div>

        <div className="how-it-works-alt__items">
          <div
            className="how-it-works-alt__items__animate "
            data-translate={currentStep}
            ref={animateRef}
          >
            {items.map((item, index) => {
              if (item) {
                return (
                  <div
                    className={`how-it-works-alt__items__item ${
                      index === currentStep
                        ? "how-it-works-alt__items__item--active"
                        : ""
                    }`}
                    key={index}
                    ref={itemRef}
                  >
                    <div className="grid grid-cols-8 py-4 font-druk font-bold sm:font-normal text-[22px] leading-[28px] sm:text-[30px] sm:leading-[40px] text-center">
                      <h1 className="bg-yellow py-8 sm:py-4 col-span-2">
                        {item.number}
                      </h1>
                      <h3 className="col-span-6 flex items-center py-4 pl-4 sm:pl-0 bg-white text-left sm:text-center ">
                        {item.label}
                      </h3>
                    </div>

                    <div className="col-start-2  py-10 lg:h-[264px] h-[361px] col-span-6 bg-white font-inter text-[16px] leading-[24px] px-8 text-left flex flex-col justify-center">
                      {item.description}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="how-it-works-alt__items__item how-it-works-alt__items__item--hide"
                    key={index}
                  >
                    <h3>{}</h3>
                    <p>{}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksCarousel;
