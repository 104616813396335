//external imports
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//internal imports
import Header from "../../components/Header";
import Simulator from "../../components/Simulator";
import Footer from "../../components/Footer";
import AfterLoginModal from "../../components/Register/AfterLoginModal";
import RealSneaker from "../../components/ForSneakerheads/RealSneaker";
import ExploreCoverage from "../../components/ForSneakerheads/ExploreCoverage";
import WhiteBox from "../../components/ForSneakerheads/WhiteBox";
import Features from "../../components/ForSneakerheads/Features";
import HowItWorks from "../../components/ForSneakerheads/HowItWorks";
import ClaimsMadeSimple from "../../components/ForSneakerheads/ClaimsMadeSimple";
import InsuranceFaq from "../../components/ForSneakerheads/InsuranceFaq";

import heroMobile from "../../assets/images/png/hero_mobile.png";
import heroTablet from "../../assets/images/png/hero_tablet.png";
import heroDesktop1440 from "../../assets/images/png/hero_desktop1440.png";
import heroDesktop1920 from "../../assets/images/png/hero_desktop1920.png";
import arrowRight from "../../assets/images/svg/arrow-right.svg";
import "./home.scss";

import CollectModules from "../../components/ForSneakerheads/CollectModules";
import Insure from "../../components/ForSneakerheads/Insure";

const ForSneakerheads = () => {
  const navigate = useNavigate();

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  const { showLoginModal, isLoggedIn } = useSelector((store) => store.user);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`body {
                background-color: black;
            }
        .header__menu-icon__bar {
            background-color: white
            }`}
        </style>
      </Helmet>

      <div className="home ">
        <Header logoColor="white" barColor="white" theme="dark" />
        <div className="home__section sm:mt-10 xl:mt-14">
          <div
            className="flex flex-col justify-start items-center "
            style={{ transform: `translateY(-${offsetY * 0.6}px)` }}
          >
            <picture className="hero__banner">
              <div
                className="Parallax__background-triangles"
                style={{ transform: `translateY(${-offsetY * 0.8}px)` }}
              />
              <source
                srcSet={heroDesktop1920}
                media="(min-width: 1920px)"
                alt="hero banner"
                className="animate-bounce"
              />
              <source
                srcSet={heroDesktop1440}
                media="(min-width: 1024px)"
                alt="hero banner"
              />

              <source
                srcSet={heroTablet}
                media="(min-width: 641px)"
                alt="hero banner"
                className="animate-bounce"
              />
              <img src={heroMobile} alt="hero banner" className="" />
            </picture>
            <div className="flex flex-col justify-center items-center hero__content Parallax">
              <div className="w-full hero__main flex flex-col mb-10">
                <h2 className="font-medium text-center hero__main__text hero__main__text--stroke">
                  COLLECT.
                </h2>
                <h2 className="font-medium text-center hero__main__text hero__main__text--stroke">
                  CONNECT.
                </h2>
                <h2 className="font-medium text-center hero__main__text hero__main__text--stroke">
                  TRACK.
                </h2>
                <h2 className="font-medium text-center hero__main__text hero__main__text--filled">
                  INSURE.
                </h2>
              </div>

              <p
                className="text-center desktop1:!text-[24px] desktop2:!text-[40px] desktop1:!w-[646px] desktop2:!w-[681px]
               mb-5 hero__text hero__description Parallax__background__triangles  w-auto xl:w-2/3 2xl:w-1/2
               desktop2:!leading-[40px]"
              >
                Everything you need in one place to manage your sneaker
                collection.
              </p>
              <div className="sm:flex justify-center items-center z-50">
                <a
                  className="cursor-pointer flex justify-center items-center mx-auto sm:mx-0 sm:my-0 bg-lightBlue text-white rounded-full font-inter font-bold text-[16px] leading-[19.36px] px-6 py-3"
                  href="https://apps.shopify.com/solesafe"
                  target="_blank"
                >
                  DOWNLOAD APP TO GET STARTED
                  <div className="cursor-pointer ml-2">
                    <img src={arrowRight} className="scroll-smooth" />
                  </div>
                </a>
              </div>
              <div
                className="Parallax__background-triangles left-[38vw] hidden sm:flex"
                style={{ transform: `translateY(${offsetY * 0.7}px)` }}
              />
              <div
                className="Parallax__background mt-[36rem] 2xl:mt-[48rem]"
                style={{ transform: `translateY(${offsetY * 0.4}px)` }}
              />
              {/* <DownloadButton /> */}
            </div>
            <div
              className="Parallax__background-traingles"
              style={{ transform: `translateY(${offsetY * 0.5}px)` }}
            />
          </div>
        </div>
        {/* Collect Modules */}
        <div>
          <CollectModules />
        </div>
        {/* Real Sneaker */}
        <div>
          <RealSneaker />
        </div>
        {/* Simulator */}
        <div className="home__section flex flex-col justify-center items-center ">
          <Simulator />
        </div>
        {/* Explore Coverage */}
        <ExploreCoverage />
        {/* White Box  */}
        <WhiteBox />
        {/* Features */}
        <div className="pb-56">
          <Features />
        </div>
        {/* How It Works */}
        <HowItWorks />
        {/* Claims Made Simple */}
        <div className="pt-60 pb-40 xl:px-14">
          <ClaimsMadeSimple />
        </div>
        {/* Insurance FAQ  */}
        <InsuranceFaq />
        {/* Insure What you Love  */}
        <div className="">
          <Insure />
        </div>
        {/* Footer  */}
        <div className="mt-[12rem]">
          <Footer />
        </div>
      </div>
      <div className="bg-blue">
        {showLoginModal && isLoggedIn && <AfterLoginModal />}
      </div>
    </>
  );
};

export default ForSneakerheads;
