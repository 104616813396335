import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../ForSneakerheads/insurance-faqs.scss";
import "tw-elements";
import Customers from "./Customers";
import Retailers from "./Retailers";
import scrollArrow from "../../../assets/images/svg/scroll-arrow.svg";

const RaffleFaq = () => {
  const navigate = useNavigate();
  const [showRetailersFaq, setShowRetailersFaq] = useState(true);
  const [showCustomersFaq, setShowCustomersFaq] = useState(false);

  return (
    <React.Fragment>
      <div className="px-4 sm:w-3/4 xl:w-2/3 2xl:w-1/2 text-center mx-auto font-druk font-medium text-[30px] leading-[32px] sm:text-[48px] sm:leading-[48px]">
        FREQUENTLY ASKED QUESTIONS
        <div className="font-inter font-normal text-[16px] leading-[23px] sm:w-2/4 xl:w-2/5 2xl:w-1/2 mt-5 mb-12 mx-auto">
          Explore our frequently asked questions for quick answers to common
          questions.
        </div>
      </div>
      {/* Tab Nav  */}
      <div className="grid grid-cols-12 font-druk font-medium text-[15px] leading-[20px] sm:mb-12 sm:mx-28 xl:mx-80 2xl:mx-[30rem]">
        <div className="col-span-6">
          <h1
            className={`${
              showRetailersFaq ? "text-white" : "text-lightGray"
            } cursor-pointer text-center`}
            onClick={() => {
              setShowRetailersFaq(true);
              setShowCustomersFaq(false);
            }}
          >
            RETAILERS
          </h1>
          <div
            className={`${
              showRetailersFaq ? "border-blue" : "border-lightGray"
            } cursor-pointer border-b-[3px] mt-4 ml-6 `}
          />
        </div>
        <div className="col-span-6">
          <h1
            className={`${
              showCustomersFaq ? "text-white" : "text-lightGray"
            } cursor-pointer text-center`}
            onClick={() => {
              setShowCustomersFaq(true);
              setShowRetailersFaq(false);
            }}
          >
            CUSTOMERS
          </h1>
          <div
            className={`${
              showCustomersFaq ? "border-blue" : "border-lightGray"
            } cursor-pointer border-b-[3px]  mt-4 mr-6`}
          />
        </div>
      </div>
      {/* hot fix <div className="border-b-2 border-green-200" /> */}
      {/* Tab content  */}
      {showRetailersFaq && <Retailers />}
      {showCustomersFaq && <Customers />}

      {/* Contact us  */}
      <div className="mb-60 sm:flex justify-center items-center">
        <button
          className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
          onClick={() => {
            navigate("/contact", { state: "partner" });
          }}
        >
          CONTACT US
          <div className="cursor-pointer rotate-90 ml-4">
            <img src={scrollArrow} className="scroll-smooth" />
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default RaffleFaq;
