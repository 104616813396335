//external imports
import React from "react";
import { Helmet } from "react-helmet";
//internal imports
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CommunityFaq from "../../components/CommunityManagement/CommunityFaq";
import HowItWorks from "../../components/CommunityManagement/HowItWorks";
import BenefitsAndFeatures from "../../components/CommunityManagement/BenefitsAndFeatures";
import YourSneaker from "../../components/CommunityManagement/YourSneaker";
import ProductFeatures from "../../components/CommunityManagement/ProductFeatures/ProductFeatures";

const CommunityManagement = () => {
  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">
          {`body {
                    background-color: black;
                }
            .header__menu-icon__bar {
                background-color: white
                }`}
        </style>
      </Helmet>

      <Header logoColor="white" barColor="white" theme="dark" />
      <YourSneaker />
      <ProductFeatures />
      <HowItWorks />
      <BenefitsAndFeatures />
      <CommunityFaq />
      <div className="mt-[12rem] mb-10">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default CommunityManagement;
