import React, { useEffect, useRef } from "react";
import PartnerBenefit1 from "../../assets/images/svg/partner-benefit-1.svg";
import PartnerBenefit2 from "../../assets/images/svg/partner-benefit-2.svg";
import PartnerBenefit3 from "../../assets/images/svg/partner-benefit-3.svg";
import PartnerBenefit4 from "../../assets/images/svg/partner-benefit-4.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import { useNavigate } from "react-router-dom";

const Benefits = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  const BenefitBoxContent = [
    {
      id: 1,
      heading: "Lower Store Risk",
      description:
        "Insure lost, stolen, or damaged packages, freeing you and your business from responsibility.",

      image: <img alt="" src={PartnerBenefit1} />,
    },

    {
      id: 2,
      heading: "Increase Revenue",
      description:
        "Work with our team to find the perfect revenue split and add to your bottom line.",
      image: <img alt="" src={PartnerBenefit2} />,
    },
    {
      id: 3,
      heading: "Best Customer Experience",
      description:
        "Give your customers peace of mind when buying sneakers or other products.",
      image: <img alt="" src={PartnerBenefit3} />,
    },
    {
      id: 4,
      heading: (
        <div>
          Quickly Resolve
          <br className="hidden 2xl:block" /> Claims
        </div>
      ),
      description:
        "Fast and easy claims resolution to get money back into your customers’ pockets.",
      image: <img alt="" src={PartnerBenefit4} />,
    },
  ];

  const handleScroll = (evt) => {
    evt.preventDefault();
    scrollContainerRef.current.scrollLeft += evt.deltaY;
  };

  useEffect(() => {
    const windowInnerWidth = window.innerWidth;
    if (scrollContainerRef.current && windowInnerWidth < 1400) {
      scrollContainerRef.current.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }
    return () => {
      if (scrollContainerRef.current && windowInnerWidth < 1400) {
        scrollContainerRef.current.removeEventListener("wheel", handleScroll, {
          passive: false,
        });
      }
    };
  }, []);

  return (
    <React.Fragment>
      <div className="py-24 2xl:pl-12">
        <div className="text-center lg:text-left px-12">
          <h1 className="font-druk font-medium text-[30px] sm:text-[48px]">
            BENEFITS
          </h1>
          <h2 className="font-inter font-normal text-[16px] my-4">
            Insurance Designed to Enhance Revenue and Lower Risk.
          </h2>
        </div>

        <div className="mt-20 pl-8 sm:pl-12 mr-8 sm:mr-12 2xl:mr-24">
          <div
            className="w-auto flex justify-between gap-6 overflow-x-auto category-scroller"
            ref={scrollContainerRef}
          >
            {BenefitBoxContent.map((ele, id) => (
              <div
                key={id}
                className="w-auto desktop1:w-[25%] h-[28rem] border border-white rounded-xl px-8 py-5"
              >
                <div className="w-[226px] desktop2:w-[auto]">{ele.image}</div>
                <div>
                  <h1 className="font-druk font-bold text-[22px] my-4">
                    {ele.heading}
                  </h1>
                  <h2 className="font-inter font-normal text-[16px]">
                    {ele.description}
                  </h2>
                </div>
              </div>
            ))}
          </div>

          <div className="cursor-pointer mt-20 sm:flex justify-center lg:justify-start items-center">
            <a
             href="https://apps.shopify.com/solesafe"
              className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
            >
              INSTALL APP
              <div className="cursor-pointer rotate-90 ml-6">
                <img alt="" src={scrollArrow} className="scroll-smooth" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Benefits;
