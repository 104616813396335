import React from "react";
import { useNavigate } from "react-router-dom";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import "../ForSneakerheads/insurance-faqs.scss";

const CommunityFaq = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="text-center px-12 sm:px-0 xl:w-3/5 2xl:w-2/5 mx-auto font-druk font-medium  text-[30px] sm:text-[48px] leading-[32px] sm:leading-[48px] pt-32 sm:pt-40 pb-14 2xl:mt-[3rem]">
        FREQUENTLY ASKED QUESTIONS
      </div>
      <div
        className="accordion mx-6 text-white rounded-xl space-y-4 2xl:mx-[10rem]"
        id="accordionExample"
      >
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
             
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              WHAT IS SOLESAFE™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between text-[16px] leading-[23px]">
              SoleSafe™ is an insurance and community management platform for
              sneakers.
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwo">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              DOES IT COST MONEY TO START OR CREATE A COMMUNITY?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between text-[16px] leading-[23px]">
              No - it is free! Just need to schedule a demo with our team to
              make sure we get things right for you.
            </div>
          </div>
        </div>

        {/* 3 rd  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThree">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
       
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              WHAT'S ALL INCLUDED?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[23px]">
              Check out our comparison chart to learn more.
            </div>
          </div>
        </div>
        {/* 4th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFour">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              WHO WOULD WANT TO START A COMMUNITY?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[23px]">
              SoleSafe™ is the best fit for new or existing community groups
              focused on sneakers to experience the full value of the platform.
            </div>
          </div>
        </div>
        {/* 5th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFive">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              WHAT ABOUT INDIVIDUAL INFLUENCERS?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[23px]">
              If you believe you have an active, engaged following it likely
              will work well to have your own community in SoleSafe™. Let’s talk
              about the best way to get started!
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[4rem] sm:flex justify-center items-center">
        <button
          className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
          type="submit"
          onClick={() => {
            navigate("/contact", { state: "partner" });
          }}
        >
          CONTACT US
          <div className="cursor-pointer rotate-90 ml-6">
            <img src={scrollArrow} className="scroll-smooth" />
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default CommunityFaq;
