//external imports
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "tw-elements";
//internal imports
import AppStores from "../AppStores";
import { hideLoginModal } from "../../reducers/userSlice";
import afterLogin from "../../assets/images/png/after-login.png";

function AfterLoginModal({ setOpenModal }) {
  const [hideImage, setHideImage] = useState(false);
  const modalRef = useRef(null);

  const dispatch = useDispatch();

  const shouldHideImage = () => {
    if (modalRef.current) {
      setHideImage(
        modalRef.current.scrollHeight >
          modalRef.current.getBoundingClientRect().height
      );
    }
  };

  useEffect(() => {
    // need debouncing
    window.addEventListener("resize", shouldHideImage, true);

    return () => window.removeEventListener("resize", shouldHideImage, true);
  }, []);

  return (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 w-screen h-screen bg-black/[0.64] z-[9999]"
      ref={modalRef}
      onClick={() => dispatch(hideLoginModal())}
    >
      <div
        className="flex flex-col xl:flex-row bg-white absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[85%] md:w-[75%] px-[3%] py-[3%] gap-x-[50px]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* col 1 */}
        <div
          className={`flex justify-center items-center flex-1 mb-[20px] ${
            hideImage ? "hidden" : ""
          }`}
        >
          <img
            src={afterLogin}
            className="sm:h-[266px] xl:h-auto"
            onLoad={shouldHideImage}
          />
        </div>
        {/* col 2 */}
        <div className="flex flex-1 flex-col">
          <p
            className="text-[22px] leading-[28px] 2xl:text-[48px] 2xl:leading-[55px] xl:text-[30px] xl:leading-[40px] font-druk mb-[20px]"
            style={{
              WebkitTextFillColor: "#fff",
              WebkitTextStroke: "2px #000",
            }}
          >
            BUILDING YOUR COLLECTION JUST GOT EASIER{" "}
            <span
              style={{
                WebkitTextFillColor: "#F3FFAC",
              }}
            >
              (AND FASTER)
            </span>
          </p>
          <p className="text-black 2xl:text-[24px] xl:text-[16px] mb-[20px]">
            Don&rsquo;t trip! We know the feeling of having your collection in
            multiple apps. You can now easly upload your collection by adding a
            CSV or directly copying info from the available marketplaces.
          </p>
          <p className="text-black font-bold 2xl:text-[24px] xl:text-[16px] mb-[20px]">
            Our goal is to save you time, so you can focus on what you love.
          </p>
          <Link
            to="/import"
            className="bg-blue w-full p-[12px] rounded-[80px] mb-[20px] text-center"
          >
            START TO IMPORT YOUR ASSETS
          </Link>
          <div className="flex justify-between items-center login-modal-apps mobileMax:flex-col">
            <style>{`.login-modal-apps > div {margin-top: 0;}`}</style>
            <p className="text-black 2xl:text-[24px] xl:text-[16px]">
              Don&rsquo;t have our app? Download it
            </p>
            <AppStores />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AfterLoginModal;
