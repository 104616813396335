import React, { useState } from "react";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";

import "tw-elements";

const SupportFaq = (props) => {
  return (
    <React.Fragment>
      {props.formType === "partner" && (
        <div>
          <div className="font-druk font-medium text-[30px] leading-[40px]  m-4 pl-2 ">
            Frequently Asked Questions
          </div>
          <div
            className="accordion text-black mt-16  mx-6  rounded-xl space-y-4"
            id="accordionExample"
          >
            <div className="accordion-item my-4">
              <h2 className="accordion-header mb-0 " id="headingOne">
                <button
                  className="
    
        collapsed
        relative
        flex
        items-center
        justify-between
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Who is SoleSafe™?
                  <img
                    src={scrollArrow}
                    className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
                  />
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-[30px] mt-4 rounded-xl text-black  bg-blue">
                  <h3 className="font-inter font-normal  text-[16px] leading-[24px] my-4">
                    SoleSafe™ is an asset management platform built for
                    sneakerheads, by sneakerheads.
                  </h3>
                </div>
              </div>
            </div>
            {/* 2nd */}
            <div className="accordion-item my-4">
              <h2 className="accordion-header mb-0 " id="headingTwo">
                <button
                  className="
     
        collapsed
        relative
        flex
        items-center
        justify-between

        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Where are you headquartered?
                  <img
                    src={scrollArrow}
                    className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
                  />
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse "
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-[30px] mt-4 rounded-xl text-black bg-blue">
                  <h3 className="font-inter font-normal  text-[16px] leading-[24px] my-4">
                    Saint Paul, Minnesota
                  </h3>
                </div>
              </div>
            </div>

            {/* 3 rd  */}
            <div className="accordion-item my-4">
              <h2 className="accordion-header mb-0 " id="headingThree">
                <button
                  className="
       
        collapsed
        relative
        flex
        items-center
        justify-between 
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  What products or solutions do you offer?
                  <img
                    src={scrollArrow}
                    className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
                  />
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-[30px] mt-4 rounded-xl text-black  bg-blue">
                  <h3 className="font-inter font-normal  text-[16px] leading-[24px] my-4">
                    Right now, we are very focused on the following:
                    <ul className="accordion-body list-disc p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
                      <li className="">Community </li>
                      <li>Collection Value Tracking </li>
                      <li>Inventory Management </li>
                      <li>Sneaker Insurance™ </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
            {/* 4th  */}
            <div className="accordion-item my-4">
              <h2 className="accordion-header mb-0 " id="headingFour">
                <button
                  className="
        collapsed
        relative
        flex
        items-center
        justify-between
        w-full
        p-[30px]
        font-druk text-[22px] leading-[35px] font-bold text-left
        bg-white
        item
        rounded-xl
        transition
        focus:outline-none
        
      "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  How can we contact you?
                  <img
                    src={scrollArrow}
                    className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
                  />
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-[30px] mt-4 rounded-xl text-black bg-blue">
                  <h3 className="font-inter font-normal  text-[16px] leading-[24px] my-4">
                    Hit us up via email at hello@solesafe.co or send us a
                    message on Instagram.
                  </h3>
                </div>
              </div>
            </div>
            {/* 5th  */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SupportFaq;
