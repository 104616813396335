import React from "react";

function ExploreCoverage() {
  return (
    <React.Fragment>
      <div className="grid grid-cols-11 text-center mt-32 mb-80 sm:mb">
        <div className=" col-span-12 font-druk font-medium text-[30px] sm:text-[48px] lg:text-[52px] leading-[40px] sm:leading-[55px]">
          EXPLORE COVERAGE PLANS
        </div>{" "}
        <div className=" col-start-2 col-span-9 lg:col-start-3 lg:col-span-7  font-inter font-bold leading-[24px] text-[16px] my-10">
          Sneaker Insurance™ is fresh, so we wanted to introduce two plans that
          provide different coverage options based on your needs — Lock and
          Safe.
          <p className="my-10">
            Check them out to see what coverages are available in each plan.
          </p>
          <p>Either way, we hope you still wear your kicks period.</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ExploreCoverage;
