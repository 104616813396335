//external imports
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//internal imports
import realSneaker from "../../assets/images/png/realSneaker.png";
import coin1 from "../../assets/images/png/coin-1.png";
import coin2 from "../../assets/images/png/coin-2.png";
import AppStores from "../AppStores";
import "../../App.scss";

const RealSneaker = () => {
  const location = useLocation();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    // Scroll to the "Real Sneaker" block when the component mounts or the location changes
    if (location.hash === "#real-sneaker") {
      const realSneakerElement = document.getElementById("real-sneaker");
      if (realSneakerElement) {
        realSneakerElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <React.Fragment>
      <div className="grid lg:flex sm:ml-12  mb-40 lg:mb-0 h-[42rem] sm:h-[65rem] lg:h-auto">
        <div className="sm:w-2/3 lg:w-1/3 mx-auto z-10 font-druk text-[30px] leading-[55px] sm:text-[75px] lg:text-[85px] text-center lg:text-left sm:leading-[90px] font-bold">
          <span className="mytext--stroke">REAL </span>SNEAKER INSURANCE™{" "}
          <p className="mytext--stroke ">IS HERE</p>
          <div className="grid place-content-center lg:place-content-start">
            <AppStores />
          </div>
        </div>
        <div className="" id="real-sneaker">
          <img
            src={coin1}
            className="absolute hidden lg:flex right-[30rem] w-24 h-24"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          />
          <div className="mt-[43rem] lg:mt-[23rem]">
            <img
              src={realSneaker}
              style={{ transform: `translateY(-${offsetY * 0.14}px)` }}
            />
          </div>
          <img
            src={coin2}
            className="absolute hidden lg:flex right-44 w-24 h-24"
            style={{ transform: `translateY(${offsetY * 0.1}px)` }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RealSneaker;
