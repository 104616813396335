//external imports
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//internal imports

import coin1 from "../../assets/images/png/coin-1.png";
import "./simulator.scss";

const Simulator = () => {
  const { pathname } = useLocation();

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [sneakersOwned, setSneakersOwned] = useState(12);
  const [sneakersValue, setSneakersValue] = useState(237);
  const [sneakersStorage, setSneakersStorage] = useState("no");

  const maxSneakers = 50;
  const maxSneakersValue = 500;

  const changeValues = (e, setFunc) => {
    setFunc(e.target.value);
  };

  const calForMinCostPerYear = (num1, num2) => {
    if (sneakersStorage === "no")
      // with .02 discount
      return parseFloat(num1 * num2 * 0.02).toFixed(0);
    if (sneakersStorage === "yes")
      // with additional .05 discount on .02
      return parseFloat(num1 * num2 * 0.02 * 0.95).toFixed(0);
  };
  const calForMaxCostPerYear = (num1, num2) => {
    if (sneakersStorage === "no")
      // with additional 0.05 discount on calformincost-no cond.
      return parseFloat(num1 * num2 * 0.05).toFixed(0);
    if (sneakersStorage === "yes")
      // with additional 0.05 discount on calformincost-yes cond.
      return parseFloat(num1 * num2 * 0.05 * 0.95).toFixed(0);
  };

  let minCostPerYear = calForMinCostPerYear(sneakersValue, sneakersOwned);
  let maxCostPerYear = calForMaxCostPerYear(sneakersValue, sneakersOwned);

  return (
    <>
      <div className="simulator-section flex flex-col justify-center items-center sm:mx-10 pb-12 -mt-[12rem]">
        <h5 className="simulator-section__title text-center w-[386px] sm:w-[480px] text-[30px] sm:text-[40px] lg:text-[52px] lg:w-[600px] xl:w-full  leading-[40px] lg:leading-[55px]">
          {pathname === "/sneakerheads"
            ? "Insurance Meets Sneaker Culture"
            : "INSURANCE LOOKS DIFFERENT THESE DAYS"}
        </h5>

        <p className="simulator-section__description text-center w-[386px] sm:w-[75%] mt-12 mx-0">
          {pathname === "/"
            ? " SoleSafe™ is a modern, digital platform delivering an automated underwriting solution specifically designed for sneakers. Our insurance solution provides real-time data to accurately and instantly quote, appraise, and authenticate every sneaker insured on the platform."
            : "SoleSafe™ offers a modern, digital platform delivering a patent-pending insurance solution specifically designed for sneakers."}
        </p>

        <div className=" w-24 2xl:w-48 hidden sm:flex ml-[43rem] lg:ml-[55rem] h-32">
          <img
            src={coin1}
            className=""
            style={{ transform: `translateY(${offsetY * 0.1}px)` }}
          />
        </div>

        <div
          className="hidden sm:flex SimulatorParallax__background sm:right-[39rem] md:right-[52rem]  lg:right-[58rem] xl:right-[72rem] 2xl:right-[85rem]  "
          style={{ transform: `translateY(${offsetY * 0.3}px)` }}
        />
        {/* <div
          className="SimulatorParallax__background"
          style={{ transform: `translateY(${offsetY * 0.5}px)` }}
        /> */}
        <div className="simulator flex flex-col justify-center items-center mt-20 sm:mt-8 w-[24rem] sm:w-[38rem] px-4  sm:px-24 py-12 sm:py-16">
          <p className="simulator__heading text-center ">
            Interactive Quote Simulator
          </p>
          <div className="simulator__section simulator__sneakers flex flex-col justify-center items-center mt-8 w-full">
            <div className="flex flex-row justify-around w-full">
              <span className="simulator__section__label basis-2/4">
                Number of Sneakers you own
              </span>
              <span className="simulator__section__value basis-2/4 text-right">
                {sneakersOwned} Pairs
              </span>
            </div>
            <div className="simulator__section__range simulator__section__range--blue  mt-3">
              <input
                type="range"
                min="0"
                max={maxSneakers}
                value={sneakersOwned}
                onInput={(e) => changeValues(e, setSneakersOwned)}
              />
              <div
                className="simulator__section__range__value-bar"
                style={{ width: `${(sneakersOwned / maxSneakers) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="simulator__value flex flex-col justify-center items-center w-full mt-7">
            <div className="flex flex-row justify-around w-full">
              <span className="simulator__section__label basis-2/4">
                Average Sneaker Market Value (Estimate Per Sneaker)
              </span>
              <span className="simulator__section__value basis-2/4 text-right">
                ${sneakersValue}
              </span>
            </div>
            <div className="simulator__section__range simulator__section__range--red mt-3">
              <input
                type="range"
                min="0"
                max={maxSneakersValue}
                value={sneakersValue}
                onInput={(e) => changeValues(e, setSneakersValue)}
              />
              <div
                className="simulator__section__range__value-bar bar--red"
                style={{
                  width: `${(sneakersValue / maxSneakersValue) * 100}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="simulator__storage flex flex-row justify-center items-center mt-8 w-full">
            <div className="simulator__section__label basis-2/4">
              Are they stored in containers?
            </div>
            <div className="flex flex-row justify-end basis-2/4 text-right">
              <div className="flex flex-row items-center">
                <input
                  type="radio"
                  id="yes"
                  name="storage"
                  value="yes"
                  className="mr-3"
                  onChange={(e) => {
                    setSneakersStorage(e.target.value);
                  }}
                  checked={sneakersStorage === "yes"}
                />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className="flex flex-row items-center ml-5">
                <input
                  type="radio"
                  id="no"
                  name="storage"
                  value="no"
                  // checked={!sneakersStorage}
                  className="mr-3"
                  onChange={(e) => {
                    setSneakersStorage(e.target.value);
                  }}
                  checked={sneakersStorage === "no"}
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          </div>
          <div className="simulator__result flex flex-col justify-center items-center px-8 py-5 mt-8 w-[20rem] sm:w-[100%]">
            <p className="simulator__result__value w-100  text-[30px] sm:text-[52px]   ">
              ${minCostPerYear}-${maxCostPerYear}
            </p>
            <p className="simulator__result__unit">Cost per year</p>
          </div>
          <p className="text-center font-inter font-normal text-[12px] leading-[18px] w-80 pt-6">
            Pricing is subject to change in app based on actual sneaker
            information shared during underwriting
          </p>
        </div>
      </div>
    </>
  );
};

export default Simulator;
