import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import PartnerShoebag from "../../assets/images/svg/partner-shoebag.svg";
import scrollArrow from "../../assets/images/svg/scroll-arrow.svg";
import yellowCoin from "../../assets/images/png/coin-1.png";
import blueCoin from "../../assets/images/png/coin-2.png";
const SneakerInsurance = (props) => {
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
      <div className="grid sm:grid sm:grid-cols-9 gap-8 sm:gap-0">
        <div className="sm:col-span-7 lg:col-span-5 2xl:col-span-5 sm:ml-12 2xl:pl-12">
          <h2 className=" mx-auto xl:mx-0 w-auto xl:w-auto 2xl:w-5/6 text-center  mytext--filled sm:text-left font-druk font-medium sm:font-bold text-[36px] sm:text-[72px] leading-[55px] sm:leading-[90px] px-4 sm:px-0">
            <p className="mx-auto sm:mx-0 mytext--stroke ">
              SNEAKER INSURANCE™
            </p>
            FOR SHOPIFY STORES
          </h2>
          <p className="mx-auto xl:mx-0 w-auto xl:w-auto 2xl:w-5/6 text-center sm:text-left text-[16px] sm:text-[20px] leading-[24px] sm:leading-[26px] font-inter my-10 sm:my-12 px-4 sm:px-0 sm:mr-60 xl:mr-0">
            <b> SoleSafe™ offers Integrated Sneaker Insurance™ </b>
            and Package Protection for your store, covering your customers from
            checkout to doorstep and beyond. We worry about risk, so you and
            your customers don’t have to.
          </p>

          <div className="cursor-pointer sm:flex justify-center items-center sm:justify-start">
            <a
              href="https://apps.shopify.com/solesafe"
              className="flex justify-center items-center w-3/5 sm:w-56  mx-auto sm:mx-0 sm:my-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] p-4"
            >
              INSTALL APP
              <div className="cursor-pointer rotate-90 ml-6">
                <img alt="" src={scrollArrow} className="scroll-smooth " />
              </div>
            </a>

            <button
              className="cursor-pointer flex justify-center items-center w-3/5 sm:w-56 mx-auto sm:mx-6 sm:my-0 mt-4 sm:mt-0 bg-white text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px] py-4"
              type="submit"
              onClick={() => {
                navigate("/contact", { state: "partner" });
              }}
            >
              <div className="flex justify-center ">
                <h1>LET'S PARTNER </h1>
                <img alt=""
                  src={scrollArrow}
                  className="scroll-smooth cursor-pointer rotate-90 ml-4 "
                />
              </div>
            </button>
          </div>
        </div>

        <div className="sm:col-span-2 lg:col-span-4 2xl:col-span-4 sm:w-[34rem] lg:w-[100%] sm:mr-[20rem] lg:mr-0 sm:mt-[18rem] lg:mt-0 place-self-center">
          {" "}
          <img alt=""
            src={yellowCoin}
            className="absolute sm:flex right-[15rem] sm:right-[21rem] xl:right-[24rem] 2xl:right-[35rem] sm:mt-[7rem] xl:mt-[5rem] 2xl:mt-[8rem] w-28 sm:w-32 xl:w-44"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          />
          <img alt=""
            src={blueCoin}
            className="absolute sm:flex right-[1rem] sm:right-[3rem] xl:right-[2rem] 2xl:right-[6rem] mt-0 sm:mt-[5.5rem] 2xl:mt-[5rem] w-16 sm:w-20 xl:w-24"
            style={{ transform: `translateY(${offsetY * 0.1}px)` }}
          />
          <img alt=""
            src={PartnerShoebag}
            style={{ transform: `translateY(-${offsetY * 0.14}px)` }}
            className="p-4 mt-12"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SneakerInsurance;
