import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Navbar from "../Navbar";
import SocialMedia from "../SocialMedia";
import arrowRight from "../../assets/images/svg/arrow-right.svg";
// import { setImportNav } from "../../actions";
// import { setLogoff } from "../../reducers/userSlice";

import { solesafeLogo, solesafeLogoBlack } from "../../assets/images";
import "./header.scss";

const logo = { white: solesafeLogo, black: solesafeLogoBlack };

const urlPrefix = process.env.REACT_APP_STRAPI_API;

const footerUrl = `${urlPrefix}/api/footer?populate=*`;

const Header = (props) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [data, setData] = useState({});
  const [profileCardShow, setProfileCardShow] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [authenticate, setAuthenticate] = useState(
    props.authenticate ? props.authenticate : "login"
  );
  const dispatch = useDispatch();

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setAuthenticate(state);
    }
  }, []);

  useEffect(() => {
    fetch(footerUrl)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response");
        }
        return response.json();
      })

      .then((footer) => {
        setData(footer.data);
      });
  }, []);

  useEffect(() => {
    if (showMobileNav) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showMobileNav]);
  return (
    <>
      <div className="header xl:pl-[0.4rem] 2xl:pl-[3rem]">
        <div
          className="header__menu-icon sm:pl-6"
          onClick={() => setShowMobileNav((prev) => !prev)}
        >
          <div
            className={`header__menu-icon__bar top-bar ${
              showMobileNav ? "top-bar--open" : ""
            }`}
          ></div>
          <div
            className={`header__menu-icon__bar middle-bar ${
              showMobileNav ? "middle-bar--open" : ""
            }`}
          ></div>
          <div
            className={`header__menu-icon__bar bottom-bar ${
              showMobileNav ? "bottom-bar--open" : ""
            }`}
          ></div>
        </div>
        {pathname === "/blog" ? (
          <img
            className="header__logo"
            src={`${urlPrefix}${data?.attributes?.logoBlack?.data?.attributes?.url}`}
            alt="solesafe logo"
            onClick={() => navigate("/")}
          />
        ) : pathname.match(/^\/blog\/\d+$/) ? (
          <img
            className="header__logo"
            src={`${urlPrefix}${data?.attributes?.logoBlack?.data?.attributes?.url}?id=${id}`}
            alt="solesafe logo"
            onClick={() => navigate("/")}
          />
        ) : (
          <>
            <img
              className="header__logo"
              src={`${urlPrefix}${data?.attributes?.logo?.data?.attributes?.url}`}
              alt="solesafe logo"
              onClick={() => navigate("/")}
            />
          </>
        )}

        <div
          className={`header__right ${
            showMobileNav ? "header__right--is-visible" : ""
          }`}
        >
          <Navbar theme={props.theme} />

          <div className="header__right__download-button">
            {pathname === "/" || pathname === "/raffles" ? (
              <div className="cursor-pointer border-2 rounded-full px-9 w-[14rem] py-3 xl:mr-14 2xl:mr-20">
                {" "}
                <a
                  className="flex justify-center items-center font-inter font-bold text-base"
                  // onClick={() => {
                  //   navigate("/contact", { state: "partner" });
                  // }}
                  href={pathname === "/" ?"https://apps.shopify.com/solesafe": "https://apps.shopify.com/safedrop" }
                  // hotfix
                  // href={data?.attributes?.installApp?.href}
                  // target="_blank"
                >
                  {data?.attributes?.installApp?.label}
                  <div className="cursor-pointer ml-2 ">
                    <img src={arrowRight} alt="Install App" />
                  </div>
                </a>
              </div>
            ) : (
              <>
                {/* App Store and Play Store  */}

                <a className="flex" href={data?.attributes?.appStore?.href}>
                  <img
                  alt=""
                    src={`${urlPrefix}${data?.attributes?.appleIcon?.data?.attributes?.url}`}
                  />
                </a>
                <a
                  className="flex w-[9rem]"
                  href={data?.attributes?.playStore?.href}
                >
                  {" "}
                  <img alt=""
                    className=""
                    src={`${urlPrefix}${data?.attributes?.playstoreIcon?.data?.attributes?.url}`}
                  />{" "}
                </a>
                {/* hot fix  */}
                {/* {authenticate ? (
              <div className="relative">
                <button
                  onClick={() => setProfileCardShow(!profileCardShow)}
                  className="p-4 bg-blue rounded-full  font-inter font-bold text-[16px] leading-[19.36px]"
                >
                  user
                </button>
                {profileCardShow && (
                  <div className="bg-white grid absolute bottom-0 right-2 top-20 ">
                    <div className="bg-white w-72 p-10 space-y-4 font-inter">
                      <h1 className="text-black font-bold text-[20px] leading-[26px]">
                        user
                      </h1>
                      <p className="text-track-red text-[12px] leading-[18px]">
                        Manage your profile settings directly in the app
                      </p>
                      <div className="border border-b-1 " />
                      <p
                        className="flex justify-between cursor-pointer text-black font-bold text-[16px] leading-[24px]"
                        onClick={() => dispatch(setImportNav("selectMethod"))}
                      >
                        UPLOAD COLLECTION
                        <span className=" rotate-90">
                          < alt="" src={scrollArrow} className="scroll-smooth " />
                        </span>
                      </p>
                      <div className="border border-b-1 border-black " />
                      <button
                        onClick={() => setAuthenticate(!authenticate)}
                        className="bg-blue font-bold text-[16px] leading-[24px] w-full rounded-full py-3"
                      >
                        LOG OUT
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {data?.attributes?.register?.map((ele) => {
                  return (
                    <ul
                      key={ele?.id}
                      className={`${
                        pathname === "/blog" || pathname.match(/^\/blog\/\d+$/)
                          ? "border-black text-black last:border-none last:text-white"
                          : " border-white"
                      } font-normal last:bg-blue border-[1px] mr-2  rounded-full `}
                    >
                      <button className="px-12 mx-2   font-inter font-bold text-[16px] leading-[19.36px]">
                        <li className="my-4 ">
                          <a href={ele?.href}> {ele?.name}</a>
                        </li>
                      </button>
                    </ul>
                  );
                })}
              </>
            )} */}
              </>
            )}
          </div>

          <SocialMedia theme={props.theme} />
          <h5
            className={`keep-it-safe ${
              props.theme === "dark" ? "keep-it-safe--dark" : ""
            }`}
          >
            <p>KEEP IT SAFE — KEEP IT SAFE — KEEP IT SAFE — </p>
            <p> KEEP IT SAFE — KEEP IT SAFE — KEEP IT SAFE — </p>
          </h5>
          <div
            className={`header__right__footer ${
              props.theme === "dark" ? "header__right__footer--dark" : ""
            }`}
          >
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms">Terms of Use</Link>
          </div>
        </div>

        {!showMobileNav && (
          <div className="hidden sm:flex  lg:hidden float-right mr-10 pt-5">
            {pathname === "/" || pathname === "/raffles" ? (
              <div className="cursor-pointer border-2 rounded-full px-9 w-[14rem] py-3 xl:mr-14 2xl:mr-20">
                {" "}
                <a
                  className="flex justify-center items-center font-inter font-bold text-base"
                  onClick={() => {
                    navigate("/contact", { state: "partner" });
                  }}
                  // hotfix
                  // href={data?.attributes?.installApp?.href}
                  // target="_blank"
                >
                  {data?.attributes?.installApp?.label}
                  <div className="cursor-pointer ml-2 ">
                    <img src={arrowRight} alt="Install App" className="" />
                  </div>
                </a>
              </div>
            ) : (
              <>
                {/* App Store and Play Store  */}

                <a className="flex" href={data?.attributes?.appStore?.href}>
                  <img
                    alt=""
                    className=""
                    src={`${urlPrefix}${data?.attributes?.appleIcon?.data?.attributes?.url}`}
                  />
                </a>
                <a
                  className="flex w-[9rem]"
                  href={data?.attributes?.playStore?.href}
                >
                  {" "}
                  <img
                    alt=""
                    className=""
                    src={`${urlPrefix}${data?.attributes?.playstoreIcon?.data?.attributes?.url}`}
                  />{" "}
                </a>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
