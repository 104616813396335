//external imports
import React from "react";
import { Helmet } from "react-helmet";
//internal imports
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Benefits from "../../components/Insurance/Benefits";
import BlueBox from "../../components/Insurance/BlueBox";
import SneakerInsurance from "../../components/Insurance/SneakerInsurance";
import Comparison from "../../components/Insurance/Comparison";
import PartnerStoresProtected from "../../components/Insurance/PartnerStoresProtected";
import HowItWorks from "../../components/Insurance/HowItWorks";
import PartnerFaq from "../../components/Insurance/PartnerFaq";

function Insurance() {
  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">
          {`body {
                background-color: black;
            }
        .header__menu-icon__bar {
            background-color: white
            }`}
        </style>
      </Helmet>

      <Header logoColor="white" barColor="white" theme="dark" />
      <div className="mt-[5rem] sm:mt-[8rem]">
        <SneakerInsurance />
      </div>
      {/* hot fix
      <PartnerStoresProtected /> */}
      <HowItWorks />
      <Benefits />
      <Comparison />
      <BlueBox />
      <PartnerFaq />
      <Footer />
    </React.Fragment>
  );
}

export default Insurance;
