import Blog from "../pages/Blog";
import Insurance from "../pages/Insurance";
import PrivacyTerms from "../pages/PrivacyTerms";
import AboutUs from "../pages/AboutUs";
import Support from "../pages/Support";
import PageNotFound from "../pages/PageNotFound";
import Category from "../components/Blogs/Category";
import BlogDetails from "../components/Blogs/BlogDetails";
import CommunityManagement from "../pages/CommunityManagement";
import ForSneakerheads from "../pages/ForSneakerheads";
import RaffleManagement from "../pages/RaffleManagement";
import Downloads from "../pages/downloads";
//hot-fix
// import Signup from "../components/Register/Signup";
// import Login from "../components/Register/Login";
// import Import from "../pages/Import";

const routes = [
  { path: "/", component: Insurance, exact: true },
  //hot-fix { path: "/blog", component: Blog, exact: true },
  //hot-fix { path: "/blog/:id", component: BlogDetails, exact: true },
  //hot-fix { path: "/category/:id", component: Category, exact: true },
  //hot-fix { path: "/signup", component: Signup, exact: true },
  //hot-fix { path: "/login", component: Login, exact: true },
  { path: "/raffles", component: RaffleManagement, exact: true },
  { path: "/sneakerheads", component: ForSneakerheads, exact: true },
  {
    path: "/community",
    component: CommunityManagement,
    exact: true,
  },
  { path: "/privacy-policy", component: PrivacyTerms, exact: true },
  { path: "/terms-of-use", component: PrivacyTerms, exact: true },
  //hot-fix { path: "/about", component: AboutUs, exact: true },
  { path: "/contact", component: Support, exact: true },
  { path: "/download", component: Downloads, exact: true },
  //hot-fix{ path: "/import", component: Import, exact: true },
  //hot-fix  { path: "/partners", component: Partners, exact: true },
  { path: "*", component: PageNotFound, exact: true },
];

export { routes };
