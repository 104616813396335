//external imports
import React, { useEffect } from "react";
import { redirect } from "react-router-dom";

const Downloads = () => {
  useEffect(() => {
    window.location.replace("https://onelink.to/a6wczg");
  }, []);
  return <></>;
};
export default Downloads;
