import React from "react";
import fivePercent from "../../assets/images/png/five_percent.png";
import insureBlue1 from "../../assets/images/png/insure_blue_1.png";
import insureBlue2 from "../../assets/images/png/insure_blue_2.png";
import insureBlue3 from "../../assets/images/png/insure_blue_3.png";
const ClaimsMadeSimple = () => {
  return (
    <React.Fragment>
      <div className="font-druk font-medium leading-[55px] text-[48px] lg:text-[52px] text-center px-10">
        CLAIMS MADE SIMPLE
      </div>
      <div className="font-inter text-[16px] leading-[24px] font-normal text-center my-10 mx-5 sm:mx-auto xl:mx-auto 2xl:mx-auto sm:w-3/4 xl:w-3/5 2xl:w-1/2 sm:pb-20 ">
        SoleSafe™ believes that the claims experience should be smooth. To make
        this happen, our team of claims adjusters evaluate each claim and
        partner with the customer to achieve a mutual resolution. We even have a
        unique way to restore your loss along the way.
      </div>
      <div className="sm:relative flex flex-col justify-center  sm:grid sm:grid-cols-12 mx-4 gap-6">
        <div className="mx-14 sm:mx-0 sm:col-start-9 sm:col-span-3  sm:absolute sm:-top-44 xl:-top-48  2xl:-top-52 sm:-right-24 lg:-right-24 xl:-right-28  h-64 w-64 xl:h-72 xl:w-64 2xl:w-72">
          <img src={fivePercent} className="" />
        </div>
        <div className="bg-blue flex flex-col sm:col-span-6 lg:col-span-4 justify-center items-center text-center rounded-lg  py-4 px-8">
          <img src={insureBlue1} className="w-2/3" />
          <div className="font-inter font-bold leading-[24px] py-5 text-[20px] ">
            SELECT SNEAKERS
          </div>
          <p className=" text-black font-inter font-normal leading-[24px] text-[16px] px-6">
            {" "}
            User has access to all insured sneakers in their collection. They
            can quickly scan to select which sneakers were involved in the
            claim.
          </p>
        </div>
        <div className=" bg-blue flex flex-col sm:col-span-6 lg:col-span-4 justify-center items-center text-center rounded-lg  py-4 px-8">
          <img src={insureBlue2} className="w-2/3" />
          <div className="font-inter font-bold leading-[24px] py-5 text-[20px] ">
            TELL US WHAT HAPPENED
          </div>
          <p className=" text-black font-inter font-normal leading-[24px] text-[16px] px-4 ">
            {" "}
            Once the sneaker(s) are selected, the user can tell us what
            happened, take photos and videos, and provide any documentation that
            will help during claims process.
          </p>
        </div>
        <div className="bg-blue flex flex-col sm:col-start-4  sm:col-span-6 lg:col-span-4 justify-center items-center text-center rounded-lg px-8 py-4 ">
          <img src={insureBlue3} className="" />
          <div className=" font-inter font-bold leading-[24px] pt-9  text-[20px] ">
            YOU MAKE THE DECISION TO RESTORE OR GET PAID OUT
          </div>
          <p className=" text-black font-inter font-normal leading-[24px] text-[16px] pt-4 sm:pt-0 ">
            {" "}
            We put ourselves in the customer shoes (literally) to reimagine the
            resolution experience.
            <br />
            <br /> SoleSafe™ is putting the decision power back with the
            customer to decide whether they want a payout or to keep their
            sneakers.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClaimsMadeSimple;
