import React from "react";
import scrollArrow from "../../../assets/images/svg/scroll-arrow.svg";
import { useNavigate } from "react-router-dom";

const Retailers = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div
        className="accordion overflow-y-auto overflow-x-hidden h-auto mt-5 mb-14 text-white rounded-xl space-y-4 mx-6 sm:mx-[3rem] xl:mx-[2.5rem] 2xl:mx-[12rem]"
        id="accordionExample"
      >
        {/* 1st */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
             
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              WHAT IS SAFEDROP™?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue flex justify-between  text-[16px] leading-[24px]">
              SafeDrop™ is a Raffle Management platform built for retailers to
              easily manage product releases. We partner with your favorite
              retailers to remove sites crashing, works to keep out bots and
              scammers, and creates a fair and safe drop experience for
              everyone.
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingTwo">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              WHAT DOES ENTRYBOOST™ MEAN?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue grid text-[16px] leading-[24px]">
              We don’t just believe that each person in the community should get
              a fair chance to secure products they want and love. As
              sneakerheads, we understand that no matter the product – sneakers
              to wine to whatever – all any of ask for is fairness,
              transparency, and no bots. We built that just for you (and us)!
              <div className="my-4">
                SafeDrop’s intelligent approach further enhances a smooth
                experience keeping the fun in the hunt for your favorite
                products. Not only do we invest in the technology, we hold
                ourselves to these standards:
              </div>
              <ul className="list-decimal">
                <li>
                  Humans Not Bots - we do the hard work with our multi-step
                  authentication system to keep bots out and your entries in.
                </li>
                <li>
                  No Duplicates or Extra Entries - Removing duplicate entries is
                  key and we leverage EntryBoost™ where it counts to account for
                  store loyalty, historical purchases, and more to give local
                  communities better chances at coveted drops.
                </li>
                <li>
                  Secure & Safe - Safety is always top-of-mind for our team. We
                  take protecting your information by any means necessary.
                </li>
                <li>
                  Easy to Enter - We keep it familiar and don’t require another
                  login process or account to be created. The only account that
                  matters is the one you have with that specific store. Free
                  game - it helps to create one with the store!
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* 3 rd  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingThree">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
       
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              HOW DOES SAFEDROP WORK FOR RETAILERS?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px] ">
              SafeDrop is a raffle management platform built for retailers to
              easily manage product releases. Retailers using the Shopify
              platform as their operating system can easily visit the App Store,
              search SafeDrop, and install the app to get started. In less than
              10 minutes, you can install the app and launch your next drop.
              <div className="my-4">
                SafeDrop™ easily fits into your existing shopping experience
                taking products already inventoried on your Shopify store to
                create your next drop. Behind the scenes, SafeDrop is securing
                customer information, checking for bots and scammers, ensuring
                payments process safely, and provides the proper communication
                whether customers get a “W” or not.
              </div>
              <div className="mb-4">
                What’s dope is we don’t create a separate website or modify your
                branding with customers. SafeDrop™ fits your branding aesthetic
                putting the power of dropping your next product in your hands.
              </div>
              <div>
                Ready to get started? Hit us up.
                {/* <div className="bg-white sm:my-4 text-black rounded-full flex justify-center items-center sm:justify-start mx-auto sm:mx-0 mt-2 w-auto sm:w-[30%] xl:w-1/6 2xl:w-60"> */}
                <a
                  className="font-inter font-bold text-[16px] leading-[19.36px] sm:mx-auto my-8"
                  type="submit"
                  onClick={() => {
                    navigate("/contact", { state: "partner" });
                  }}
                >
                  {/* <div className="flex justify-center items-center lg:w-full py-3"> */}
                  <h1 className="underline underline-offset-4">Request Demo</h1>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 4th  */}
        <div className="accordion-item my-4">
          <h2 className="accordion-header mb-0 " id="headingFour">
            <button
              className="
        justify-between
        collapsed
        relative
        flex
        items-center
        w-full
        p-[30px]
        font-druk text-[20px] leading-[24px] font-bold text-left
        rounded-xl
        transition
        focus:outline-none
        border
      "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              HOW DO I INSTALL SAFEDROP™ ?
              <img
                src={scrollArrow}
                className="scroll-smooth rounded-full bg-white p-1 rotate-180 "
              />
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-[30px] mt-4 rounded-xl text-black font-inter font-normal bg-blue text-[16px] leading-[24px]">
              <div className="">
                The easiest and fastest way is to install directly via our
                <a
                  className="underline underline-offset-4  cursor-pointer mx-auto sm:mx-0 sm:my-0 pl-1 pt-1 text-black rounded-full font-inter font-bold text-[16px] leading-[19.36px]"
                  href="https://apps.shopify.com/solesafe"
                  target="_blank"
                >
                  app listing on the Shopify App Store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Retailers;
